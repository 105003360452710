/* mears-mcmview-override-contents

export default function useSearchParams()
{
	return [{
		get: (name: string) => { }
	}, () => {}];
}

*/

import { useSearchParams as useReactRouterDomSearchParams } from "react-router-dom";

export default useReactRouterDomSearchParams;