/* mears-mcmview-override-contents

import { useInjected } from "@yellow-sub/angular-react";
import { UseRestClientContextService } from '../services/use-rest-client-context.service';

export function useRestClientContext()
{
	return useInjected(UseRestClientContextService);
}

*/

import { createContext, useContext } from "react";
import Client from "../rest/Client";

const RestClientContext = createContext<Client | undefined>(undefined);

export default RestClientContext;

export function useRestClientContext(): Client {
	const context = useContext(RestClientContext);
	if (!context) {
		throw new Error("No RestClientContext.Provider found when calling useRestClientContext.");
	}
	return context;
}
