import { Header } from "semantic-ui-react";

import Page from "./Page";
import DataTable from "../components/UI/DataTable/DataTable";
import { PropertyEditContextProvider } from "../contexts/PropertyEditContext";

export default function DataIntegrity() {
	return (
		<Page>
			<Header as="h1">Data</Header>
			<PropertyEditContextProvider>
				<DataTable editable={true} displayIntegrityTiles={true}></DataTable>
			</PropertyEditContextProvider>
		</Page>
	);
}
