import React, { useEffect, useState } from "react";
import { Header, Dropdown } from "semantic-ui-react";
import Preloader from "../Preloader/Preloader";
import { UserVector } from "../../../assets/UserVector";

import { useRestClientContext } from "../../../contexts/RestClientContext";
import { useUserContext } from "../../../contexts/UserContext";

import "./TeamSelect.scss";
import Team from "../../../rest/Team";

interface TeamSelectProps {
	className?: string;
	placeholder?: string;
	showWithZeroProperties?: boolean;
	loading?: boolean;
	hideWhenNoChoices?: boolean;
	onChange: Function;
}

interface TeamSelectOption {
	key?: number;
	text?: string;
	value?: number;
}

const TeamSelect = (props: TeamSelectProps) => {
	const {
		className,
		placeholder,
		showWithZeroProperties,
		loading: inheritedLoading,
		hideWhenNoChoices,
		onChange,
	} = props;

	const [loading, setLoading] = useState(true);
	const [options, setOptions] = useState<TeamSelectOption[]>([]);

	const client = useRestClientContext();
	const user = useUserContext();

	useEffect(() => {
		const fetch = async () => {
			const response: Response = await client.fetchTeams();
			const data: Team[] = await response.json();

			setOptions(
				data
					.filter((obj) => {
						if (showWithZeroProperties) return true;

						return obj.properties_count! > 0;
					})
					.map((obj) => {
						const result: TeamSelectOption = { key: obj.id, text: `${obj.name} (${obj.id})`, value: obj.id };
						return result;
					})
			);
			setLoading(false);
		};

		// TODO: Catch any errors
		fetch();
	}, [client, showWithZeroProperties]);

	if (hideWhenNoChoices && options.length < 2) {
		if (loading) return <Preloader variant="inline" />;

		return <></>;
	}

	return (
		<div className={`team-select-container ${className ?? ""}`}>
			<div>
				<UserVector />
			</div>
			<div>
				<Header as="h4" disabled>
					{placeholder}
				</Header>
			</div>

			<div>
				{loading || inheritedLoading ? (
					"Please wait..." // TODO: Use prelodaer
				) : (
					<Dropdown
						inline
						scrolling
						search
						options={options}
						defaultValue={user.team_id}
						onChange={(event, { value }) => onChange(value)}
					/>
				)}
			</div>
		</div>
	);
};

export default TeamSelect;
