import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import levenshtein from "damerau-levenshtein";

import { useRestClientContext } from "../contexts/RestClientContext";
import Property from "../rest/Property";

interface Match {
	similarity: number;
	data: Record<string, string | number>;
}

export interface OdcEpcSuggestion {
	key: string;
	suggested: string | number;
	current: string | number;
}

interface OdcEpcSuggestionsProps {
	property: Property;
}

export default function useOdcEpcSuggestions({ property }: OdcEpcSuggestionsProps) {
	const client = useRestClientContext();

	const [loading, setLoading] = useState(false);
	const [warnings, setWarnings] = useState<string[]>([]);
	const [status, setStatus] = useState("");
	const [suggestions, setSuggestions] = useState<OdcEpcSuggestion[]>([]);
	const [date, setDate] = useState<DateTime | null>(null);
	const [log, setLog] = useState([]);

	useEffect(() => {
		async function fetchData() {
			let response;

			setWarnings([]);
			setSuggestions([]);
			setDate(null);
			setStatus("Finding property in ODC EPC register...");
			setLoading(true);

			const postcode = encodeURIComponent(property.data.postcode);

			response = await client.fetchEpcRegisterSearch(postcode);

			const properties = await response.json();
			const matches: Match[] = [];
			const match = `${property.data.addr_no} ${property.data.addr_street}`;
			let warnings = [];

			for (const data of properties) {
				["address1", "address2"].forEach((key) => {
					let distance = levenshtein(match, data[key]);

					matches.push({
						similarity: distance.similarity,
						data: data,
					});
				});
			}

			if (matches.length === 0) {
				setStatus(`No properties found on ODC EPC register for ${property.data.postcode}`);
				setLoading(false);
				return;
			}

			matches.sort((a, b) => (a.similarity > b.similarity ? -1 : 1));

			const closest = matches[0].data;
			const foreignAddress = [
				closest["address1"],
				closest["address2"],
				closest["posttown"],
				closest["postcode"],
			].join(", ");
			const isExactMatch = property.fullAddress.toUpperCase() === foreignAddress.toUpperCase();

			if (!isExactMatch)
				warnings.push(
					`The suggestions shown are source from the closest matched address, which is ${foreignAddress}`
				);

			setStatus("Converting to DREam format...");

			response = await client.convertPublicEpcData(closest);

			const native = await response.json();
			const suggested = new Property(native.result);
			const suggestions = [];

			for (const key in suggested.data) {
				if (suggested.data[key] === property.data[key]) continue;

				suggestions.push({
					key: key,
					suggested: suggested.data[key],
					current: property.data[key],
					accept: false,
				});
			}

			if ("warnings" in native) warnings = [...warnings, ...native.warnings];

			setSuggestions(suggestions);
			setWarnings(warnings);
			setStatus("");
			setDate(DateTime.fromISO(closest["lodgement-date"] as string));
			setLoading(false);

			if ("log" in native) setLog(native.log);
		}

		fetchData();
	}, [property, client]);

	return {
		loading,
		warnings,
		status,
		suggestions,
		date,
		log,
	};
}
