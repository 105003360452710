import { Modal as SemanticModal, StrictModalProps } from "semantic-ui-react";

import Card from "../UI/Card/Card";

export interface ModalProps extends StrictModalProps {
	title?: string;
	component?: JSX.Element;
};

export default function Modal(props: ModalProps) {
	return (
		<SemanticModal open={true} {...props}>
			<Card title={props.title}>{props.children}</Card>
		</SemanticModal>
	);
}
