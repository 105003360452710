import { LayerProps } from "react-map-gl";

export const clusterLayerStyle: LayerProps = {
	id: "cluster",
	type: "circle",
	source: "earthquakes",
	filter: ["has", "point_count"],
	paint: {
		"circle-color": "#51bbd6",
		"circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
	},
};

export const clusterCountLayerStyle: LayerProps = {
	id: "cluster-count",
	type: "symbol",
	source: "earthquakes",
	filter: ["has", "point_count"],
	layout: {
		"text-field": "{point_count_abbreviated}",
		"text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
		"text-size": 12,
	},
};

export const UNCLUSTERED_PROPERTIES_LAYER_ID = "unclustered_properties";

export const unclusteredPointLayerStyle: LayerProps = {
	id: UNCLUSTERED_PROPERTIES_LAYER_ID,
	type: "circle",
	source: "earthquakes",
	filter: ["!", ["has", "point_count"]],
	paint: {
		"circle-color": [
			"step",
			["get", "epc_rating"],
			"darkred",
			21,
			"red",
			39,
			"orange",
			55,
			"yellow",
			69,
			"lightgreen",
			81,
			"green",
			92,
			"darkgreen",
		],
		"circle-radius": 10,
		"circle-stroke-width": 1,
		"circle-stroke-color": "#fff",
	},
};

//https://docs.mapbox.com/mapbox-gl-js/example/3d-buildings/
export const building3dLayerStyle: LayerProps = {
	id: "add-3d-buildings",
	source: "composite",
	"source-layer": "building",
	filter: ["==", "extrude", "true"],
	type: "fill-extrusion",
	minzoom: 15,
	paint: {
		"fill-extrusion-color": "#aaa",

		"fill-extrusion-height": ["interpolate", ["linear"], ["zoom"], 15, 0, 15.05, ["get", "height"]],
		"fill-extrusion-base": ["interpolate", ["linear"], ["zoom"], 15, 0, 15.05, ["get", "min_height"]],
		"fill-extrusion-opacity": 0.6,
	},
};
