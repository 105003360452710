import { useMemo } from "react";
import { PropertyResponse } from "../../../rest/Property";
import EpcRatingTile from "../EpcRatingTile/EpcRatingTile";
import Uprn from "../Uprn/Uprn";

import "./MiniTable.scss";

interface MiniTableProps {
	properties: PropertyResponse[];
};

export default function MiniTable({ properties }: MiniTableProps) {

	// NB: Memoized so it doesn't re-render when you're doing things like typing into the group modal form
	const table = useMemo(() => {

		return (
			<table className="mini-table">
				<thead>
					<tr>
						<th>UPRN</th>
						<th>Address</th>
						<th>EPC</th>
					</tr>
				</thead>
				<tbody>
					{properties?.map((property) => {
						return (
							<tr key={property.id}>
								<td>
									<Uprn property={property}>{property.uprn}</Uprn>
								</td>
								<td>{property.fullAddress}</td>
								<td className="epc-rating-container">
									<EpcRatingTile grade={property.epc_rating_grade} />
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		);

	}, [properties]);

	return table;

}
