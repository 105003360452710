import React from "react";
import { Grid } from "semantic-ui-react";
import EpcRatingTile from "../../UI/EpcRatingTile/EpcRatingTile";
import { NetZeroGoalData } from "./NetZeroGoal";

interface NetZeroGoalFiguresProps
{
	data?: NetZeroGoalData;
}

const NetZeroGoalFigures = ({ data }: NetZeroGoalFiguresProps) => {
	return (
		<Grid>
			<Grid.Row>
				<Grid.Column>
					{/* TODO: This is not an EPC rating. There should not be an EpcRatingTile here. Please use something styled with a class instead of misusing the EpcRatingTile */}
					<EpcRatingTile grade="C" compact />
				</Grid.Column>
				<Grid.Column width={8}>Current Position</Grid.Column>
			</Grid.Row>
			<Grid.Row>
				<Grid.Column>
					<EpcRatingTile grade="F" compact />
				</Grid.Column>
				<Grid.Column width={8}>{data?.target_year} Target</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

export default NetZeroGoalFigures;
