import React from "react";
import { Checkbox, CheckboxProps } from "semantic-ui-react";

import "./Toggle.scss";

interface ToggleProps extends CheckboxProps {
	variant?: string;
}

const Toggle = (props: ToggleProps) => {
	const { variant, ...forwardProps } = props;
	const classes = ["toggle-switch"];

	if (variant) classes.push(`"varient-${variant}`);

	return <Checkbox className={classes.join(" ")} toggle {...forwardProps} />;
};

export default Toggle;
