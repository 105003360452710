import Button from "./Button/Button";
import Card from "./Card/Card";

import "./ConfirmEmailCard.scss";

interface ConfirmEmailCardProps {
	logout: Function;
}

export default function ConfirmEmail({ logout }: ConfirmEmailCardProps) {
	return (
		<div className="fullscreen-email">
			<div className="email-verification">
				<Card title="Verify your email">
					<p>Please click the verification link sent to your email to complete registration.</p>
					<Button variant="contained" onClick={() => logout()}>
						Back to Login
					</Button>
				</Card>
			</div>
		</div>
	);
}
