import { useState } from "react";
import Button from "../Button/Button";
import { ModalProps } from "../../Modal/Modal";

interface BulkEditButtonProps {
	modal: ModalProps;
	selectedPropertyIds: number[];
	onReload: Function;
}

export default function BulkEditButton({ modal, selectedPropertyIds, onReload }: BulkEditButtonProps) {
	const [open, setOpen] = useState(false);
	const DynamicModal = modal.component;

	const onClose = () => {
		setOpen(false);
		onReload();
	};

	return (
		<>
			<Button disabled={selectedPropertyIds.length === 0} onClick={() => setOpen(true)}>
				Edit
			</Button>
			{open && (
				// TODO: Why? Please look further into this
				// @ts-ignore
				<DynamicModal title={modal.title} selectedPropertyIds={selectedPropertyIds} onClose={() => onClose()} />
			)}
		</>
	);
}
