import { User } from "../types/types";
import Role from "./Role";

export default class Team {

	// TODO: Please can we type and define data so that this doesn't happen
	public id: number;
	public name?: string;
	public users?: User[];
	public properties_count?: number;
	public role?: Role;

	constructor(data: any) {
		this.id = 0;
		Object.assign(this, data);
	}
}
