import BulkEditModal, { BulkEditModalField, BulkEditModalProps } from "./BulkEditModal";

export default function BulkEditAddressModal(props: BulkEditModalProps): JSX.Element {
	const fields: BulkEditModalField[] = [
		{
			label: "Address Number",
			key: "addr_no",
		},
		{
			label: "Address Street",
			key: "addr_street",
		},
		{
			label: "City",
			key: "addr_city",
		},
		{
			label: "Postcode",
			key: "postcode",
		},
	];

	return <BulkEditModal {...props} modalProps={{title: "Edit Address"}} fields={fields} />;
}
