import React from "react";

const FavouriteButtonVector = ({ filled, loading }) => {
	return (
		<svg
			width="16"
			height="20"
			viewBox="0 0 16 20"
			fill={filled ? "#FF5F43" : "none"}
			opacity={loading ? 0.5 : 1}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.0003 19.0002L8.00033 14.0002L1.00033 19.0002V3.00019C1.00033 2.46976 1 1.00042 1 1.00042C1 1.00042 2.4699 1.00019 3.00033 1.00019H13.0003C13.5308 1.00019 15.0003 1 15.0003 1C15.0003 1 15.0003 2.46976 15.0003 3.00019V19.0002Z"
				stroke="#FF5F43"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default FavouriteButtonVector;
