import { useEffect, useState } from "react";

import { useRestClientContext } from "../contexts/RestClientContext";
import { useTeamContext } from "../contexts/TeamContext";
import Client, { PropertyQuery } from "../rest/Client";
import { PropertyResponse } from "../rest/Property";

interface TableProperties {
	loading: boolean;
	properties?: PropertyResponse[];
}

// NB: Returns either the selected properties, or will fetch properties based on the filters selected
export default function useTableProperties(selected: PropertyResponse[], query?: PropertyQuery): TableProperties
{
	const client: Client = useRestClientContext();
	const team = useTeamContext();

	const [loading, setLoading] = useState(true);
	const [properties, setProperties] = useState();

	useEffect(() => {

		if(selected.length)
			return;

		const controller = new AbortController();

		setProperties(undefined);
		setLoading(true);

		client
			.fetchTeamProperties(
				team, 
				{
					...query,
					page_size: 0xFFFFFFFF
				},
				controller.signal
			)
			.then((response: Response) => response.json())
			.then((json: any) => {
				setProperties(json.entries);
				setLoading(false);
			});
		
		return () => controller.abort();

	}, [selected, query, client, team]);

	if(selected.length)
	{
		return {
			loading: false,
			properties: selected
		};
	}

	return {
		loading,
		properties
	};
}