import { useState } from "react";

const LOCAL_STORAGE_KEY = "custom-improvement-sets-history";

// TODO: Type and interface copied from CustomPanel, probs best to abstract this please
type CustomParameter = [number];

interface MappedRelationship
{
	id: number;
	reason?: string; // NB: History items have a reason
	params?: CustomParameter;
}

export interface CustomImprovementSetsHistoryRecord
{
	name: string;
	createdAt: number;
	improvements: MappedRelationship[];
}

interface CustomImprovementSetsHistory
{
	items: CustomImprovementSetsHistoryRecord[];
	save: Function;
}

export default function useCustomImprovementSetsHistory(): CustomImprovementSetsHistory
{
	const json = localStorage.getItem(LOCAL_STORAGE_KEY) || "[]";
	const [items, setItems]	= useState( JSON.parse(json) );

	const save = (name: string, improvements: MappedRelationship[]) => {

		// NB: Needle to match duplicates
		const needle = JSON.stringify(improvements);

		// NB: Prepend
		const next = [{
			name,
			improvements,
			createdAt: new Date().getTime()
		}]
			// NB: Concat but remove duplicates from existing items first
			.concat(
				items.filter((item: CustomImprovementSetsHistoryRecord) => JSON.stringify(item.improvements) !== needle)
			);

		// NB: Store in local storage and state
		localStorage.setItem(
			LOCAL_STORAGE_KEY, 
			JSON.stringify(next)
		);

		setItems(next);

	};

	return {
		items,
		save
	};
}