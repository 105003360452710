import { Grid } from "semantic-ui-react";

import Button from "../../UI/Button/Button";
import Card from "../../UI/Card/Card";

import "./SummaryReport.scss";

export default function SummaryReport() {
	return (
		<div className="summary-report-container">
			<Card title="Summary Report" minHeight={437}>
				<Grid padded="vertically" className="summary-report-content">
					<Grid.Row>
						<Grid.Column>
							{/* TODO this could do with a better image here */}
							<div className="summary-report-img-background">
								<img
									className="summary-report-img"
									src="/assets/images/logo-with-text.png"
									alt="DREam Logo"
								/>
							</div>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column>
							<p>View and download your monthly summary report.</p>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column>
							<a
								href={`https://app.powerbi.com/groups/47eab3b1-520c-4556-953d-11d5f9e37fee/reports/
								4482aff4-25db-4401-973b-b28ca73616f5/ReportSectionafa35718e104989b5af6?ctid=
								44bc7c72-1a46-4d32-aebb-041a8e58857c&experience=power-bi`}
								target="_BLANK"
								rel="noreferrer"
							>
								<Button variant="contained">View Report</Button>
							</a>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Card>
		</div>
	);
}
