import { Grid } from "semantic-ui-react";
import { Header } from "semantic-ui-react";

import Page from "./Page";
import "./Dashboard.scss";

import SummaryReport from "../components/Dashboard/SummaryReport/SummaryReport";
import NetZeroJourney from "../components/Dashboard/NetZeroJourney/NetZeroJourney";
import NetZeroGoal from "../components/Dashboard/NetZeroGoal/NetZeroGoal";
import FavouriteGroups from "../components/Dashboard/FavoriteGroups/FavouriteGroups";
import EpcData from "../components/Dashboard/EpcData/EpcData";
import OrderThermalSurveys from "../components/Dashboard/OrderThermalSurveys/OrderThermalSurveys";
import YourProperties from "../components/Dashboard/YourProperties/YourProperties";
import PerformanceStats from "../components/Dashboard/PerformanceStats/PerformanceStats";
import RequestNewWidget from "../components/Dashboard/RequestNewWidget/RequestNewWidget";

export default function Dashboard() {
	return (
		<Page>
			<div className="header-text-container">
				<Header as="h1">Dashboard</Header>
				<p>Welcome to your new DREam dashboard</p>
			</div>
			<Grid stackable columns={2}>
				<Grid.Column width={12}>
					<EpcData />
				</Grid.Column>
				<Grid.Column width={4}>
					<SummaryReport />
				</Grid.Column>

				<Grid.Column width={7}>
					<FavouriteGroups />
				</Grid.Column>
				<Grid.Column width={9}>
					<NetZeroGoal />
				</Grid.Column>


				<Grid.Column width={12}>
					<NetZeroJourney />
				</Grid.Column>
				<Grid.Column width={4}>
					<OrderThermalSurveys />
				</Grid.Column>

				<Grid.Column width={16}>
					<YourProperties />
				</Grid.Column>

				<Grid.Column width={12}>
					<PerformanceStats />
				</Grid.Column>
				<Grid.Column width={4}>
					<RequestNewWidget />
				</Grid.Column>
			</Grid>
		</Page>
	);
}
