import React from "react";

interface UserVectorProps {
	stroke?: string;
}

export const UserVector = ({ stroke }: UserVectorProps) => {
	return (
		<svg width="22" height="22" stroke="#9395A2" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_220_867)">
				<path
					d="M15.5837 19.25V17.4167C15.5837 16.4442 15.1973 15.5116 14.5097 14.8239C13.8221 14.1363 12.8895 13.75 11.917 13.75H4.58366C3.6112 13.75 2.67857 14.1363 1.99093 14.8239C1.3033 15.5116 0.916992 16.4442 0.916992 17.4167V19.25"
					stroke={stroke ? stroke : ""}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M8.24968 10.0833C10.2747 10.0833 11.9163 8.44171 11.9163 6.41667C11.9163 4.39162 10.2747 2.75 8.24968 2.75C6.22463 2.75 4.58301 4.39162 4.58301 6.41667C4.58301 8.44171 6.22463 10.0833 8.24968 10.0833Z"
					stroke={stroke ? stroke : ""}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M21.083 19.25V17.4166C21.0824 16.6042 20.812 15.815 20.3143 15.1729C19.8165 14.5308 19.1196 14.0722 18.333 13.8691"
					stroke={stroke ? stroke : ""}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M14.667 2.86914C15.4557 3.07108 16.1548 3.52978 16.654 4.17292C17.1532 4.81607 17.4242 5.60707 17.4242 6.42122C17.4242 7.23538 17.1532 8.02638 16.654 8.66952C16.1548 9.31266 15.4557 9.77136 14.667 9.97331"
					stroke={stroke ? stroke : ""}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_220_867">
					<rect width="22" height="22" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
