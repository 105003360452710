import { Fragment } from "react";
import { Layer } from "mapbox-gl";

import Card from "../Card/Card";

import "./HoveredFeaturesOverlay.scss";

enum Area {
	ENGLAND = "deprivation_england",
	WALES = "deprivation_wales",
	SCOTLAND = "deprivation_scotland",
};

interface Temp {
	properties: Record<string, string>,
	layer: Layer
}

interface HoveredFeaturesOverlayProps {
	features: {
		deprivation: Temp,
		offGas: Temp
	}
};

export default function HoveredFeaturesOverlay({ features }: HoveredFeaturesOverlayProps) {
	if (!features || !Object.keys(features).length) return <></>;

	const getDataKeys = (area: Area): string[] => {
		if (area === Area.WALES) {
			return ["lsoa_name_", "lsoa_code", "decile"];
		} else if (area === Area.ENGLAND) {
			return ["LSOA01NM", "lsoa11cd", "WBDec"];
		} else if (area === Area.SCOTLAND) {
			return ["DZName", "DataZone", "Decilev2"];
		}
		throw new Error("Invalid Area");
	};

	const convertPercentage = (str: any): string => {
		// if value is not a convertable number... just display it
		if (str == null || isNaN(str as number)) return str
		return (Number(str) * 100).toPrecision(4) + "%";
	}

	return (
		<Card title="Hovered Features">
			<dl className={features.deprivation ? "hovered-layer-features-info deprevation-style" : "hovered-layer-features-info"}>
				{/* <dt>Region Name</dt>
			<dd>{regionName}</dd> */}
				{features.deprivation &&
					getDataKeys(features.deprivation.layer.id as Area).map((key) => (
						<Fragment key={key}>
							<dt>{key}</dt>
							<dd>{features.deprivation.properties[key]}</dd>
						</Fragment>
					))}
				{features.offGas && (
					<>
						<dt>LSOA Code</dt>
						<dd>{features.offGas.properties.lsoa_code}</dd>
						<dt>Properties Off Gas</dt>
						<dd>{features.offGas.properties.properties_off_grid}</dd>
						<dt>Percent Off Gas</dt>
						<dd>{convertPercentage(features.offGas.properties.percent_properties_off_grid)}</dd>
					</>
				)}
			</dl>
		</Card>
	);
}
