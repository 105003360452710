import Case from "case";

export default class FriendlyNames {
	static fromColumnName(name) {
		switch (name) {
			case "uprn":
				return "UPRN";

			case "floorconstruction":
				return "Floor Construction";

			case "room_height1":
				return "Room Height (Ground floor)";

			case "room_height2":
				return "Room Height (1st floor)";

			case "room_height3":
				return "Room Height (2nd floor)";

			case "created_at":
				return "Created at";
			case "updated_at":
				return "Updated at"

			case "construction_age":
			case "wall_construction":
			case "roof_type":
			case "glazing_type":
			case "floor_area":
			case "lighting":
			case "status":
			case "target":
			case "value":
				return Case.capital(name);

			default:
				throw new Error("Invalid field name");
		}
	}
}
