import React from "react";
import "./MainFooter.scss";
import footerLogo from "../../../assets/Img/FooterLogo.png";
const MainFooter = () => {
	return (
		<div className="main-footer-container">
			<span>Brought to you by</span>
			<img className="img-footer-logo" src={footerLogo} alt="IRT"></img>
		</div>
	);
};

export default MainFooter;
