import { useCallback, useRef } from "react";

function onTableScroll(event, dummy) {
	dummy.scrollLeft = event.target.scrollLeft;
}

function onDummyScroll(event, container) {
	container.scrollLeft = event.target.scrollLeft;
}

function useDummyScrollbar() {
	const ref = useRef(null);
	
	const setRef = useCallback((node) => {

		let intervalId;

		if (ref.current) {
			// Make sure to cleanup any events/references added to the last instance
			ref.current.querySelector(".overflow-x-scroll").removeEventListener("scroll", onTableScroll);
			ref.current.querySelector(".dummy-scrollbar").removeEventListener("scroll", onDummyScroll);

			clearInterval(intervalId);
		}

		if (node) {
			// Check if a node is actually passed. Otherwise node would be null.
			// You can now do what you need to, addEventListeners, measure, etc.
			const container = node.querySelector(".overflow-x-scroll");
			const dummy = node.querySelector(".dummy-scrollbar");
			const inner = node.querySelector(".dummy-scrollbar-inner-width");

			intervalId = setInterval(() => {
				dummy.style.width = `${container.clientWidth}px`;
				inner.style.width = `${container.scrollWidth}px`;
			}, 100);

			container.addEventListener("scroll", (event) => onTableScroll(event, dummy));
			dummy.addEventListener("scroll", (event) => onDummyScroll(event, container));
		}

		// Save a reference to the node
		ref.current = node;

	}, []);

	return [setRef];
}

export { useDummyScrollbar };
