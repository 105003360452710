import { useState } from 'react';

import Button from '../UI/Button/Button';
import Property from '../../rest/Property';

import CustomImprovementSetsModal from './CustomImprovementSetsModal';
import { Grid } from 'semantic-ui-react';

interface CustomUpdateButtonProps
{
    property: Property
    onUpdateProperty: () => null
}

export default function CustomUpdateButton({ property, onUpdateProperty }: CustomUpdateButtonProps)
{
	const [updateModalOpen, setUpdateModalOpen] = useState(false);
	
	return (
    
    <>
    <Grid textAlign='right'>
        <Grid.Column>
            <Button 
                floated="right" 
                variant="contained" 
                onClick={() => setUpdateModalOpen(true)}
            >
                Custom Update (Beta)
            </Button>
        </Grid.Column>
    </Grid>

    {
        updateModalOpen && 
        <CustomImprovementSetsModal 
			teamId={property.teamId}
            property={property}
            onComplete={() => onUpdateProperty()}
            onClose={() => setUpdateModalOpen(false)}
        />
    }
    </>);
}