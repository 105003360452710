import React from "react";
import { Header } from "semantic-ui-react";
import { HardDriveVector } from "../../../assets/HardDriveVector";
import { useTeamContext } from "../../../contexts/TeamContext";
import "./PropertiesLoaded.scss";

const PropertiesLoaded = () => {
	const team = useTeamContext();
	const count = team.properties_count!;

	return (
		<div className="properties-loaded-container">
			<div>
				<HardDriveVector />
			</div>
			<div>
				<Header as="h4" disabled content={`${count.toLocaleString("en-US")} Properties Loaded`} />
			</div>
		</div>
	);
};

export default PropertiesLoaded;
