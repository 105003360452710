import { useEffect, useState } from "react";
import {
	Header,
	Message,
	Table,
	TableBody,
	TableCell,
	TableHeader,
	TableHeaderCell,
	TableRow,
} from "semantic-ui-react";
import { useInterval } from "usehooks-ts";
import FriendlyNames from "../../database/FriendlyNames";

import { SmartTarget, SmartTargetLogEntry, SmartTargetStatus } from "../../types/types";
import Preloader from "../UI/Preloader/Preloader";
import Select from "../UI/Select/Select";
import Modal from "./Modal";

import "./SmartTargetModal.scss";

/**
 * Display a table from JSON array where keys are column headings.
 *
 * @param message the smart target log message
 */
function RenderMessageAsTable({ message }: { message: string }) {
	const tableData = JSON.parse(message);

	return (
		<Table celled>
			<TableHeader>
				<TableRow>
					{Object.keys(tableData[0]).map((key: string) => (
						<TableHeaderCell key={key}>{key}</TableHeaderCell>
					))}
				</TableRow>
			</TableHeader>

			<TableBody>
				{tableData.map((row: any, iter: number) => (
					<TableRow key={iter}>
						{Object.keys(row).map((cellKey: string) => (
							<TableCell key={cellKey}>{row[cellKey]}</TableCell>
						))}
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
}

interface SmartTargetModalProps {
	selectedSmartTarget: SmartTarget;
	updateSmartTarget: (smartTarget: SmartTarget) => void;
	onClose: () => void;
}

export default function SmartTargetModal({ selectedSmartTarget, updateSmartTarget, onClose }: SmartTargetModalProps) {
	const [filteredLogs, setFilteredLogs] = useState<SmartTargetLogEntry[]>([]);
	const [pollDelay, setPollDelay] = useState<number | null>(5000);
	const [pollDuration, setPollDuration] = useState<number>(0);
	const [logLevel, setLogLevel] = useState<number>(3);

	const filterOptions = [
		{ key: "debug", value: 3, text: "Debug" },
		{ key: "detail", value: 2, text: "Detailed" },
		{ key: "overview", value: 1, text: "Overview" },
	];

	/** Statuses which signify that the job has stopped updating */
	const stopStatuses = [SmartTargetStatus.CANCELLED, SmartTargetStatus.ERROR, SmartTargetStatus.FINISHED];

	const filterLogs = (level: number) => {
		if (selectedSmartTarget.logs) {
			setFilteredLogs(selectedSmartTarget.logs.filter((log: SmartTargetLogEntry) => log.log_level <= level));
		}
	};

	useEffect(() => {
		updateSmartTarget(selectedSmartTarget); // this may be finished, but we still want to update to populate the logs
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedSmartTarget.id]);

	useEffect(() => {
		filterLogs(logLevel);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedSmartTarget.logs]);

	useInterval(() => {
		// don't poll if job finished
		if (selectedSmartTarget && stopStatuses.includes(selectedSmartTarget.status)) {
			setPollDelay(null);
			return;
		}

		updateSmartTarget(selectedSmartTarget);
		setPollDuration(pollDuration + 1);

		if (pollDuration > 20) setPollDelay(2000 * pollDuration); // slow down after a while
	}, pollDelay);

	const onLogFilterChange = (level: number) => {
		setLogLevel(level);
		filterLogs(level);
	};

	/**
	 * Test if the message is in JSON format - it should be rendered as table if so
	 * @param message - message part of the smart target log
	 */
	const isTableData = (message: string): boolean => {
		if (message.startsWith("[{")) {
			try {
				JSON.parse(message);
				return true;
			} catch (e) {
				return false;
			}
		}

		return false;
	};

	return (
		<Modal title={`Smart Target ${selectedSmartTarget.id}`} onClose={onClose}>
			<Table>
				<TableBody>
					{["target", "value", "status", "created_at", "updated_at"].map((field: string) => (
						<TableRow>
							<TableCell>{FriendlyNames.fromColumnName(field)}</TableCell>
							<TableCell>{selectedSmartTarget[field as keyof SmartTarget] as string}</TableCell>
						</TableRow>
					))}
					{selectedSmartTarget.custom_improvement_sets && (
						<>
							<TableRow>
								<TableCell>Suggested Improvement</TableCell>
								<TableCell>{selectedSmartTarget.custom_improvement_sets.name}</TableCell>
							</TableRow>
						</>
					)}
				</TableBody>
			</Table>

			{!!selectedSmartTarget.logs?.length && (
				<>
					<div className="flex-space">
						<Header>Log</Header>
						<Select
							placeholder="Log level"
							options={filterOptions}
							defaultValue={3}
							onChange={(event, data) => onLogFilterChange(Number(data.value))}
						/>
					</div>

					{filteredLogs.map((entry: SmartTargetLogEntry) => (
						<Message key={entry.id} size="tiny" className="smart-target-log">
							<b>{entry.log_time}</b>
							{isTableData(entry.message) ? (
								<RenderMessageAsTable message={entry.message} />
							) : (
								<p>{entry.message}</p>
							)}
						</Message>
					))}
				</>
			)}
			{!stopStatuses.includes(selectedSmartTarget.status) &&  (
				<Preloader />
			)}
		</Modal>
	);
}
