import { createContext, useContext, useState, useEffect, PropsWithChildren } from "react";
import { useRestClientContext } from "./RestClientContext";

import Preloader from "../components/UI/Preloader/Preloader";

export interface EditableFieldValue {
	value: string;
	friendly: string;
}

type EditableFieldValueCollection = Record<string, EditableFieldValue[]>;

const PropertyEditContext = createContext<EditableFieldValueCollection>({});

const PropertyEditContextProvider = ({ children }: PropsWithChildren) => {
	const client = useRestClientContext();
	const [editableFieldValues, setEditableFieldValues] = useState<EditableFieldValueCollection>({});

	useEffect(() => {
		client
			.fetchEditableFieldValues()
			.then((response: Response) => response.json())
			.then((json: EditableFieldValueCollection) => setEditableFieldValues(json));
	}, [client]);

	const { Provider } = PropertyEditContext;

	return (
		<Provider value={editableFieldValues}>
			{editableFieldValues ? children : <Preloader status="Loading editable fields..." />}
		</Provider>
	);
};

export { PropertyEditContextProvider };

export default PropertyEditContext;

export function usePropertyEditContext() {
	return useContext(PropertyEditContext);
}
