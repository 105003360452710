import Uprn from "../Uprn/Uprn";
import EpcRatingTile from "../EpcRatingTile/EpcRatingTile";

import "./Cell.scss";
import { PropertyResponse } from "../../../rest/Property";

interface ReactTableCell {
	row: {
		original: PropertyResponse
	},
	column: {
		id: string;
	},
	render: Function;
	value: string;
	getCellProps: Function;
};

interface CellProps {
	reactTableCell: ReactTableCell;
	displayIntegrityTiles?: boolean;
}

const LABELS_BY_INTEGRITY: Record<number, string> = {
	1: "Assumption",
	2: "Confirmed",
	3: "Cloned",
	4: "EPC",
	5: "Supplied"
};

export default function Cell({ reactTableCell, displayIntegrityTiles }: CellProps) {
	const cell = reactTableCell;
	let children;

	switch (cell.column.id) {
		case "uprn":
			children = <Uprn property={cell.row.original}>{cell.render("Cell")}</Uprn>;
			break;

		case "epc_rating_grade":
			children = (
				<div className="datatable-column-cell-epc">
					<EpcRatingTile grade={cell.value} />
				</div>
			);
			break;

		default:
			children = cell.render("Cell");
			break;
	}

	const props: {
		className: string;
		"data-cell-data-integrity"?: number;
		title?: string;
	} = {
		className: "datatable-column-cell-td"
	};

	if(displayIntegrityTiles && cell.row.original.cell_data_integrity)
	{
		const integrity: number = cell.row.original.cell_data_integrity[cell.column.id];

		props["data-cell-data-integrity"] = integrity;
		props.title = LABELS_BY_INTEGRITY[integrity];
	}

	return (
		<td 
			{...props}
			{...cell.getCellProps()}
		>
			{children}
		</td>
	);
}
