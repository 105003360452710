import FuelTypeSelect from "../UI/FuelTypeSelect/FuelTypeSelect";
import BulkEditModal, { BulkEditModalField, BulkEditModalProps } from "./BulkEditModal";

interface BulkEditFuelTypeModalProps extends BulkEditModalProps {}

export default function BulkEditFuelTypeModal(props: BulkEditFuelTypeModalProps) {
	const fields: BulkEditModalField[] = [
		{
			label: "Fuel Type",
			key: "fuel_type",
			component: FuelTypeSelect,
		},
	];

	return <BulkEditModal {...props} modalProps={{ title: "Edit Fuel Type" }} fields={fields} />;
}
