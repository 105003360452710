import React from "react";

import { Header } from "semantic-ui-react";

import Page from "./Page";
import "./Maintenance.scss";

export default function Maintenance() {
	return (
		<Page className="maintenance-page">
			<div className="maintenance-content">
				<img className="dream-logo-with-text" src="/assets/images/logo-with-text.png" alt="DREam logo" />
				<Header size="huge">Site Under Maintenance</Header>
				<p>Sorry, DREam is undergoing maintenance. We'll be back soon.</p>
			</div>
		</Page>
	);
}
