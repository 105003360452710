import { Header } from "semantic-ui-react";

import Page from "./Page";
import DataTable from "../components/UI/DataTable/DataTable";

export default function EpcData() {
	return (
		<Page>
			<Header as="h1">Data</Header>
			<DataTable></DataTable>
		</Page>
	);
}
