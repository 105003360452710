import { Link } from "react-router-dom";

import { PropertyResponse } from "../../../rest/Property";
import { PropsWithChildren } from "react";

interface UprnProps extends PropsWithChildren
{
	property: PropertyResponse;
}

export default function Uprn({ property, children }: UprnProps) {
	return (
		<Link
			to={{
				pathname: `/epc-data/${property.id}`,
				search: "?from=epc-data",
			}}
			target="_blank"
			rel="noopener noreferrer"
		>
			<span className="uprn">{children}</span>
		</Link>
	);
}
