import { Progress } from "semantic-ui-react";
import { Batch } from "../../../types/types";

interface JobBatchProgressBarProps {
	batch: Batch;
}

export default function JobBatchProgressBar({batch}: JobBatchProgressBarProps)
{
	const completed	= batch.pending_jobs - batch.failed_jobs;
	const percent	= Math.round(100 * (1 - (completed / batch.total_jobs)));
	const finished	= batch.total_jobs - completed;

	return <Progress 
		percent={percent}
		autoSuccess
	>
		{finished} / {batch.total_jobs}
	</Progress>
}
