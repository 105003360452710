import MapboxDraw from "@mapbox/mapbox-gl-draw";
import FreehandMode from "mapbox-gl-draw-freehand-mode";
import { ControlPosition, MapRef, useControl } from "react-map-gl";
import _ from "lodash";

import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { MapboxDrawOptions } from "@mapbox/mapbox-gl-draw";
import { useEffect } from "react";

interface FreehandDrawControlProps extends MapboxDrawOptions {
	position: ControlPosition;
	onReady?: Function;
	mapGl: MapRef | null;
}

export default function FreehandDrawControl(props: FreehandDrawControlProps) {
	useControl(
		() => {
			const SimpleSelectDisableDrag = _.extend(MapboxDraw.modes.simple_select, {
				dragMove() {},
			});
			const DirectSelectDisableDrag = _.extend(MapboxDraw.modes.direct_select, {
				dragFeature() {},
			});
			const instance = new MapboxDraw({
				...props,
				modes: {
					...MapboxDraw.modes,
					simple_select: SimpleSelectDisableDrag,
					direct_select: DirectSelectDisableDrag,
					draw_polygon: FreehandMode,
				},
			});

			if (props.onReady) props.onReady(instance);

			return instance;
		},
		{
			position: props.position,
		}
	);

	useEffect(() => {
		if (props.mapGl) {
			// disable polygon mouseoves
			props.mapGl.on("mouseenter", "gl-draw-polygon-fill-active.hot", () => {
				props.mapGl!.getCanvas().style.cursor = "default";
			});
			props.mapGl.on("mouseenter", "gl-draw-polygon-fill-active.cold", () => {
				props.mapGl!.getCanvas().style.cursor = "default";
			});
			props.mapGl.on("mouseleave", "gl-draw-polygon-fill-active.hot", () => {
				props.mapGl!.getCanvas().style.cursor = "";
			});
			props.mapGl.on("mouseleave", "gl-draw-polygon-fill-active.cold", () => {
				props.mapGl!.getCanvas().style.cursor = "";
			});

			// this is not ideal but seems like the only way to change the tooltip
			document
				.querySelector(".mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_trash")
				?.setAttribute("title", "Delete a Polygon (select a polygon to delete)");
		}
	}, [props.mapGl]);

	return null;
}
