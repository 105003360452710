import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Header, Message } from "semantic-ui-react";
import Preloader from "../components/UI/Preloader/Preloader";
import { useRestClientContext } from "../contexts/RestClientContext";
import { DateTime } from "luxon";

import Page from "./Page";

import "./ImportMonitor.scss";

export default function ImportMonitor() {
	const client = useRestClientContext();
	const params = useParams();

	const [loading, setLoading] = useState(true);
	const [data, setData] = useState();
	const [iteration, setIteration] = useState(0);

	useEffect(() => {
		if (iteration === 0) setLoading(true);

		client
			.fetchImport(params.import_id)
			.then((response) => response.json())
			.then((data) => {
				setData(data);
				setLoading(false);
			});

		const timeoutId = setTimeout(() => {
			setIteration(iteration + 1);
		}, 5000);

		return () => {
			clearTimeout(timeoutId);
		};
	}, [client, params.import_id, iteration]);

	const formatDateTime = (dateTime) => DateTime.fromISO(dateTime).toLocaleString(DateTime.DATETIME_FULL);

	return (
		<Page>
			<Header as="h1">Import {params.import_id}</Header>
			{loading ? (
				<Preloader />
			) : (
				<>
					<section role="status">
						<p>
							{data.filename} created at {formatDateTime(data.created_at)}
						</p>
						<p>
							<strong>{data.status}</strong> (Last updated at {formatDateTime(data.updated_at)})
						</p>
						<p>
							<progress max="1" value={data.progress} />
						</p>
					</section>
					{data.status && data.status.toLowerCase() === "failed" && (
						<Message negative>
							<Message.Header>Error processing file</Message.Header>
							<p>
								Something went wrong when processing your file. Check the log below for more
								information.
							</p>
						</Message>
					)}
					{data.log_entries.length ? (
						<ol id="import-monitor-log-entries">
							{data.log_entries.map((entry) => {
								const props = {
									[entry.type]: true,
								};

								return (
									<li key={entry.id}>
										<Message {...props}>
											<p>{entry.message}</p>
											<time dateTime={entry.created_at}>{formatDateTime(entry.created_at)}</time>
										</Message>
									</li>
								);
							})}
						</ol>
					) : (
						<Message>No log entries to display</Message>
					)}
				</>
			)}
		</Page>
	);
}
