import { Header, Tab } from "semantic-ui-react";

import Page from "./Page";
import ImportProperties from "../components/Import/ImportProperties";
import ImportPropertyImages from "../components/Import/ImportPropertyImages";

export default function Import() {
	const panes = [
		{
			menuItem: "Import Property Data",
			render: () => (
				<Tab.Pane>
					<ImportProperties />
				</Tab.Pane>
			),
		},
		{
			menuItem: "Import Property Images",
			render: () => (
				<Tab.Pane>
					<ImportPropertyImages />
				</Tab.Pane>
			),
		},
	];

	return (
		<Page>
			<Header as="h1">Import (Beta)</Header>
			<Tab panes={panes} />
		</Page>
	);
}
