import { BulkEditModalField, BulkEditModalProps } from "./BulkEditModal";

import WallTypeSelect from "../UI/WallTypeSelect/WallTypeSelect";
import BulkEditModal from "./BulkEditModal";

interface BulkEditWallTypeModalProps extends BulkEditModalProps {

}

export default function BulkEditWallTypeModal(props: BulkEditWallTypeModalProps) {
	const fields: BulkEditModalField[] = [
		{
			label: "Wall Type",
			key: "wall_type",
			component: WallTypeSelect,
		},
	];

	return <BulkEditModal {...props} modalProps={{ title: "Edit Wall Type" }} fields={fields} />;
}
