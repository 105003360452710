import { PropsWithChildren } from "react";
import "./Right.scss";

interface RightProps extends PropsWithChildren {};

export default function Right({children}: RightProps)
{
	return <div className="right">
		{children}
	</div>;
}