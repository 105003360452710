import React from "react";
import { RefreshVector } from "../../../assets/RefreshVector";
// import { SettingsVector } from "../../../assets/SettingsVector";
import Alert from "../../UI/Alert/Alert";
import ProfileWidget from "../../UI/ProfileWidget/ProfileWidget";
import "./HeaderButtons.scss";

const HeaderButtons = () => {
	return (
		<div className="header-buttons">
			<RefreshVector />
			{/* <SettingsVector /> */}
			<Alert numUnreadNotifications={0} />
			<ProfileWidget />
		</div>
	);
};

export default HeaderButtons;
