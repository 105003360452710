import React from "react";

const MappingVector = () => {
	return (
		<svg width="24" stroke="#9395A2" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g>
				<path
					d="M1 6V22L8 18L16 22L23 18V2L16 6L8 2L1 6Z"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path d="M8 2V18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M16 6V22" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			</g>
		</svg>
	);
};

export default MappingVector;
