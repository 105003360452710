import folderPlus from "../../../assets/Img/folder-plus.png";
import "./RequestNewWidget.scss";

export default function RequestNewWidget() {
	return (
		<a
			href="https://irtsurveys.atlassian.net/servicedesk/customer/portals/"
			target="_blank"
			rel="noopener noreferrer"
			className="request-new-widget"
		>
			<div>
				<img src={folderPlus} alt="New Folder Icon"></img>
			</div>
			<div>Request a new widget</div>
		</a>
	);
}
