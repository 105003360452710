import { Container, Header, Divider, Icon } from "semantic-ui-react";
import "./Card.scss";
import { CSSProperties, PropsWithChildren } from "react";

interface CardProps extends PropsWithChildren {
	title?: string;
	minHeight?: number;
	className?: string;
	onClose?: Function;
	handleMinimise?: Function;
	isMinimised?: boolean;
}

export default function Card(props: CardProps) {
	const { title, children, minHeight, className, onClose, handleMinimise, isMinimised, ...passthrough } = props;

	let classNameString = "card";
	let style: CSSProperties = {};

	if (className) classNameString += ` ${className}`;

	if (minHeight) style.minHeight = minHeight;

	return (
		<Container className={classNameString} fluid style={style} {...passthrough}>
			{title && title.length && (
				<>
					<div className="header-container">
						<Header as={isMinimised ? "h4" : "h2"}>{title}</Header>
						<div className="control-buttons">
							{handleMinimise && (
								<Icon
									name={isMinimised ? "window maximize" : "window minimize"}
									onClick={() => handleMinimise()}
								/>
							)}
							{onClose && <Icon name="close" onClick={() => onClose()}></Icon>}
						</div>
					</div>
					{!isMinimised && <Divider />}
				</>
			)}
			{children}
		</Container>
	);
}
