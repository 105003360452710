import PreviousVector from "../../../assets/PreviousVector";
import Button, { ButtonProps } from "./Button";

export default function Rewind({ disabled, onClick }: ButtonProps) {
	const stroke = disabled ? "#9395a2" : "#ff5f43";

	return (
		<Button variant="text" onClick={() => onClick!()}>
			<PreviousVector stroke={stroke} fill="none" />
			<PreviousVector stroke={stroke} fill="none" />
		</Button>
	);
}
