import { ResponsiveLine } from "@nivo/line";
import { sizeFormatter } from "human-readable";

import { LineSvgProps } from "@nivo/line";

interface CO2ChartProps extends LineSvgProps {}

export default function CO2Chart(props: CO2ChartProps) {
	const formatter = sizeFormatter({
		keepTrailingZeroes: false,
		render: (literal, symbol) => `${literal}${symbol}`,
	});

	return (
		<div className="net-zero-journey-chart">
			<ResponsiveLine
				{...props}
				colors={["#43936c", "#7f7f7f"]}
				axisLeft={{
					legend: "SAP",
					legendPosition: "middle",
					legendOffset: -60,
					format: (value) => formatter(value),
				}}
				layers={["grid", "axes", "lines", "markers", "legends"]}
			/>
		</div>
	);
}
