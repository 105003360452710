import { Table } from "semantic-ui-react";

import Card from "../UI/Card/Card";
import addExternalWallInsulationImprovements from "./addExternalWallInsulationImprovements";

import applyDynamicWallTypeFields from "./applyDynamicWallTypeFields";
import Property from "../../rest/Property";

interface ImprovementsAvailableCardProps {
	property: Property;
}

export default function ImprovementsAvailableCard({ property }: ImprovementsAvailableCardProps) {
	const sap = property.data.sap_data;

	const data = [
		{
			name: "Triple Glazing",
			tooltip: "Installation of 100% Triple Glazing u-value = 1.1 W/m2",
			sapVal: sap.improveddg_triplesapvalue,
			co2Val: sap.improveddg_tripleco2value,
			co2Saving: sap.improveddg_tripleco2saving,
			costSaving: sap.improveddg_triplecostsaving,
			heatDemand: sap.improved_dg_a_heat_demand,
		},
		{
			name: "Underfloor Insulation",
			tooltip: "Underfloor insulation providing u-value = 0.25 W/m2",
			sapVal: sap.improvedunderfloorinsulationsapvalue,
			co2Val: sap.improvedunderfloorinsulationco2value,
			co2Saving: sap.improvedunderfloorinsulationco2saving,
			costSaving: sap.improvedunderfloorinsulationcostsaving,
			heatDemand: sap.improved_underfloor_insulation_heat_demand,
		},
		{
			name: "Air Source Heat Pump",
			tooltip: "Installation of Air Source Heat Pump with efficiency of 250% on a Standard Tariff",
			sapVal: sap.improvedairsourceheatsapvalue,
			co2Val: sap.improvedairsourceheatco2value,
			co2Saving: sap.improvedairsourceheatco2saving,
			costSaving: sap.improvedairsourceheatcostsaving,
			heatDemand: sap.improved_air_source_heat_heat_demand,
		},
		{
			name: "Ground Source Heat Pump",
			tooltip: "Installation of Ground Source Heat Pump with efficiency of 350% on a Standard Tariff",
			sapVal: sap.improvedgroundsourceheatsapvalue,
			co2Val: sap.improvedgroundsourceheatco2value,
			co2Saving: sap.improvedgroundsourceheatco2saving,
			costSaving: sap.improvedgroundsourceheatcostsaving,
			heatDemand: sap.improved_ground_source_heat_heat_demand,
		},
		{
			name: "Loft Insulation (400mm)",
			tooltip: "Installation of 400mm loft insulation providing u-value  = 0.18 W/m2",
			sapVal: sap.improvedloft400sapvalue,
			co2Val: sap.improvedloft400co2value,
			co2Saving: sap.improvedloft400co2saving,
			costSaving: sap.improvedloft400costsaving,
			heatDemand: sap.improved_loft400_heat_demand,
		},
		{
			name: "Solar Panels (1.5kW)",
			tooltip: "Installation of 1.5 kWp solar array - will assume South facing with some overshading",
			sapVal: sap.improvedpv15kwsapvalue,
			co2Val: sap.improvedpv15kwco2value,
			co2Saving: sap.improvedpv15kwco2saving,
			costSaving: sap.improvedpv15kwcostsaving,
			heatDemand: sap.improved_pv15kw_heat_demand,
		},
		{
			name: "Solar Panels (2.5kW)",
			tooltip: "Installation of 2.5 kWp solar array - will assume South facing with some overshading",
			sapVal: sap.improvedpv25kwsapvalue,
			co2Val: sap.improvedpv25kwco2value,
			co2Saving: sap.improvedpv25kwco2saving,
			costSaving: sap.improvedpv25kwcostsaving,
			heatDemand: sap.improved_pv25kw_heat_demand,
		},
		{
			name: "Solar Panels (3.5kW)",
			tooltip: "Installation of 3.5 kWp solar array - will assume South facing with some overshading",
			sapVal: sap.improvedpv35kwsapvalue,
			co2Val: sap.improvedpv35kwco2value,
			co2Saving: sap.improvedpv35kwco2saving,
			costSaving: sap.improvedpv35kwcostsaving,
			heatDemand: sap.improved_pv35kw_heat_demand,
		},
		{
			name: "50mm Internal Wall Insulation",
			tooltip: "Installation of 50mm Internal Wall Insulation",
			sapVal: sap.improvedwi50sapvalue,
			co2Val: sap.improvedwi50co2value,
			co2Saving: sap.improvedwi50co2saving,
			costSaving: sap.improvedwi50costsaving,
			heatDemand: sap.improved_ewi50_heat_demand,
		},
		{
			name: "100mm Internal Wall Insulation",
			tooltip: "Installation of 100mm Internal Wall Insulation",
			sapVal: sap.improvedwi100sapvalue,
			co2Val: sap.improvedwi100co2value,
			co2Saving: sap.improvedwi100co2saving,
			costSaving: sap.improvedwi100costsaving,
			heatDemand: sap.improved_ewi100_heat_demand,
		},
		{
			name: "150mm Internal Wall Insulation",
			tooltip: "Installation of 150mm Internal Wall Insulation",
			sapVal: sap.improvedwi150sapvalue,
			co2Val: sap.improvedwi150co2value,
			co2Saving: sap.improvedwi150co2saving,
			costSaving: sap.improvedwi150costsaving,
			heatDemand: sap.improved_ewi150_heat_demand,
		},
		{
			name: "200mm Internal Wall Insulation",
			tooltip: "Installation of 200mm Internal Wall Insulation",
			sapVal: sap.improvedwi200sapvalue,
			co2Val: sap.improvedwi200co2value,
			co2Saving: sap.improvedwi200co2saving,
			costSaving: sap.improvedwi200costsaving,
			heatDemand: sap.improved_ewi200_heat_demand,
		},
		{
			name: "Party Wall Insulation",
			tooltip: "Installation of Party Wall insulation where possible",
			sapVal: sap.improvedpartywallsapvalue,
			co2Val: sap.improvedpartywallco2value,
			co2Saving: sap.improvedpartywallco2saving,
			costSaving: sap.improvedpartywallcostsaving,
			heatDemand: sap.improved_party_wall_heat_demand,
		},
		{
			name: "Convert Main Heating source to Gas",
			tooltip: "Conversion of current heating system to a Gas system with an A Rated Boiler",
			sapVal: sap.improvedmainheat2gassapvalue,
			co2Val: sap.improvedmainheat2gasco2value,
			co2Saving: sap.improvedmainheat2gasco2saving,
			costSaving: sap.improvedmainheat2gascostsaving,
			heatDemand: sap.improved_main_heat2_gas_heat_demand,
		},
		{
			name: "Heating Controls",
			tooltip: "Improved controls for heating system and appliances",
			sapVal: sap.improvedheatingcontrolssapvalue,
			co2Val: sap.improvedheatingcontrolsco2value,
			co2Saving: sap.improvedheatingcontrolsco2saving,
			costSaving: sap.improvedheatingcontrolscostsaving,
			heatDemand: sap.improved_heating_controls_heat_demand,
		},
		{
			name: "Smart Heating Controls",
			tooltip: "Installation of smart control system for heating and appliances",
			sapVal: sap.improvedsmartcontrolssapvalue,
			co2Val: sap.improvedsmartcontrolsco2value,
			co2Saving: sap.improvedsmartcontrolsco2saving,
			costSaving: sap.improvedsmartcontrolscostsaving,
			heatDemand: sap.improved_smart_controls_heat_demand,
		},
		{
			name: "Solar Thermal Water Heating",
			tooltip: "Installation of 10m2 of Solar Thermal water heating, assumed South facing",
			sapVal: sap.improvedsolarthermalsapvalue,
			co2Val: sap.improvedsolarthermalco2value,
			co2Saving: sap.improvedsolarthermalco2saving,
			costSaving: sap.improvedsolarthermalcostsaving,
			heatDemand: sap.improved_solar_thermal_heat_demand,
		},
		{
			name: "A Rated Boiler",
			tooltip: "Replacement of current boiler with A rated gas combi boiler",
			sapVal: sap.improved_ch_sap_value,
			costSaving: sap.improved_ch_cost_saving,
			co2Val: sap.improved_ch_co2_value,
			co2Saving: sap.improved_ch_co2_saving,
			heatDemand: sap.improved_ch_heat_demand,
		},
		{
			name: "100% Draught Proofing",
			tooltip: "Installation of draught proofing measures",
			sapVal: sap.improved_dpsap_value,
			costSaving: sap.improved_dp_cost_saving,
			co2Val: sap.improved_dp_co2_value,
			co2Saving: sap.improved_dp_co2_saving,
			heatDemand: sap.improved_dp_heat_demand,
		},
		{
			name: "100% Low Energy Lighting",
			tooltip: "Installation of Low Energy Lighting to all outlets",
			sapVal: sap.improved_lighting_sap_value,
			costSaving: sap.improved_lighting_cost_saving,
			co2Val: sap.improved_lighting_co2_value,
			co2Saving: sap.improved_lighting_co2_saving,
			heatDemand: sap.improved_lighting_heat_demand,
		},
		{
			name: "Loft Insulation (270mm)",
			tooltip: "Increased loft insulation to 270mm",
			sapVal: sap.improved_roof_sap_value,
			costSaving: sap.improved_roof_cost_saving,
			co2Val: sap.improved_roof_co2_value,
			co2Saving: sap.improved_roof_co2_saving,
			heatDemand: sap.improved_roof_heat_demand,
		},
		{
			id: "installation-of-wall-insulation",
			name: property.data.wall_type,
			tooltip: "Installation of wall insulation [as per intelligent lookup]",
			sapVal: sap.improved_walls_sap_value,
			costSaving: sap.improved_walls_cost_saving,
			co2Val: sap.improved_walls_co2_value,
			co2Saving: sap.improved_walls_co2_saving,
			heatDemand: sap.improved_walls_heat_demand,
		},
		{
			name: "Current Building Regulations",
			tooltip: "To Current Building Regulations",
			sapVal: sap.improved_to_cbr_sap_value,
			costSaving: sap.improved_to_cbr_cost_saving,
			co2Val: sap.improved_to_cbr_co2_value,
			co2Saving: sap.improved_to_cbr_co2_saving,
			heatDemand: sap.improved_to_cbr_heat_demand,
		},
	];

	const installationOfWallInsulation = data.find((obj) => obj.id === "installation-of-wall-insulation");

	if (installationOfWallInsulation) applyDynamicWallTypeFields(installationOfWallInsulation, property);

	addExternalWallInsulationImprovements(data, property);

	return (
		<Card title="Improvements Available">
			<Table celled>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>Improvement Name</Table.HeaderCell>
						<Table.HeaderCell>SAP Value</Table.HeaderCell>
						<Table.HeaderCell>EI Value</Table.HeaderCell>
						<Table.HeaderCell>CO2 Saving (kgs per year)</Table.HeaderCell>
						<Table.HeaderCell>Cost Saving (£s per year)</Table.HeaderCell>
						<Table.HeaderCell>Heat Demand (kWh/m2 per year)</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{data
						.filter(
							(obj) =>
								(obj.costSaving != null && obj.costSaving > 0) ||
								obj.name === "Air Source Heat Pump" ||
								obj.name === "Ground Source Heat Pump"
						)
						.map((obj, index) => {
							return (
								<Table.Row key={index}>
									<Table.Cell>{obj.name}</Table.Cell>
									<Table.Cell>{parseInt(obj.sapVal)}</Table.Cell>
									<Table.Cell>{parseInt(obj.co2Val)}</Table.Cell>
									{/* TODO: Have a look at this please, why on earth would there be a backspace in this data?! Should be safe to rely on the v2 server to always cast this to float and just round it */}
									<Table.Cell>
										{obj.co2Saving?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
									</Table.Cell>
									<Table.Cell>
										{obj.costSaving?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
									</Table.Cell>
									<Table.Cell>{obj.heatDemand}</Table.Cell>
								</Table.Row>
							);
						})}
				</Table.Body>
			</Table>
		</Card>
	);
}
