import { Tab } from "semantic-ui-react";

import Modal from "../Modal/Modal";

import { CustomImprovementsModalPanelProps } from "../../types/types";

import CustomPanel from './CustomPanel';
import HistoryPanel from "./HistoryPanel";
// import PresetsPanel from "./PresetsPanel";

import "./CustomImprovementSetsModal.scss";
import SuggestedPanel from "./SuggestedPanel";
import SmartTargetsPanel from "./SmartTargetsPanel";
import { useUserContext } from "../../contexts/UserContext";

export default function CustomImprovementSetsModal({ teamId, property, data, onComplete, onClose }: CustomImprovementsModalPanelProps) {

	const user = useUserContext();

	const panes = [
		{
			menuItem: "Custom",
			render: () => <Tab.Pane>
				<CustomPanel 
					teamId={teamId}
					property={property}
					data={data} 
					onComplete={onComplete} 
					onClose={onClose} 
				/>
			</Tab.Pane>
		},
		/* {
			menuItem: "Presets",
			render: () => <Tab.Pane>
				<PresetsPanel 
					resource={resource} 
					data={data} 
					onComplete={onComplete} 
					onClose={onClose} 
				/>
			</Tab.Pane>
		}, */
		{
			menuItem: "History",
			render: () => <Tab.Pane>
				<HistoryPanel 
					teamId={teamId}
					property={property}
					data={data} 
					onComplete={onComplete} 
					onClose={onClose} 
				/>
			</Tab.Pane>
		}
	];

	if(property)
	{
		panes.splice(1, 0, {
			menuItem: "Suggested",
			render: () => <Tab.Pane>
				<SuggestedPanel 
					property={property}
					data={data} 
					onComplete={onComplete} 
					onClose={onClose} 
				/>
			</Tab.Pane>
		});

		if(user.is_admin)
			panes.push({
				menuItem: "Smart Targets",
				render: () => <Tab.Pane>
					<SmartTargetsPanel
						property={property}
					/>
				</Tab.Pane>
			});
	}

	// TOOD: Can we break this up a bit into smaller components, if possible?
    return (
        <Modal title="Update Custom Measures" onClose={() => onClose()}>
			<Tab 
				panes={panes} 
			/>
        </Modal>
    );
}
