import { useState } from "react";
import { Grid } from "semantic-ui-react";
import Button from "../../UI/Button/Button";
import ExplainerModal from "./ExplainerModal";

interface Item
{
	title: string;
	link?: string;
}

export default function InitiativeButtons() {
	const items: Item[] = [
		{
			title: "NetZero",
			link: "https://app.powerbi.com/groups/47eab3b1-520c-4556-953d-11d5f9e37fee/reports/4482aff4-25db-4401-973b-b28ca73616f5/ReportSectioneec30c8eb78f5bafaa79?ctid=44bc7c72-1a46-4d32-aebb-041a8e58857c",
		},
		{
			title: "SHDF",
			link: "https://app.powerbi.com/groups/47eab3b1-520c-4556-953d-11d5f9e37fee/reports/4482aff4-25db-4401-973b-b28ca73616f5/ReportSectionc9d139659dfcfe821693?ctid=44bc7c72-1a46-4d32-aebb-041a8e58857c",
		},
		{
			title: "EESSH2",
			link: "https://app.powerbi.com/groups/47eab3b1-520c-4556-953d-11d5f9e37fee/reports/4482aff4-25db-4401-973b-b28ca73616f5/ReportSectiona24a4e4d404c9c53ed19?ctid=44bc7c72-1a46-4d32-aebb-041a8e58857c",
		},
		{
			title: "HUG",
			link: "https://app.powerbi.com/links/8fewzbwd0Y?ctid=44bc7c72-1a46-4d32-aebb-041a8e58857c&pbi_source=linkShare&bookmarkGuid=2355b8ae-1cbb-41cf-93d8-75f3197feaa0"
		},
		{
			title: "ECO4",
			link: "https://app.powerbi.com/groups/47eab3b1-520c-4556-953d-11d5f9e37fee/reports/4482aff4-25db-4401-973b-b28ca73616f5/ReportSectiona3d594c8572cede8ab88?ctid=44bc7c72-1a46-4d32-aebb-041a8e58857c",
		},
		{
			title: "ORP",
			link: "https://app.powerbi.com/links/8fewzbwd0Y?ctid=44bc7c72-1a46-4d32-aebb-041a8e58857c&pbi_source=linkShare&bookmarkGuid=cb40a5a0-05ac-4ec8-a1b1-1be2451cf57d"
		},
		{
			title: "SHNZHF",
			link: "https://app.powerbi.com/links/8fewzbwd0Y?ctid=44bc7c72-1a46-4d32-aebb-041a8e58857c&pbi_source=linkShare&bookmarkGuid=fc8250e7-a744-4f3c-82fd-578b3fbd9b78",
		},
	];

	const [isModalOpen, setIsModalOpen] = useState(false);

	const onButtonClick = () => {
		setIsModalOpen(true);
	};

	const onCloseExplainer = () => {
		setIsModalOpen(false);
	};

	return (
		<>
			<Grid columns="equal" className="standards">
				{items.map((obj) => (
					<Grid.Column key={obj.title}>
						{"link" in obj ? (
							<a href={obj.link} target="_BLANK" rel="noreferrer">
								<Button variant="contained">{obj.title}</Button>
							</a>
						) : (
							<Button variant="contained" onClick={onButtonClick}>
								{obj.title}
							</Button>
						)}
					</Grid.Column>
				))}
			</Grid>
			{isModalOpen && <ExplainerModal closeIcon onClose={onCloseExplainer} />}
		</>
	);
}
