import React from "react";
import { Grid } from "semantic-ui-react";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";

import FolderVector from "../../../assets/FolderVector";

import { Group } from "../../../types/types";

interface FavouriteGroupCardProps extends Omit<Group, "inserted_at"> {
	date: string;
};

const FavouriteGroupCard = (props: FavouriteGroupCardProps) => {
	const datetime = DateTime.fromISO(props.date);

	return (
		<Grid verticalAlign="middle">
			<Link to={`/epc-data?group=${props.id}`}>
				<Grid.Column>
					<FolderVector />
				</Grid.Column>
				<Grid.Column width={12}>
					<Grid.Row>
						<Grid.Column>{props.name}</Grid.Column>
						<Grid.Column>
							<p style={{ opacity: 0.5 }}>{datetime.toRelative()}</p>
						</Grid.Column>
					</Grid.Row>
				</Grid.Column>
			</Link>
		</Grid>
	);
};

export default FavouriteGroupCard;
