import { useState } from "react";
import { Link, useNavigate } from "../../../shims/useNavigate";
import { PropertyResponse } from "../../../rest/Property";
import { GroupPropertyModalMode } from "../../Modal/GroupPropertyModal";

import Button from "../Button/Button";
import GroupPropertyModal from "../../Modal/GroupPropertyModal";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import { useRestClientContext } from "../../../contexts/RestClientContext";
import { useTeamContext } from "../../../contexts/TeamContext";
import { Group } from "../../../types/types";
import { PropertyQuery } from "../../../rest/Client";

import "./GroupButtons.scss";

interface GroupButtonsProps {
	selectedProperties: PropertyResponse[];
	showRemove?: boolean;
	filters?: PropertyQuery;
	/** Will default to the group IDs (number[]) if passed by search param */
	groups?: Group[] | number[];
	onUpdate?: () => void;
}

export default function GroupButtons({ selectedProperties, filters, showRemove, groups, onUpdate }: GroupButtonsProps) {
	const client = useRestClientContext();
	const team = useTeamContext();
	const navigate = useNavigate();

	const [groupPropertyModalMode, setGroupPropertyModalMode] = useState<GroupPropertyModalMode | null>(null);
	const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
	const [deleting, setDeleting] = useState(false);

	const onCreateGroup = () => {
		setGroupPropertyModalMode(GroupPropertyModalMode.CREATE);
	};

	const onAddToGroup = () => {
		setGroupPropertyModalMode(GroupPropertyModalMode.ADD);
	};

	const removeFromGroup = () => {
		setGroupPropertyModalMode(GroupPropertyModalMode.REMOVE);
	};

	const onCloseGroupModal = () => {
		setGroupPropertyModalMode(null);
		if (onUpdate) onUpdate();
	};

	const onDeleteGroup = () => {
		setConfirmDeleteModalOpen(true);
	};

	const onConfirmDeleteGroup = () => {
		if (groups?.length) {
			// group can either be an ID or a Group object
			const groupId = typeof groups[0] === "number" ? groups[0] : groups[0].id;
			setDeleting(true);
			client
				.deleteGroup(team.id, groupId)
				.then(() => {
					navigate("/groups", { replace: true });
				})
				.finally(() => setDeleting(false));
		}
	};

	return (
		<div className="group-button-container">
			<Link to="/groups">
				<Button variant="contained">View Groups</Button>
			</Link>
			<Button variant="outlined" onClick={onCreateGroup}>
				Create Group
			</Button>
			<Button variant="outlined" onClick={onAddToGroup}>
				Add to Group
			</Button>
			{showRemove && (
				<Button
					variant="outlined"
					disabled={!(groups?.length === 1)}
					onClick={removeFromGroup}
				>
					Remove from group
				</Button>
			)}
			{groups && (
				<Button variant="outlined" onClick={onDeleteGroup} disabled={groups?.length !== 1}>
					Delete Group
				</Button>
			)}
			{groupPropertyModalMode && (
				<GroupPropertyModal
					selectedProperties={selectedProperties}
					filters={filters}
					mode={groupPropertyModalMode}
					onClose={onCloseGroupModal}
					group={groups && groups[0]}
				/>
			)}
			<ConfirmationModal
				title="Delete group?"
				open={confirmDeleteModalOpen}
				confirmationText={deleting ? "Please wait..." : "OK"}
				buttonsDisabled={deleting}
				onCancel={() => setConfirmDeleteModalOpen(false)}
				onConfirm={() => onConfirmDeleteGroup()}
			>
				Are you sure you want to delete this group?
			</ConfirmationModal>
		</div>
	);
}
