import Select from "../Select/Select";

import Role from "../../../rest/Role";
import { SelectProps } from "../Select/Select";

interface RoleSelectProps extends SelectProps {}

export default function RoleSelect(props: RoleSelectProps) {
	const roles = [
		{
			key: Role.USER,
			value: Role.USER,
			text: "User",
		},
		{
			key: Role.MANAGER,
			value: Role.MANAGER,
			text: "Manager",
		},
	];

	return <Select {...props} options={roles} />;
}
