import React from "react";
import EpcRatingTile from "../../UI/EpcRatingTile/EpcRatingTile";
import "./ChartLegend.scss";

const ChartLegend = () => {
	return (
		<div className="your-spending-chart-legend">
			<div>
				<div>
					<div className="dashed-line-swatch"></div>
					<span>Actual</span>
					{/* TODO: Misuse of EPC rating tiles here, this is a chart legend, it's nothing to do with EPC ratings */}
					<EpcRatingTile grade="C" compact />
					<span>CO2 Target</span>
					<EpcRatingTile grade="F" compact />
					<span>Budget Required</span>
				</div>
			</div>
		</div>
	);
};

export default ChartLegend;
