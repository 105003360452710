import { useState, useCallback, useEffect } from "react";
import env from "react-dotenv";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import { Header, Grid, Message } from "semantic-ui-react";

import { useRestClientContext } from "../contexts/RestClientContext";
import { useUserContext } from "../contexts/UserContext";

import Page from "./Page";
import Preloader from "../components/UI/Preloader/Preloader";
import Property from "../rest/Property";
import Button from "../components/UI/Button/Button";
import BackChevronVector from "../assets/BackChevronVector";
import DetailsCard from "../components/Property/DetailsCard";
import CustomImprovementsCard from "../components/Property/CustomImprovementsCard";
import ImprovementsAvailableCard from "../components/Property/ImprovementsAvailableCard";
import EnergyUsageCard from "../components/Property/EnergyUsageCard";
import Co2EnergyOptimisationCard from "../components/Property/Co2EnergyOptimisationCard";
import PropertyImagesCard from "../components/Property/PropertyImagesCard";
import EpcSuggestionsModal from "../components/Property/EpcSuggestionsModal";

import "./Property.scss";
import AdminToolsCard from "../components/Property/AdminToolsCard";
import ConfirmationModal from "../components/Modal/ConfirmationModal";
import CustomUpdateButton from "../components/CustomImprovements/CustomUpdateButton";
import useUpdateTrigger from "../hooks/useUpdateTrigger";

export default function PropertyPage(props) {
	const { id } = useParams();
	const client = useRestClientContext();
	const user = useUserContext();
	const navigate = useNavigate();
	const [error, setError] = useState(null);
	const [epcSuggestionsOpen, setEpcSuggestionsOpen] = useState(false);
	const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const { trigger, triggerUpdate } = useUpdateTrigger();

	const onOpenEpcSuggestions = () => {
		setEpcSuggestionsOpen(true);
	};

	//getting the previous page from url - couldn't get anything without URLSearchParams working bc I think react-router-dom took away the function I wanted
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const from = queryParams.has("from") ? queryParams.get("from") : "epc-data";

	const { isLoaded: isMapLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: env.GOOGLE_MAPS_API_KEY,
	});

	const [property, setProperty] = useState(null);

	// NB: Just ignore this... we want setMap but we don't need to read map at the moment
	// eslint-disable-next-line no-unused-vars
	const [map, setMap] = useState(null);

	const onLoad = useCallback(function callback(map) {
		setMap(map);
	}, []);

	const onUnmount = useCallback(function callback(map) {
		setMap(null);
	}, []);

	const onDeleteProperty = () => {
		setConfirmDeleteModalOpen(true);
	};

	const onConfirmDeleteProperty = () => {

		setIsDeleting(true);

		client
			.deleteProperty(property.team_id, property.id)
			.then(() => {
				navigate("/");
			});

	};
	
	useEffect(() => {
		async function fetchData() {
			const response = await client.fetchProperty(user.team_id, id);
			const data = await response.json();

			if (data.error) {
				setError(data.error);
				return;
			}

			setProperty(new Property(data));
		}

		setProperty(null);

		try {
			fetchData();
		} catch (error) {
			console.error(error);
			debugger;
		}
	}, [client, user.team_id, id, trigger]);

	let children;

	if (error) {
		children = (
			<Message negative>
				<Message.Header>{error.code}</Message.Header>
				<p>{error.message}</p>
			</Message>
		);
	}
	else if (!property || !isMapLoaded) 
		children = <Preloader status="Loading property..." />;
	else {
		children = (
			<>
				<Header as="h1">{property.fullAddress}</Header>
				<GoogleMap
					mapContainerStyle={{
						width: "100%",
						height: "50vh",
					}}
					center={property.googleLatLng}
					zoom={18}
					onLoad={onLoad}
					onUnmount={onUnmount}
				>
					{/* NB: Using MarkerF because Marker doesn't work presently, see https://github.com/JustFly1984/react-google-maps-api/issues/3019 */}
					<MarkerF position={property.googleLatLng} />
				</GoogleMap>
				{user.is_admin && <AdminToolsCard property={property} />}
				<DetailsCard property={property}>
					<Button variant="contained" onClick={onOpenEpcSuggestions}>
						Compare &amp; Update
					</Button>
				</DetailsCard>
				<CustomImprovementsCard property={property}>
					<CustomUpdateButton property={property} onUpdateProperty={triggerUpdate}/>
				</CustomImprovementsCard>
				<Grid className="charts">
					<Grid.Row>
						<Grid.Column>
							<EnergyUsageCard property={property} />
						</Grid.Column>
						<Grid.Column>
							<Co2EnergyOptimisationCard property={property} />
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<ImprovementsAvailableCard property={property} />
				<PropertyImagesCard property={property} />

				<Button 
					variant="contained"
					onClick={onDeleteProperty}
				>
					Delete Property
				</Button>

				{epcSuggestionsOpen && (
					<EpcSuggestionsModal property={property} onClose={() => setEpcSuggestionsOpen(false)} />
				)}

				<ConfirmationModal 
					open={confirmDeleteModalOpen}
					title="Confirm delete"
					confirmationText="Confirm"
					buttonsDisabled={isDeleting}
					onConfirm={onConfirmDeleteProperty}
					onClose={() => setConfirmDeleteModalOpen(false)}
				>
					<p>
						Are you sure you want to delete this property?
					</p>
					<p>
						<strong>This action cannot be undone.</strong>
					</p>
				</ConfirmationModal>
			</>
		);
	}

	return (
		<Page className="property">
			<Button variant="outlined" onClick={() => navigate("/" + from)}>
				<BackChevronVector />
				Back
			</Button>
			{children}
		</Page>
	);
}
