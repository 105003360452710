import Button from "../UI/Button/Button";
import Modal from "./Modal";

import "./BestViewedOnDesktopModal.scss";

interface BestViewedOnDesktopModalProps {
	onClose: Function;
}

export default function BestViewedOnDesktopModal({ onClose }: BestViewedOnDesktopModalProps) {
	return (
		<Modal title="Welcome" className="best-viewed-on-desktop">
			<p>This application works best on desktop screens at 720p (1280 x 768) or larger.</p>
			<p>For a better user experience, we recommend switching to a desktop device.</p>
			<Button variant="contained" onClick={onClose}>
				Close
			</Button>
		</Modal>
	);
}
