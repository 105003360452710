import Input from "../../Input/Input";

interface DefaultColumnFilterProps {
	column: {
		filterValue: string;
		setFilter: Function;
	}
}

export default function DefaultColumnFilter({ column: { filterValue, setFilter } }: DefaultColumnFilterProps) {
	return (
		<Input
			value={filterValue || ""}
			onChange={(event) => setFilter(event.target.value) || undefined} // NB: Set undefined to remove the filter entirely
		/>
	);
}
