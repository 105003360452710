import { ChangeEvent, useState } from "react";
import { Form } from "semantic-ui-react";
import Button from "../UI/Button/Button";
import Modal, { ModalProps } from "./Modal";
import { useRestClientContext } from "../../contexts/RestClientContext";
import Suspense from "../UI/Suspense/Suspense";

export default function CreateTeamModal({onClose}: ModalProps)
{
	const client = useRestClientContext();
	const [name, setName] = useState("");
	const [promise, setPromise] = useState<Promise<Response>>();

	const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
		setName(event.target.value);
	};

	const onSubmit = () => {
		
		const promise = client.createTeam(name);

		promise
			.then((response: Response) => response.json())
			.then((json: any) => {
				window.location.reload();
			});
		
		setPromise(promise);

	};

	return <Modal title="Create Team" onClose={onClose}>
		<Suspense promise={promise} isInitiallyLoading={false}>
			<Form onSubmit={onSubmit}>
				<Form.Field>
					<label>Name</label>
					<input 
						required 
						name="name" 
						value={name}
						onChange={onNameChange}
					/>
				</Form.Field>
				<Button variant="contained">
					Create
				</Button>
			</Form>
		</Suspense>
	</Modal>;
}