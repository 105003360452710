import React from "react";
import { Dropdown, DropdownProps, Label } from "semantic-ui-react";
import "./Select.scss";
import PropTypes from "prop-types";

export interface SelectProps extends DropdownProps
{
	label?: string;
	size?: string;
	placeholder?: string;
}

const Select = (props: SelectProps) => {
	const { label, size } = props;

	return (
		<>
			{label?.length && <Label>{label}</Label>}
			<Dropdown
				placeholder={props.placeholder ?? "All"}
				className={size ? `story-select--${size}  ` : "story-select--md "}
				search
				selection
				{...props}
			/>
		</>
	);
};

export default Select;

Select.propTypes = {
	/**
	 * How large should the button be?
	 **/
	size: PropTypes.oneOf(["sm", "md", "lg", "full"]),
};
