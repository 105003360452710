import React from "react";

export const HardDriveVector = () => {
	return (
		<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M20.1663 11H1.83301"
				stroke="#9395A2"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4.99551 4.684L1.83301 10.9998V16.4998C1.83301 16.9861 2.02616 17.4524 2.36998 17.7962C2.7138 18.14 3.18011 18.3332 3.66634 18.3332H18.333C18.8192 18.3332 19.2856 18.14 19.6294 17.7962C19.9732 17.4524 20.1663 16.9861 20.1663 16.4998V10.9998L17.0038 4.684C16.8521 4.37856 16.6181 4.12151 16.3282 3.94176C16.0383 3.76201 15.7041 3.66668 15.363 3.6665H6.63634C6.29526 3.66668 5.961 3.76201 5.67113 3.94176C5.38126 4.12151 5.14729 4.37856 4.99551 4.684V4.684Z"
				stroke="#9395A2"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M5.5 14.6665H5.51" stroke="#9395A2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			<path
				d="M9.16699 14.6665H9.17699"
				stroke="#9395A2"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
