import Property from "../../rest/Property";

export default function addExternalWallInsulationImprovements(data: any, property: Property) {
	if (
		!(
			/^solid brick \(as built\)$/i.test(property.data.wall_type) ||
			/^system build \(as built\)$/i.test(property.data.wall_type) ||
			/^granite or whin/i.test(property.data.wall_type) ||
			/^granite or whin/i.test(property.data.wall_type) ||
			/^stone: granite or whin \(as built\)$/i.test(property.data.wall_type)
		)
	)
		return;

	const sap = property.sapData;

	let externalWallInsulationImprovements = [
		{
			name: "50mm External Wall Insulation",
			sapVal: sap.improvedewi50sapvalue,
			costSaving: sap.improvedewi50costsaving,
			co2Val: sap.improvedewi50co2value,
			co2Saving: sap.improvedewi50co2saving,
			heatDemand: sap.improved_ewi50_heat_demand,
		},
		{
			name: "100mm External Wall Insulation",
			sapVal: sap.improvedewi100sapvalue,
			costSaving: sap.improvedewi100costsaving,
			co2Val: sap.improvedewi100co2value,
			co2Saving: sap.improvedewi100co2saving,
			heatDemand: sap.improved_ewi100_heat_demand,
		},
		{
			name: "150mm External Wall Insulation",
			sapVal: sap.improvedewi150sapvalue,
			costSaving: sap.improvedewi150costsaving,
			co2Val: sap.improvedewi150co2value,
			co2Saving: sap.improvedewi150co2saving,
			heatDemand: sap.improved_ewi150_heat_demand,
		},
		{
			name: "200mm External Wall Insulation",
			sapVal: sap.improvedewi200sapvalue,
			costSaving: sap.improvedewi200costsaving,
			co2Val: sap.improvedewi200co2value,
			co2Saving: sap.improvedewi200co2saving,
			heatDemand: sap.improved_ewi200_heat_demand,
		},
	];

	externalWallInsulationImprovements.sort((a, b) => {
		return a.sapVal > b.sapVal ? -1 : 1;
	});

	externalWallInsulationImprovements.forEach((improvement) => data.push(improvement));
}
