import React from "react";
import Button from "../Button/Button";
import Card from "../Card/Card";

import Role from "../../../rest/Role";
import { Link } from "react-router-dom";

import Team from "../../../rest/Team";

import "./TeamCard.scss";
interface TeamCardProps
{
	team: Team;
}

export default function TeamCard({ team }: TeamCardProps) {
	return (
		<Card title={team.name} className="team">
			{team.role === Role.ADMIN || team.role === Role.MANAGER ? (
				<Link to={`/teams/${team.id}`}>
					<Button variant="contained">Manage</Button>
				</Link>
			) : (
				<p>You are a member of this team.</p>
			)}
		</Card>
	);
}
