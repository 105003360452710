import React from "react";

const ExportVector = () => {
	return (
		<svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M3.66663 10V16.6667C3.66663 17.1087 3.85978 17.5326 4.2036 17.8452C4.54741 18.1577 5.01373 18.3333 5.49996 18.3333H16.5C16.9862 18.3333 17.4525 18.1577 17.7963 17.8452C18.1401 17.5326 18.3333 17.1087 18.3333 16.6667V10"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.6667 5.00008L11 1.66675L7.33337 5.00008"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M11 1.66675V12.5001" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};

export default ExportVector;
