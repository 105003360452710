import { Serie } from "@nivo/line";

interface PerformanceStatChartLabelsProps {
	bars: Serie[];
}

const PerformanceStatChartLabels = ({ bars }: PerformanceStatChartLabelsProps) => {
	const labelMargin = 10;
	return bars.map(
		(
			{ 
				data: 
				{ 
					// TODO: Review please.. probably used the wrong type
					// @ts-ignore
					formattedValue 
				}, 
				x, 
				width, 
				y, 
				key 
			}, 
			i
		) => {
			return (
				<text
					key={key}
					x={x + width / 2}
					y={y + labelMargin}
					textAnchor="bottom"
					alignmentBaseline="central"
					style={{
						fill: i ? "rgb(51, 51, 51)" : "rgb(251,251,251)",
						writingMode: "vertical-rl",
						//makes it so that the tooltip comes up when hovering over text
						cursor: "default",
						pointerEvents: "none",
					}}
				>
					{formattedValue}
				</text>
			);
		}
	);
};

export default PerformanceStatChartLabels;
