import React from "react";
import { Link } from "react-router-dom";

import { KeyValuePair } from "../../../types/types";

import EpcPieChart from "./EpcPieChart/EpcPieChart";
import Button from "../../UI/Button/Button";

interface EpcDataSummaryProps {
	data: KeyValuePair;
}

const EpcDataSummary = ({ data }: EpcDataSummaryProps) => {
	return (
		<div>
			<div style={style.flexChart}>
				<EpcPieChart data={data} />
			</div>
			<div style={style.flexButton}>
				<Link to="/epc-data">
					<Button variant="contained">View Data</Button>
				</Link>
				<Link to="/mapping" target="_BLANK">
					<Button variant="outlined">View Mapping</Button>
				</Link>
			</div>
		</div>
	);
};

export default EpcDataSummary;

// TODO: Please no... move this to SCSS
const style = {
	flexChart: {
		display: "flex",

		alignItems: "flex-end",
		justifyContent: "center",
	},
	flexButton: {
		display: "flex",
		width: 400,
		gap: 10,
		alignItems: "flex-end",
		justifyContent: "center",
		marginTop: 30,
	},
};
