import React from "react";

const RectangleVector = () => {
	return (
		<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="7" cy="7" r="5.5" fill="#FF5F43" stroke="#F5F6FA" strokeWidth="3" />
		</svg>
	);
};

export default RectangleVector;
