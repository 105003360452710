import React, { Suspense, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Header, Tab } from "semantic-ui-react";

import { useRestClientContext } from "../contexts/RestClientContext";

import UserManagementTable from "../components/UI/UserManagementTable/UserManagementTable";
import JobBatchesTable from "../components/UI/JobBatchesTable/JobBatchesTable";
import Button from "../components/UI/Button/Button";
import CustomImprovementSetsModal from "../components/CustomImprovements/CustomImprovementSetsModal";
import BulkCustomImprovementSetsProgressModal from "../components/CustomImprovements/CustomImprovementSetsProgressModal";
import { useUserContext } from "../contexts/UserContext";

export default function Team() {
	const params = useParams();
	const client = useRestClientContext();
	const user = useUserContext();
	
	const [promise, setPromise] = useState();
	const [team, setTeam] = useState();
	const [customImprovementSetsModalOpen, setCustomImprovementSetsModalOpen] = useState(false);
	const [currentBulkCustomImprovementSetsBatches, setCurrentBulkCustomImprovementSetsBatches] = useState(null);

	useEffect(() => {
		
		const promise = client
			.fetchTeam(params.team_id)
			.then((response) => response.json())
			.then((json) => setTeam(json));

		setPromise(promise);

	}, [client, params.team_id]);

	// TODO: Repeated from DataTable, abstract this somehow please
	const onBulkCustomImprovementSetsStarted = (batches) => {
		setCurrentBulkCustomImprovementSetsBatches(batches);
	};

	let panes = [];

	if(team)
	{
		if(user.is_admin)
			panes.push({
				menuItem: "Custom Measures",
				render: () => <Tab.Pane>
					<Button variant="contained" onClick={() => setCustomImprovementSetsModalOpen(true)}>Update all properties</Button>
				</Tab.Pane>
			});
		
		panes.push(
			{
				menuItem: "Job Batches",
				render: () => <Tab.Pane>
					<JobBatchesTable teamId={params.team_id} />
				</Tab.Pane>
			},
			{
				menuItem: "User Management",
				render: () => <Tab.Pane>
					<UserManagementTable team={team} />
				</Tab.Pane>
			}
		);
	}

	return <Suspense promise={promise}>
		<Header as="h1">{team?.name}</Header>
		<Tab panes={panes} />
		{
			customImprovementSetsModalOpen && 
			<CustomImprovementSetsModal 
				teamId={params.team_id}
				onClose={() => setCustomImprovementSetsModalOpen(false)}
				onComplete={batches => onBulkCustomImprovementSetsStarted(batches)}
			/>
		}
		{
			currentBulkCustomImprovementSetsBatches &&
			<BulkCustomImprovementSetsProgressModal
				batches={currentBulkCustomImprovementSetsBatches}
				onClose={() => setCurrentBulkCustomImprovementSetsBatches(null)}
			/>
		}
	</Suspense>;
}
