import { useState } from "react";

import BestViewedOnDesktopModal from "../components/Modal/BestViewedOnDesktopModal";
import "./Page.scss";

export default function Page({ children, className }) {
	const [bestViewedOnDesktopModalOpen, setBestViewedOnDesktopModalOpen] = useState(window.innerWidth < 1280);

	return (
		<div id="page" className={className}>
			{children}
			{bestViewedOnDesktopModalOpen && (
				<BestViewedOnDesktopModal onClose={() => setBestViewedOnDesktopModalOpen(false)} />
			)}
		</div>
	);
}
