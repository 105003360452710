interface FieldProps {
	label: string;
	value: string | number;
}

export default function Field({ label, value }: FieldProps) {
	return (
		<div className="field">
			<div className="label">{label}</div>
			<div className="value">{value}</div>
		</div>
	);
}
