import React from "react";
import { useDropzone, FileWithPath } from "react-dropzone";

import "./CsvFileInput.scss";

interface CsvFileInputProps {
	onChange?: Function;
}

export default function CsvFileInput(props: CsvFileInputProps) {
	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		accept: {
			"text/csv": [".csv"],
		},
		maxFiles: 1,
		onDrop: (acceptedFiles) => {
			props.onChange?.(acceptedFiles);
		},
	});

	const files = acceptedFiles.map((file: FileWithPath) => (
		<li key={file.path}>
			{file.path} - {file.size} bytes
		</li>
	));

	return (
		<section className="container csv-file-upload">
			<div {...getRootProps({ className: "dropzone" })}>
				<input {...getInputProps()} />
				<p>Drag 'n' drop a file here, or click to select a file</p>
			</div>
			<aside>
				<h4>File</h4>
				<ul>{files}</ul>
			</aside>
		</section>
	);
}
