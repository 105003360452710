import React from "react";

import "./RedactedEmail.scss";

interface RedactedEmailProps {
	address: string;
}

export default function RedactedEmail({ address }: RedactedEmailProps) {
	if (typeof address !== "string") throw new Error("Expected string");

	const parts = address.split("@");

	return (
		<span>
			{parts[0]}@<span className="redacted-email-domain">{parts[1]}</span>
		</span>
	);
}
