import Property from "../../rest/Property";

interface Improvement {
	name?: string;
	tooltip: string;
}

export default function applyDynamicWallTypeFields(improvement: Improvement, property: Property) {
	// TODO: Really would like this moved to the server ideally, all property logic should be there. For now this will do

	improvement.name = /^Cavity \(as built\)$/i.test(property.data.wall_type)
		? "Filled Cavity"
		: "100mm External Wall Insulation";

	if (
		/^Stone: granite or whin \(as built\)$/.test(property.data.wall_type) ||
		/^Stone : sandstone or limestone \(as built\)$/.test(property.data.wall_type) ||
		/^Solid brick \(as built\)$/.test(property.data.wall_type) ||
		/^Cob \(as built\)$/.test(property.data.wall_type) ||
		/^System build \(as built\)$/.test(property.data.wall_type)
	)
		improvement.tooltip = improvement.tooltip.replace("[as per intelligent lookup]", "(addition of 100 EWI/IWI)");
	else if (/^Cavity \(as built\)$/.test(property.data.wall_type))
		improvement.tooltip = improvement.tooltip.replace("[as per intelligent lookup]", "(Filled Cavity)");
	else if (/^Timber frame \(as built\)$/.test(property.data.wall_type))
		improvement.tooltip = improvement.tooltip.replace(
			"[as per intelligent lookup]",
			"(addition of internal insulation)"
		);
	else improvement.tooltip = improvement.tooltip.replace("[as per intelligent lookup]", "(No measure)");
}
