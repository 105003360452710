import React from "react";

const LoginVector = () => {
	return (
		<svg width="24" height="24" stroke="#9395A2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g>
				<path
					d="M21 13L21 17.6667C21 18.2855 20.7893 18.879 20.4142 19.3166C20.0391 19.7542 19.5304 20 19 20L5 20C4.46957 20 3.96086 19.7542 3.58579 19.3166C3.21071 18.879 3 18.2855 3 17.6667L3 13"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path d="M13 13L18 8L13 3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M18 8H6" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			</g>
		</svg>
	);
};

export default LoginVector;
