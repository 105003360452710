import { useState, useMemo } from "react";
import { Form, Grid, TextArea } from "semantic-ui-react";

import { Group } from "../../types/types";
import { PropertyResponse } from "../../rest/Property";

import Modal from "./Modal";
import EpcRatingTile from "../UI/EpcRatingTile/EpcRatingTile";
import MiniTable from "../UI/MiniTable/MiniTable";
import Input from "../UI/Input/Input";
import Button from "../UI/Button/Button";
import Preloader from "../UI/Preloader/Preloader";
import GroupSelect from "../UI/GroupSelect/GroupSelect";

import { useRestClientContext } from "../../contexts/RestClientContext";
import { useTeamContext } from "../../contexts/TeamContext";

import Property from "../../rest/Property";

import "./GroupPropertyModal.scss";
import { PropertyQuery } from "../../rest/Client";
import useTableProperties from "../../hooks/useTableProperties";

function sum(values: number[]) {
	let result = 0;
	values.forEach((val) => {
		result += val;
	});
	return result;
}

function avg(values: number[]) {
	return sum(values) / values.length;
}

export enum GroupPropertyModalMode {
	ADD = "add",
	CREATE = "create",
	REMOVE = "remove",
};

interface GroupPropertyModalProps {
	selectedProperties: PropertyResponse[];
	filters?: PropertyQuery;
	mode: GroupPropertyModalMode;
	onClose: Function;
	group?: Group | number;
};

export default function GroupPropertyModal(props: GroupPropertyModalProps) {
	const client = useRestClientContext();
	const team = useTeamContext();

	let title, submit;

	// NB: Memoized so the reference doesn't change, stops constant re-rendering
	const query = useMemo<PropertyQuery>(() => {

		return {
			filters: JSON.stringify(props.filters),
			fields: [
				"id",
				"uprn",
				"addr_no",
				"addr_street",
				"addr_city",
				"postcode",
				"epc_rating",
				"epc_rating_grade",
				"main_fuel_co2"
			]
		}

	}, [props.filters]);

	let { properties, loading: loadingProperies } = useTableProperties(props.selectedProperties, query);

	// let { properties } = props;
	const { mode, selectedProperties, ...passthrough } = props;

	const groupId = typeof props.group === "number" ? props.group : props.group?.id;
	const groupTitle = typeof props.group === "object" ? props.group?.name : null;

	const [name, setName] = useState("");
	const [description, setDescription] = useState<string | number | undefined>("");
	const [loading, setLoading] = useState(false);
	const [existingGroupId, setExistingGroupId] = useState<number | undefined>(groupId);
	const [existingGroupProperties, setExistingGroupProperties] = useState<Property[]>();

	switch (mode) {
		case "create":
			title = submit = "Create Group";
			break;

		case "add":
			title = submit = "Add to Group";
			break;

		case "remove":
			title = submit = groupTitle ? `Remove from ${groupTitle}`: "Remove from Group";
			break;

		default:
			throw new Error("Invalid mode");
	}

	const onGroupChanged = (group: Group[]) => {
		// NB: Appears to be a multi-select now, workaround this by addressing only the first element
		const id = group[0].id;

		setLoading(true);

		client
			.fetchGroupProperties(team.id, id)
			.then((response: Response) => response.json())
			.then((json: PropertyResponse[]) => {
				const properties = json.map((data: PropertyResponse) => new Property(data));

				setExistingGroupId(id);
				if (mode === GroupPropertyModalMode.ADD)
					setExistingGroupProperties(properties);
				setLoading(false);
			});
	};

	const onSubmit = () => {
		let body: {
			name?: string;
			description?: string | number;
			properties: number[];
		} = {
			properties: properties!.map((property) => property.id), // NB: Reference the original properties here for efficiency. No point transmitting the existing ones too. Could be cleaner, but should work.
		};

		let promise;

		if (mode === GroupPropertyModalMode.CREATE)
		{
			promise = client.createGroup(
				team.id,
				{
					...body,
					name,
					description
				}
			);
		} else if (mode === GroupPropertyModalMode.ADD)
		{
			promise = client.updateGroupProperties(team.id, existingGroupId!, body);
		} else if (mode === GroupPropertyModalMode.REMOVE) {
			promise = client.deleteGroupProperties(team.id, existingGroupId!, body);
		}
		else
			throw new Error("Unknown state");

		setLoading(true);

		promise
			.then(() => {
				setLoading(false);
				props.onClose();
			});
	};

	if(loadingProperies)
		return <Modal title={title} className="group-property-modal">
				<Preloader />
			</Modal>;

	if (existingGroupProperties) {
		const existingIdMap: Record<number, boolean> = {};
		const unique = [];

		properties!.forEach((property) => {
			existingIdMap[property.id] = true;
		});

		for (const property of existingGroupProperties) {
			if (existingIdMap[property.id]) continue; // NB: Ignore duplicates, this would skew averages

			unique.push(property);
		}

		// @ts-ignore
		properties = [...properties, ...unique];
	}

	const averageSapValue = avg(properties!.map((obj) => obj.epc_rating));

	return (
		// @ts-ignore
		<Modal title={title} className="group-property-modal" {...passthrough}>
			<ul className="property-stats one">
				<li>Total Number of Selected Properties: {properties!.length.toLocaleString()}</li>
				<li>Average Co2 Value: {avg(properties!.map((obj) => obj.main_fuel_co2)).toFixed(3)}</li>
			</ul>
			<ul className="property-stats">
				<li className="sap-value">
					Average SAP Value: <EpcRatingTile rating={averageSapValue} /> ({Math.round(averageSapValue)})
				</li>
				<li>Total Co2 Value: {sum(properties!.map((obj) => obj.main_fuel_co2)).toFixed(3)}</li>
			</ul>
			<Form>
				<Grid>
					<Grid.Row>
						<Grid.Column className="fields-container">
							{mode === GroupPropertyModalMode.CREATE ? (
								<>
									<Form.Field>
										<label>Group Name</label>
										<Input value={name} onChange={(event) => setName(event.target.value)} />
									</Form.Field>
									<Form.Field>
										<label>Group Description</label>
										<TextArea
											value={description}
											onChange={(event, { value }) => setDescription(value)}
										/>
									</Form.Field>
								</>
							) : mode === GroupPropertyModalMode.ADD ? (
								<Form.Field>
									<label>Group</label>
									<GroupSelect onChange={onGroupChanged} />
								</Form.Field>
							) : (
								props.group && <b>Group: {groupTitle || `ID ${groupId}`}</b>
							)}
						</Grid.Column>
						<Grid.Column className="mini-table-container">
							<MiniTable properties={properties!} />
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className="button-container">
						<Button variant="outlined" disabled={loading} onClick={props.onClose}>
							Cancel
						</Button>
						<Button
							variant="contained"
							disabled={
								(mode === "create" && !name.length) ||
								(mode === "add" && !existingGroupProperties) ||
								loading
							}
							onClick={onSubmit}
						>
							{submit}
						</Button>
					</Grid.Row>
				</Grid>
			</Form>
			{loading && <Preloader cover />}
		</Modal>
	);
}
