import React from "react";
import { Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";

import { KeyValuePair } from "../../../types/types";

import EpcRatingTile from "../../UI/EpcRatingTile/EpcRatingTile";
import { EpcGrade, epcGrades } from "./EpcData";

interface EpcDataBreakdownProps
{
	data: KeyValuePair;
}

const rangesByGrade: Record<EpcGrade, string> = {
	A: "(92-100+)",
	B: "(81-91)",
	C: "(69-80)",
	D: "(55-68)",
	E: "(39-54)",
	F: "(21-38)",
	G: "(0-20)",
	Ungraded: "",
};

const EpcDataBreakdown = ({ data }: EpcDataBreakdownProps) => {
	const percentagesByGrade: {[key: string]: number} = {};

	epcGrades.forEach((grade) => {
		let dataVal;
		if (grade !== "Ungraded") {
			dataVal = Number(data["total_grade_" + grade.toLowerCase()]);
		} else if (data["ungraded"] != null) {
			dataVal = Number(data["ungraded"]);
		} else {
			return;
		}
		percentagesByGrade[grade] = Math.round(100 * (dataVal / Number(data.total_properties)));
	});

	return (
		<Grid divided="vertically">
			{epcGrades.map((grade) => {
				const state = {
					filters: [
						{
							id: "epc_rating_grade",
							value: grade,
						},
					],
				};
				const colName = grade === "Ungraded" ? "ungraded" : "total_grade_" + grade.toLowerCase();

				if (grade === "Ungraded" && !data[colName]) {
					// auto hide when no ungraded properties
					return <></>;
				}

				return (
					<Grid.Row key={grade} style={{ paddingTop: 0, paddingBottom: 0 }}>
						<Grid.Column width={2}>
							<Link to="/epc-data" state={state}>
								<EpcRatingTile grade={grade} compact />
							</Link>
						</Grid.Column>
						<Grid.Column width={5}>
							<Link to="/epc-data" state={state}>
								{`${grade} ${rangesByGrade[grade]}`}
							</Link>
						</Grid.Column>
						<Grid.Column width={5}>
							<Link to="/epc-data" state={state}>
								<h4>{data[colName]}</h4>
							</Link>
						</Grid.Column>
						<Grid.Column width={4}>
							<Link to="/epc-data" state={state}>
								{percentagesByGrade[grade]}%
							</Link>
						</Grid.Column>
					</Grid.Row>
				);
			})}
		</Grid>
	);
};

export default EpcDataBreakdown;
