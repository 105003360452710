import { Link, useMatch, useResolvedPath } from "react-router-dom";
import Button from "./UI/Button/Button";

interface MainNavigationMenuItemProps
{
	to: string;
	icon: JSX.Element;
	text: string;
	collapsed: boolean;
}

export default function MainNavigationMenuItem(props: MainNavigationMenuItemProps) {
	const { to, icon, text, collapsed } = props;
	const resolved = useResolvedPath(to);
	const match = useMatch({ path: resolved.pathname, end: true });

	return (
		<Link to={to} className={match ? "active" : ""}>
			<Button variant={match ? "contained" : "text"}>
				{icon}
				{!collapsed && text}
			</Button>
		</Link>
	);
}
