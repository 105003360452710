import { ResponsiveLine } from "@nivo/line";
import { sizeFormatter } from "human-readable";

import { LineSvgProps } from "@nivo/line";

interface BudgetRequiredChartProps extends LineSvgProps {}

export default function BudgetRequiredChart(props: BudgetRequiredChartProps) {
	const formatter = sizeFormatter({
		keepTrailingZeroes: false,
		render: (literal, symbol) => `£${literal}${symbol}`,
	});

	return (
		<div className="net-zero-journey-chart">
			<ResponsiveLine
				{...props}
				colors={["#ca3a31"]}
				axisLeft={null}
				axisRight={{
					legend: "£",
					legendPosition: "middle",
					legendOffset: 60,
					format: (value) => formatter(value),
				}}
				enableGridY={false}
				layers={["grid", "axes", "lines", "markers", "legends"]}
			/>
		</div>
	);
}
