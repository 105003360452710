import { useState } from "react";
import { Message } from "semantic-ui-react";

import Card from "../Card/Card";
import MiniTable from "../MiniTable/MiniTable";
import GroupButtons from "../GroupButtons/GroupButtons";
import { PropertyResponse } from "../../../rest/Property";
import Preloader from "../Preloader/Preloader";

import "./SelectedPropertiesOverlay.scss";

interface SelectedPropertiesOverlayProps {
	properties: PropertyResponse[];
	onClose: Function;
	isLoading: boolean;
}

export default function SelectedPropertiesOverlay({ properties, onClose, isLoading }: SelectedPropertiesOverlayProps) {
	const [isMinimised, setIsMinimised] = useState(false);

	const handleMinimise = () => {
		setIsMinimised(!isMinimised);
	};

	return (
		<Card
			title="Selected Properties"
			className="selected-properties-overlay"
			onClose={onClose}
			handleMinimise={handleMinimise}
			isMinimised={isMinimised}
		>
			{!isMinimised &&
				(isLoading ? (
					<Preloader />
				) : properties.length > 0 ? (
					<>
						<div className="table-container">
							<MiniTable properties={properties} />
						</div>
						<GroupButtons selectedProperties={properties} />
					</>
				) : (
					<Message warning>
						<Message.Header>No properties in selection</Message.Header>
						<Message.Content>
							Include some properties in the polygon for them to appear here.
						</Message.Content>
					</Message>
				))}
		</Card>
	);
}
