import React from "react";
import CurrentTeamSelect from "../../UI/CurrentTeamSelect/CurrentTeamSelect";
import PropertiesLoaded from "../../UI/PropertiesLoaded/PropertiesLoaded";
import HeaderButtons from "../HeaderButtons/HeaderButtons";
import "./Header.scss";

const MainHeader = () => {
	return (
		<div className="header-component">
			<PropertiesLoaded />
			<CurrentTeamSelect />
			<HeaderButtons />
		</div>
	);
};

export default MainHeader;
