import { useState } from "react";

import { useRestClientContext } from "../../contexts/RestClientContext";

import Button from "../UI/Button/Button";
import Suspense from "../UI/Suspense/Suspense";
import Modal from "./Modal";
import Right from "../UI/Right/Right";

interface CancelBatchConfirmationModalProps {
	teamId: number;
	batchId: number;
	onCancelled: Function;
	onClose: Function;
}

export default function CancelBatchConfirmationModal({teamId, batchId, onCancelled, onClose}: CancelBatchConfirmationModalProps)
{
	const client = useRestClientContext();
	const [promise, setPromise] = useState<Promise<Response>>();

	const onCancel = () => {

		setPromise(
			client
				.deleteBulkCustomImprovementBatch(teamId, batchId)
				.then(() => onClose())
		);

	};

	return <Modal 
		title="Confirm cancellation"
		onClose={() => onClose()}
	>
		<Suspense 
			promise={promise}
			isInitiallyLoading={false}
		>
			<p>
				Are you sure you want to cancel this batch?
			</p>
			<Right>
				<Button 
					variant="contained"
					onClick={() => onCancel()}
				>
					Yes
				</Button>
				<Button 
					variant="outlined"
					onClick={() => onClose()}
				>
					No
				</Button>
			</Right>
		</Suspense>
	</Modal>
}