import { useEffect, FormEvent, useState, useMemo, MouseEvent } from "react";
import { Icon, CheckboxProps, Form, ModalProps, Radio, Accordion, AccordionTitleProps } from "semantic-ui-react";

import Modal from "./Modal";
import { useTeamContext } from "../../contexts/TeamContext";

import "./ExportFromTableModal.scss";
// import usePropertyColumns from "../../hooks/usePropertyColumns";
import PropertyColumnCheckboxes from "../UI/PropertyColumnCheckboxes/PropertyColumnCheckboxes";
import useDefaultPropertyColumnSelection from "../../hooks/useDefaultPropertyColumnSelection";
// import Button from "../UI/Button/Button";
import Right from "../UI/Right/Right";
import queryString from "query-string";
import ExportButton from "../UI/ExportButton/ExportButton";

interface ExportFromTableModalProps extends ModalProps
{
	selectedPropertyIds: number[];
	groupId?: number;
}

enum ExportProperties {
	ALL = "all",
	SELECTION = "selection"
};

export default function ExportFromTableModal({selectedPropertyIds, groupId, ...props}: ExportFromTableModalProps)
{
	const team = useTeamContext();

	const [activeAccordionIndex, setActiveAccordionIndex] = useState(0);
	const [properties, setProperties] = useState<ExportProperties>(selectedPropertyIds.length > 0 ? ExportProperties.SELECTION : ExportProperties.ALL);
	const defaultColumnSelection = useDefaultPropertyColumnSelection();
	const [columns, setColumns] = useState<Record<string, boolean>>(defaultColumnSelection);

	const exportProps = useMemo(() => {

		const queryStringParameters: Record<string, string> = {};

		if(properties === ExportProperties.SELECTION)
			queryStringParameters.properties = JSON.stringify(selectedPropertyIds);
		
		queryStringParameters.columns = JSON.stringify(
			Object
				.entries(columns)
				.filter(entry => entry[1] === true)
				.map(entry => entry[0])
		);

		return {
			resource: `/api/teams/${team.id}/properties/export?` + queryString.stringify(queryStringParameters),
			mimeType: "text/csv"
		};

	}, [team.id, selectedPropertyIds, properties, columns]);

	useEffect(() => {

		if(properties === ExportProperties.SELECTION && selectedPropertyIds.length === 0)
			setProperties(ExportProperties.ALL);

	}, [properties, selectedPropertyIds]);

	const onPropertiesChanged = (e: FormEvent<HTMLInputElement>, { value }: CheckboxProps) => setProperties(value as ExportProperties);

	const onAccordionNavigate = (event: MouseEvent<HTMLDivElement, MouseEvent>, data: AccordionTitleProps) => {
		const { index } = data;
		const newIndex = activeAccordionIndex === index ? -1 : index;

		setActiveAccordionIndex(newIndex as number);
	};

	return <Modal
		title="Export"
		className="export-from-table"
		{...props}
	>
		<Form>
			<Accordion>
				<Accordion.Title
					active={activeAccordionIndex === 0}
					index={0}
					// NB: There's something weird going on with the way AccordionTitleProps are imported
					// @ts-ignore
					onClick={onAccordionNavigate}
				>
					<Icon name="dropdown" />
					Properties
				</Accordion.Title>
				<Accordion.Content
					active={activeAccordionIndex === 0}
				>
					<Form.Field>
						<Radio 
							name="properties"
							label="Export selection" 
							value={ExportProperties.SELECTION}
							checked={properties === ExportProperties.SELECTION}
							disabled={selectedPropertyIds.length === 0}
							onChange={onPropertiesChanged}
						/>
					</Form.Field>
					<Form.Field>
						<Radio
							name="properties"
							label="Export all"
							value={ExportProperties.ALL}
							checked={properties === ExportProperties.ALL}
							onChange={onPropertiesChanged}
						/>
					</Form.Field>
				</Accordion.Content>

				<Accordion.Title
					active={activeAccordionIndex === 1}
					index={1}
					// NB: There's something weird going on with the way AccordionTitleProps are imported
					// @ts-ignore
					onClick={onAccordionNavigate}
				>
					<Icon name="dropdown" />
					Columns
				</Accordion.Title>
				<Accordion.Content
					active={activeAccordionIndex === 1}
				>
					<Form.Field className="fields-container">
						<PropertyColumnCheckboxes
							selectedColumns={columns}
							setSelectedColumns={setColumns}
						/>
					</Form.Field>
				</Accordion.Content>
			</Accordion>
			<Right>
				<ExportButton {...exportProps} />
			</Right>
		</Form>
	</Modal>
}