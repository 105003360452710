import { useState } from "react";
import { DateTime } from "luxon";

import Card from "../Card/Card";
import "./GroupCard.scss";
import FavouriteButtonVector from "../../../assets/FavouriteButtonVector";
import ExportButton from "../ExportButton/ExportButton";
import { useTeamContext } from "../../../contexts/TeamContext";
import { Link } from "react-router-dom";
import { useRestClientContext } from "../../../contexts/RestClientContext";
import Button from "../Button/Button";
import { Container, Icon } from "semantic-ui-react";
import Modal from "../../Modal/Modal";

import { Group } from "../../../types/types";
import FileVector from "../../../assets/FileVector";
import LoginVector from "../../../assets/LoginVector";

interface GroupCardProps {
	group: Group;
	onDeleted: Function;
}

export default function GroupCard({ group, onDeleted }: GroupCardProps) {
	const client = useRestClientContext();
	const team = useTeamContext();

	// TODO: This is a bit of an odd mechanism. Might be best to allow the group card to trigger the container to update this specific group. Not sure. Leave it if it's OK I guess.
	const [isFavourite, setIsFavourite] = useState(group.is_favourite);
	const [isFavouriteLoading, setIsFavouriteLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	const { id, name, description, inserted_at } = group;
	const datetime = DateTime.fromISO(inserted_at);

	const onToggleFavourite = () => {
		setIsFavouriteLoading(true);

		client
			.updateGroup(team.id, id, {
				is_favourite: !isFavourite
			})
			.then((response: Response) => response.json())
			.then((json: Group) => {
				setIsFavourite(json.is_favourite);
				setIsFavouriteLoading(false);
			});
	};

	const onDelete = (event: Event) => {
		setIsDeleteModalOpen(true);

		event.preventDefault();
		return false;
	};

	const onCancelDelete = () => {
		setIsDeleteModalOpen(false);
	};

	const onConfirmDelete = () => {
		setIsDeleteModalOpen(false);
		setIsLoading(true);

		client
			.deleteGroup(team.id, id)
			.then(() => {
				onDeleted();
			});
	};

	const classes = ["groups-card-container"];

	if (isLoading) classes.push("is-loading");

	return (
		<div className={classes.join(" ")}>
			{isDeleteModalOpen && (
				<Modal title="Confirm delete" className="confirm-delete-group">
					Are you sure you want to delete the group "{group.name}"
					<div className="delete-confirmation-buttons">
						<Button variant="contained" onClick={onConfirmDelete}>
							Yes
						</Button>
						<Button variant="outlined" onClick={onCancelDelete}>
							No
						</Button>
					</div>
				</Modal>
			)}
			<div className="favourite-button-vector" onClick={onToggleFavourite}>
				{/* TODO: Why are we using underscores in this prop name, fix please */}
				<FavouriteButtonVector filled={isFavourite} loading={isFavouriteLoading} />
			</div>
			<div>
				{/* <Link to={`/epc-datagroup=${group.id}`} onClick={event => onClick(event)}> */}
					<Card title={name}>
						<div className="groups-card-container-inside">
							<div className="group-description-container">
								<p>{description}</p>
							</div>

							<div className="groups-card-container-inside-button">
								<ExportButton
									resource={`/api/teams/${team.id}/groups/${group.id}/properties/export`}
									mimeType="text/csv"
								/>
							</div>
						</div>
						<Container className="view-table-button-container">
							<Link to={`/epc-data?group=${group.id}`}>
								<Button variant="contained">
									<FileVector />
									Data
								</Button>
							</Link>
							<Link to={`/data-integrity?groups=${group.id}`}>
								<Button variant="contained">
									<LoginVector />
									Data Quality
								</Button>
							</Link>
						</Container>
						<div className="card-bottom-row">
							<div className="groups-card-created-date">
								Created <strong>{datetime.toRelative()}</strong>
							</div>
							<Icon name="trash alternate outline" onClick={onDelete} />
						</div>
					</Card>
				{/* </Link> */}
			</div>
		</div>
	);
}
