import React from "react";
import { Grid } from "semantic-ui-react";
import ThermalVector from "../../../assets/ThermalVector";
import Card from "../../UI/Card/Card";
import Button from "../../UI/Button/Button";
import "./OrderThermalSurveys.scss";

const OrderThermalSurveys = () => {
	return (
		<div className="color-card-thermal">
			<Card title="Order Thermal Surveys" minHeight={450}>
				<Grid padded="vertically">
					<Grid.Row>
						<Grid.Column>
							<div className="thermal-square-opaque">
								<ThermalVector />
							</div>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column>
							Our thermal surveys provide valuable information on the true condition of your properties – detecting heat loss, leaks, damp and mould and any issues with EWI or insulation.
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column>
							<a 
								href="https://irtsurveys.co.uk/contact"
								target="_BLANK"
								rel="noreferrer"
							>
								<Button variant="contained" inverse>
									Request a quote
								</Button>
							</a>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Card>
		</div>
	);
};

export default OrderThermalSurveys;
