import usePropertyColumns from "./usePropertyColumns";

// TODO: I think we need a better strategy for serializing the application state, in case of collisions. See also https://github.com/yellow-sub-cy/dream-client-v2/commit/a63b4168bc237c6b73f31b3b0ed801e91cb5cdb2
const LOCAL_STORAGE_KEY = "defaeult-property-column-selection";

export function setDefaultPropertyColumnSelection(selection: Record<string, boolean>)
{
	localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(selection));
}

export default function useDefaultPropertyColumnSelection()
{
	const selectAll: Record<string, boolean> = {};
	const availableColumns	= usePropertyColumns();

	availableColumns.forEach(definition => selectAll[definition.accessor] = true);

	const storedJson		= localStorage.getItem(LOCAL_STORAGE_KEY);
	const storedSelection	= storedJson ? JSON.parse(storedJson) : selectAll;

	return storedSelection;
}