import { useState } from "react";
import { Table, Icon } from "semantic-ui-react";

import Property from "../../rest/Property";
import { CustomImprovementsSet } from "../../types/types";

import { useRestClientContext } from "../../contexts/RestClientContext";

import Button from "../UI/Button/Button";
import Preloader from "../UI/Preloader/Preloader";

interface CustomImprovementsSetTableRowProps {
	property: Property;
	set: CustomImprovementsSet;
	onDeleted: Function;
}

export default function CustomImprovementsSetTableRow({property, set, onDeleted}: CustomImprovementsSetTableRowProps)
{
	const client = useRestClientContext();
	const [deleting, setDeleting] = useState(false);

	const onDelete = () => {

		setDeleting(true);

		client
			.deleteCustomImprovementSet(property.teamId, property.id, set.id)
			.then(() => onDeleted(set));

	};

	return <Table.Row>
		<Table.Cell>
			{set.name}
		</Table.Cell>
		<Table.Cell>{set.result?.sap_value}</Table.Cell>
		<Table.Cell>{set.result?.co2_value}</Table.Cell>
		<Table.Cell>{set.result?.co2_saving}</Table.Cell>
		<Table.Cell>{set.result?.cost_saving}</Table.Cell>
		<Table.Cell>{set.result?.heat_demand}</Table.Cell>
		<Table.Cell>
			<Button 
				variant="text"
				size="mini"
				title="Delete"
				onClick={onDelete}
			>
				{deleting ? <Preloader variant="inline" /> : <Icon name="delete" />}
			</Button>
		</Table.Cell>
	</Table.Row>;
}