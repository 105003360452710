import { useState } from "react";

import Button from "../Button/Button";
import ExportVector from "../../../assets/ExportVector";

import { useRestClientContext } from "../../../contexts/RestClientContext";

interface ExportButtonProps
{
	resource: string;
	mimeType: string;
	disabled?: boolean;
}

export default function ExportButton({ resource, mimeType, disabled }: ExportButtonProps) {
	const client = useRestClientContext();
	const [loading, setLoading] = useState(false);

	const onExport = () => {
		setLoading(true);

		client.download(resource, mimeType).then(() => setLoading(false));
	};

	return (
		<Button variant="contained" disabled={loading || disabled} onClick={() => onExport()}>
			<div className="group-card-button">
				<ExportVector />
				Export
			</div>
		</Button>
	);
}
