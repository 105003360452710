import { useState, useEffect } from "react";
import { Container, List } from "semantic-ui-react";
import { ReactSortable } from "react-sortablejs";

import { useRestClientContext } from "../../contexts/RestClientContext"

import Property from "../../rest/Property";
import Suspense from "../UI/Suspense/Suspense";
import Right from "../UI/Right/Right";
import Button from "../UI/Button/Button";
import Item from "./Item";
import { CustomImprovement } from "../../types/types";

interface SuggestedPanelProps {
	property: Property;
	data: any;
	onComplete?: Function;
	onClose: Function;
}

interface Suggestion {
	improvement: CustomImprovement;
	reason: string;
	parameters: string[];
}

export default function SuggestedPanel({property, data, onComplete, onClose}: SuggestedPanelProps)
{
	const client = useRestClientContext();
	
	const [promise, setPromise] = useState<Promise<Response>>();
	const [suggestions, setSuggestions] = useState<Suggestion[]>([]);

	const onSubmit = () => {

		const mapped = suggestions.map(suggestion => {

			const improvement = suggestion.improvement;
			const result: {
				id: number;
				reason: string;
				params?: string[];
			} = {
				id: improvement.id,
				reason: suggestion.reason,
			};

			if(improvement.accepts_parameters)
				result.params = suggestion.parameters;
			
			return result;

		});

		const promise = client.createPropertyCustomImprovements(property, {
			...data,
			"custom-improvements": mapped
		});

		setPromise(promise);

		promise
			.then((response: Response) => response.json())
			.then((json: any) => {
				
				if(onComplete)
					onComplete(json);
				
				onClose();

			});

	};

	useEffect(() => {

		const promise = client.fetchSuggestedCustomImprovements(property.teamId, property.id);

		setPromise(promise);

		promise
			.then((response: Response) => response.json())
			.then((data: Suggestion[]) => setSuggestions(data));

	}, [client, property.teamId, property.id]);

	const onRemoveImprovement = (improvement: CustomImprovement) => {
		setSuggestions(suggestions.filter(suggestion => suggestion.improvement !== improvement));
    }

	return <Suspense promise={promise}>
		<List>
			<ReactSortable
				// @ts-ignore
				list={suggestions}
				// @ts-ignore
				setList={setSuggestions}
				className="sortable-list"
			>
				{
					suggestions
						.map((suggestion) => 
							<Item
								key={suggestion.improvement.id}
								improvement={suggestion.improvement}
								context="selected"
								reason={suggestion.reason}
								// @ts-ignore
								param={suggestion.parameters ? suggestion.parameters[0] : null}
								parameterInputDisabled={true}
								onRemove={onRemoveImprovement}
							/>
						)
				}
			</ReactSortable>
		</List>
		<Container>
			<Right>
				<Button variant="contained" onClick={onSubmit}>Submit</Button>
			</Right>
		</Container>
	</Suspense>
}