// TODO: Many any's here, please sort

function dateBetweenFilter(rows: any, id: number, filterValues: string[]) {
	let sd = new Date(filterValues[0]);
	let ed = new Date(filterValues[1]);
	return rows.filter((r: any) => {
		var time = new Date(r.values[id]);
		if (filterValues.length === 0) return rows;
		return time >= sd && time <= ed;
	});
}

dateBetweenFilter.autoRemove = (val: any) => !val;

export default dateBetweenFilter;
