import usePropertyColumns from "../../../hooks/usePropertyColumns";
import { setDefaultPropertyColumnSelection } from "../../../hooks/useDefaultPropertyColumnSelection";

import "./PropertyColumnCheckboxes.scss";

export interface PropertyColumnCheckboxesProps
{
	selectedColumns: Record<string, boolean>;
	setSelectedColumns: Function;
}

export default function PropertyColumnCheckboxes({selectedColumns, setSelectedColumns}: PropertyColumnCheckboxesProps)
{
	const columns = usePropertyColumns();

	const onToggle = (key: string) => {

		const state = {
			...selectedColumns,
			[key]: !selectedColumns[key]
		};
		
		setSelectedColumns(state);
		setDefaultPropertyColumnSelection(state);
		
	}

	return <>
		{columns.map(definition => {

			return <label 
				key={definition.accessor}
				className="property-column-checkbox"
			>
				{/* NB: I ended up using vanilla checkboxes here because Semantic checkboxes seem to get confused about their state. I think it might be something to do with them rendering in a modal possibly. Maybe try going back after updating to Semantic 3? */}
				<input
					type="checkbox"
					checked={selectedColumns[definition.accessor]}
					onChange={() => onToggle(definition.accessor)}
				/>
				<span>
					{definition.Header}
				</span>
			</label>;
			
		})}
	</>;
}