import { ResponsivePie } from "@nivo/pie";
import { linearGradientDef } from "@nivo/core";
import { NetZeroGoalDatum } from "./NetZeroGoalChart";

import "./PieChart.scss";

interface NetZeroGoalPieProps
{
	data: NetZeroGoalDatum[];
	percentage?: string | number;
}

export default function NetZeroGoalPie({ data, percentage }: NetZeroGoalPieProps) {
	return (
		<div className="pie-chart-container">
			<ResponsivePie
				data={data}
				// keys={["Current Position", "vue", "elm"]}
				startAngle={-90}
				endAngle={90}
				innerRadius={0.7}
				activeInnerRadiusOffset={29}
				activeOuterRadiusOffset={8}
				borderColor={{
					from: "color",
					modifiers: [["opacity", 1.0]],
				}}
				enableArcLinkLabels={false}
				arcLinkLabelsSkipAngle={25}
				arcLinkLabelsTextOffset={15}
				arcLinkLabelsTextColor="#333333"
				arcLinkLabelsOffset={-1}
				arcLinkLabelsDiagonalLength={14}
				arcLinkLabelsThickness={2}
				arcLinkLabelsColor={{ from: "color" }}
				enableArcLabels={false}
				arcLabelsRadiusOffset={0.7}
				arcLabelsSkipAngle={13}
				arcLabelsTextColor={{
					from: "color",
					modifiers: [["darker", 0.9]],
				}}
				isInteractive={false}
				defs={[
					// using helpers
					// will inherit colors from current element

					linearGradientDef("gradientB", [{ offset: 100, color: "rgba(255, 95, 67, 0.5)" }]),
					// using plain object
					{
						id: "gradientC",
						type: "linearGradient",
						colors: [
							{ offset: 10, color: "#78D59F" },
							{ offset: 70, color: "#FFEF56" },
						],
					},
					{
						id: "gradientA",
						type: "linearGradient",
						colors: [{ offset: 100, color: "rgba(168, 176, 192, 0.1)" }],
					},
				]}
				// 2. defining rules to apply those gradients
				fill={[
					{ match: (d) => d.id === "Set Target", id: "gradientB" },
					{ match: (d) => d.id === "Current Position", id: "gradientC" },
					{ match: (d) => d.id === "Target", id: "gradientA" },
				]}
				animate={false}
				legends={[]}
			/>
			<div className="pie-chart-overlay">
				<span>{percentage}%</span>
				<span className="pie-chart-total-label">To Net Zero Target</span>
			</div>
		</div>
	);
};
