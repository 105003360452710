/* mears-mcmview-override-contents

export default function useLocation()
{
	return null;
}

*/

import { useLocation as useReactRouterDomLocation } from "react-router-dom";

export default useReactRouterDomLocation;