import { Header } from "semantic-ui-react";

import Property from "../../rest/Property";

import Card from "../UI/Card/Card";
import OptimisationChart from "./OptimisationChart";

interface Co2EnergyOptimisationCardProps {
	property: Property;
}

export default function Co2EnergyOptimisationCard({ property }: Co2EnergyOptimisationCardProps) {
	const sap = property.data.sap_data;

	const co2Data = [
		{
			value: sap.current_co2_rating,
			label: "Current",
			id: "CCO2",
		},
		{
			value: sap.potential_co2_rating - sap.current_co2_rating,
			label: "Potential",
			id: "PCO2",
		},
	];
	const energyData = [
		{
			value: sap.current_energy_rating,
			label: "Current",
			id: "CENG",
		},
		{
			value: sap.potential_energy_rating - sap.current_energy_rating,
			label: "Potential",
			id: "PENG",
		},
	];

	return (
		<Card title="CO2 &amp; Energy Optimisation">
			<Header as="h3">CO2</Header>
			<div className="chart-container pie-chart">
				<OptimisationChart data={co2Data} />
			</div>
			<Header as="h3">Energy</Header>
			<div className="chart-container pie-chart">
				<OptimisationChart data={energyData} />
			</div>
		</Card>
	);
}
