import { SyntheticEvent } from "react";
import { DropdownProps } from "semantic-ui-react";
import Select from "../Select/Select";

import { usePropertyEditContext } from "../../../contexts/PropertyEditContext";

import { EditableFieldValue } from "../../../contexts/PropertyEditContext";

export interface PropertyFieldSelectProps {
	field: string;
	onChange: Function;
}

export default function PropertyFieldSelect(props: PropertyFieldSelectProps) {
	const editing = usePropertyEditContext();

	const options = editing[props.field].map((obj: EditableFieldValue) => {
		return {
			key: obj.value,
			text: obj.friendly,
			value: obj.value,
		};
	});

	// NB: Adapter to mimic event for consistency on bulk edit dialogs. Might be good to make this consistent everywhere.
	const onChange = (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
		props.onChange({
			target: {
				value: data.value,
			},
		});
	};

	return <Select options={options} {...props} onChange={onChange} />;
}
