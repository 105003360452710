import { FormEvent, useEffect, useState } from "react";
import {
	Form,
	FormField,
	Table,
	TableBody,
	TableCell,
	TableHeader,
	TableHeaderCell,
	TableRow,
} from "semantic-ui-react";
import { useRestClientContext } from "../../contexts/RestClientContext";
import Property from "../../rest/Property";

import { SmartTarget, SmartTargetFields, SmartTargetParams, SmartTargetStatus } from "../../types/types";
import SmartTargetModal from "../Modal/SmartTargetModal";
import Button from "../UI/Button/Button";
import Preloader from "../UI/Preloader/Preloader";

import "./SmartTargetsPanel.scss";

interface SmartTargetsPanelProps {
	property: Property;
}

export default function SmartTargetsPanel({ property }: SmartTargetsPanelProps) {
	const client = useRestClientContext();
	const [smartTargets, setSmartTargets] = useState<SmartTarget[]>([]);
	const [params, setParams] = useState<SmartTargetParams>({
		target: SmartTargetFields.SAP_VALUE,
		value: 90,
	});
	const [numSubmissions, setNumSubmissions] = useState(0);
	const [selectedSmartTarget, setSelectedSmartTarget] = useState<SmartTarget | null>(null);
	const [isSmartTargetModalOpen, setIsSmartTargetModalOpen] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [validationError, setValidationError] = useState<string | null>(null);

	/** Map of each field and their current values. Also, whether the target value should be above the current or not. **/
	const currentValues: Record<SmartTargetFields, { current: number; validateAbove: boolean }> = {
		co2_saving: { current: property.data.sap_data.current_co2_use, validateAbove: false },
		co2_value: { current: property.data.sap_data.current_co2_use, validateAbove: true },
		cost_saving: { current: property.data.sap_data.total_cost_current, validateAbove: false },
		energy_saving: { current: property.data.sap_data.current_energy_use, validateAbove: false },
		heat_demand: { current: property.data.sap_data.current_heat_demand, validateAbove: true },
		hlp: { current: property.data.sap_data.as_built_hlp, validateAbove: true },
		sap_value: { current: property.data.epc_rating, validateAbove: true },
	};

	useEffect(() => {
		setLoading(true);
		client
			.fetchAllPropertySmartTargets(property.teamId, property.id)
			.then((response: Response) => response.json())
			.then((json: any) => {
				setSmartTargets(json);
				setLoading(false);
			});
	}, [client, property, numSubmissions]);

	useEffect(() => {
		if (currentValues[params.target].validateAbove && params.value < currentValues[params.target].current) {
			setValidationError("Target is below the current value")
		} else {
			setValidationError(null)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params])

	const updateSmartTarget = (smartTarget: SmartTarget) => {
		client
			.fetchPropertySmartTargets(property.teamId, property.id, smartTarget.id)
			.then((response: Response) => response.json())
			.then((json: any) => {
				setSelectedSmartTarget(json);

				const newSmartTargets = smartTargets.map((iterSmartTarget) => {
					if (iterSmartTarget.id === smartTarget.id) {
						return json;
					}
					return iterSmartTarget;
				});

				setSmartTargets(newSmartTargets);
			});
	};

	const onParamChanged = (event: FormEvent<HTMLSelectElement | HTMLInputElement>) => {
		setParams({
			...params,
			[event.currentTarget!.name]: event.currentTarget!.value,
		});
	};

	const onViewSmartTarget = (smartTarget: SmartTarget) => {
		setSelectedSmartTarget(smartTarget);
		setIsSmartTargetModalOpen(true);
	};

	const cancelPendingSmartTarget = (smartTarget: SmartTarget) => {
		client.cancelPendingSmartTargets(property.teamId, property, smartTarget).then(() => {
			const newSmartTargets = smartTargets.map((targetItem: SmartTarget) =>
				targetItem.id === smartTarget.id ? { ...targetItem, status: SmartTargetStatus.CANCELLED } : targetItem
			);
			setSmartTargets(newSmartTargets);
		});
	};

	const onSubmit = () => {
		client.createSmartTargets(property.teamId, property, params).then(() => setNumSubmissions(numSubmissions + 1)); // NB: Trigger reload
	};

	return (
		<>
			{loading ? (
				<Preloader />
			) : (
				<Table celled>
					<TableHeader>
						<TableRow>
							<TableHeaderCell>ID</TableHeaderCell>
							<TableHeaderCell>Target</TableHeaderCell>
							<TableHeaderCell>Value</TableHeaderCell>
							<TableHeaderCell>Status</TableHeaderCell>
							<TableHeaderCell>Actions</TableHeaderCell>
						</TableRow>
					</TableHeader>
					<TableBody>
						{smartTargets.map((obj: SmartTarget) => (
							<TableRow>
								<TableCell>{obj.id}</TableCell>
								<TableCell>{obj.target}</TableCell>
								<TableCell>{obj.value}</TableCell>
								<TableCell>{obj.status}</TableCell>
								<TableCell>
									<Button variant="mini" onClick={() => onViewSmartTarget(obj)}>
										View
									</Button>
									{obj.status === "pending" && (
										<Button variant="mini" onClick={() => cancelPendingSmartTarget(obj)}>
											Cancel
										</Button>
									)}
									{/* <Button variant="mini">
								Delete
							</Button> */}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			)}
			<Form>
				<FormField>
					<label>Target field</label>
					<select name="target" value={params.target} onInput={(event) => onParamChanged(event)}>
						<option value="energy_saving">Energy saving</option>
						<option value="co2_saving">CO2 saving</option>
						<option value="cost_saving">Cost saving</option>
						<option value="sap_value">SAP value</option>
						<option value="co2_value">CO2 value</option>
						<option value="heat_demand">Heat demand</option>
						<option value="hlp">HLP</option>
					</select>
				</FormField>
				<FormField>
					<label>Target value</label>
					<input
						name="value"
						type="number"
						step="any"
						value={params.value}
						onInput={(event) => onParamChanged(event)}
					/>
					{currentValues[params.target].current != null && (
						<>
							<label className="current-value-field-label">Current value</label>
							<p className="current-value-field">
								<b>{currentValues[params.target].current}</b>
							</p>
						</>
					)}
				</FormField>
				{validationError && <p className="validation-error">{validationError}</p>}
				<Button variant="contained" onClick={() => onSubmit()}>
					Submit
				</Button>
			</Form>
			{isSmartTargetModalOpen && selectedSmartTarget && (
				<SmartTargetModal
					selectedSmartTarget={selectedSmartTarget}
					updateSmartTarget={updateSmartTarget}
					onClose={() => setIsSmartTargetModalOpen(false)}
				/>
			)}
		</>
	);
}
