import { useState, useEffect } from "react";

import { useRestClientContext } from "../contexts/RestClientContext";
import { useTeamContext } from "../contexts/TeamContext";
import Property from "../rest/Property";

export default function useBulkPropertyImageUpload({
	acceptedFiles,
	records,
	fieldMapping,
	imageFields,
	deleteExistingImages,
}) {
	const client = useRestClientContext();
	const team = useTeamContext(); // TODO: Support reading team ID from sheet

	const [processing, setProcessing] = useState(false);
	const [currentRecordIndex, setCurrentRecordIndex] = useState(0);
	const [status, setStatus] = useState("Idle");
	const [progress, setProgress] = useState(0);
	const [errors, setErrors] = useState([]);

	const beginProcessing = () => {
		setProcessing(true);
		setCurrentRecordIndex(0);
		setStatus("Starting import...");
		setProgress(0);
		setErrors([]);
	};

	const cancelProcessing = () => {
		setProcessing(false);
		setStatus("Cancelled");
	};

	useEffect(() => {
		if (!processing) return;

		const appendError = (message) => {
			setErrors((errors) => [...errors, message]);
		};

		const inverseMapping = Object.fromEntries(Object.entries(fieldMapping).map(([key, value]) => [value, key]));
		const record = records[currentRecordIndex];
		const uprn = record[inverseMapping["uprn"]];

		let property;

		const onPropertiesResponse = (json) => {
			if (!json.entries?.length) {
				appendError(`No properties found for UPRN ${uprn}`);
				setCurrentRecordIndex(currentRecordIndex + 1);
				return;
			}

			property = new Property(json.entries[0]);

			if (!deleteExistingImages) {
				onPreUploadComplete();
				return;
			}

			setStatus(`Deleting images for ${property.uprn}`);

			client
				.deletePropertyImages(property)
				.then(() => onPreUploadComplete())
				.catch((error) => {
					// TODO: Won't catch for some reason. Review please.
					appendError(error.message);
				});
		};

		const onPreUploadComplete = () => {
			const getFileFromFilename = (filename) => acceptedFiles.find((file) => file.name === filename);

			const promises = imageFields
				.filter((name) => name in record)
				.filter((name) => {
					const filename = record[name];

					if (!getFileFromFilename(filename)) {
						appendError(`File ${filename} not found in selected files`);
						return false;
					}

					return true;
				})
				.map((name) => {
					const formData = new FormData();
					formData.append("file", getFileFromFilename(record[name]));

					for (const source in fieldMapping) {
						const destination = fieldMapping[source];
						formData.append(destination, record[source]);
					}

					// TODO: Test when this fails
					return client.uploadPropertyImages(property, formData);
				});

			setStatus(`Uploading images for ${property.data.uprn}`);

			Promise.all(promises).then(() => {
				const nextRecordIndex = currentRecordIndex + 1;

				if (nextRecordIndex >= records.length) {
					setProgress(1);
					setProcessing(false);
					return;
				}

				setProgress(currentRecordIndex / records.length);
				setCurrentRecordIndex(nextRecordIndex);
			});
		};

		// TODO: Catch no uprn

		setStatus(`Fetching property ${uprn}`);

		client.fetchPropertyFromUprn(uprn, team.id).then((json) => onPropertiesResponse(json));
	}, [processing, currentRecordIndex, acceptedFiles, client, fieldMapping, imageFields, records, team.id, deleteExistingImages]);

	return {
		status,
		progress,
		errors,
		processing,
		beginProcessing,
		cancelProcessing,
	};
}
