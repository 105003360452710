import { useState } from "react";
import { useInterval } from "usehooks-ts";
import Modal from "../Modal/Modal";
import { useRestClientContext } from "../../contexts/RestClientContext";
import { Progress } from "semantic-ui-react";
import { BulkCustomImprovementsAggregate, BulkCustomImprovementSetsBatch } from "../../types/types";
import Button from "../UI/Button/Button";

interface BulkCustomImprovementSetsProgressModalProps {
	batches: BulkCustomImprovementSetsBatch[];
	onClose: Function;
}

export default function BulkCustomImprovementSetsProgressModal({
	batches,
	onClose,
}: BulkCustomImprovementSetsProgressModalProps) {
	const client = useRestClientContext();
	const initialState: BulkCustomImprovementsAggregate = {
		// not too bothered about calculating all these values as this will be done on the backend when polling
		cancelled_at: null,
		finished_at: null,
		progress: 0,
		total_failed: 0,
		total_jobs: 0,
		total_pending: 0,
	}

	const [state, setState] = useState<BulkCustomImprovementsAggregate>(initialState);
	const [isCancelLoading, setCancelLoading] = useState(false);
	const [pollDelay, setPollDelay] = useState(1000);

	const pollBatch = () => {
		// TODO: Options can be undefined, see
		client
			.fetchBulkCustomImprovementBatchAggregate(
				batches[0].options!.team_id,
				batches.map((batch) => batch.id),
			).then((response: Response) => response.json())
			.then((json: BulkCustomImprovementsAggregate) => {
				setState(json);
			});
	};

	// start with small delay and exponentially increase for small jobs and sync queues
	useInterval(
		() => {
			pollBatch();
			if (pollDelay < 8000) setPollDelay(pollDelay * 2);
		},
		state?.cancelled_at || state.progress >= 100 ? null : pollDelay
	);

	const cancelBatch = () => {
		setCancelLoading(true);
		for (const batch of batches) {
			client
				.deleteBulkCustomImprovementBatch(batch.options!.team_id, batch.id)
				.then(() => pollBatch())
				.finally(() => setCancelLoading(false));
		}
	};

	return (
		<Modal title="Running Custom Measures" onClose={() => onClose()}>
			<>
				<p>
					Custom Measure Sets batch{" "}
					{state.cancelled_at ? "has been canceled" : state.progress >= 100 ? "has finished" : "is running"}.
				</p>
				{state.progress < 100 && (
					<p>
						You can safely close this window or navigate away, and the batch will continue running in the
						background.
					</p>
				)}
				<Progress percent={state.progress} error={!!state.cancelled_at} autoSuccess />
			</>

			<div className="button-container">
				<Button
					variant="contained"
					onClick={cancelBatch}
					loading={isCancelLoading}
					disabled={!!state.cancelled_at || state.progress >= 100}
				>
					Cancel Batch Job
				</Button>
			</div>
		</Modal>
	);
}
