import { PropsWithChildren } from "react";
import { Grid } from "semantic-ui-react";

import Property from "../../rest/Property";

import Card from "../UI/Card/Card";
import Field from "./Field";

import "./DetailsCard.scss";

interface DetailsCardProps extends PropsWithChildren {
	property: Property;
}

export default function DetailsCard({ children, property }: DetailsCardProps) {
	const formatPvString = (property: Property) => {
		let pvString = property.data.pvpower ? `${property.data.pvpower}kW h` : 'None';
		if (property.pvOrientation != null) {
			pvString += ` (${property.pvOrientation})`;
		}
		return pvString;
	}

	return (
		<Card title="Property Details" className="property-details">
			<Grid>
				<Grid.Row>
					<Grid.Column>
						<Field label="UPRN" value={property.data.uprn} />
						<Field label="Address" value={property.fullAddress} />
						<Field
							label="Wall Construction"
							value={`${property.data.wall_type} (${property.data.wall_construction})`}
						/>
						<Field
							label="Roof Insulation"
							value={`${property.data.roof_type}mm (${property.data.roof_construction})`}
						/>
						<Field
							label="Glazing"
							value={`${property.data.glazing_type} (${property.data.glazing_u_value})`}
						/>
						<Field
							label="Fuel Type"
							value={`${property.data.fuel_type} (${property.data.main_fuel_price})`}
						/>
						<Field
							label="Boiler"
							value={`${property.boiler} (${property.data.heating_efficiency})`}
						/>
						<Field label="PV" value={formatPvString(property)} />

					</Grid.Column>
				</Grid.Row>

				<Grid.Row>
					<Grid.Column>
						<Field label="Lighting" value={`${property.data.lighting}%`} />
						<Field label="Current Heat Demand" value={property.currentHeatDemand} />
						<Field label="Potential Heat Demand" value={property.potentialHeatDemand} />
						<Field label="Current CO2 Usage" value={property.currentCo2Usage} />
						<Field label="Potential CO2 Usage" value={property.potentialCo2Usage} />
						<Field label="ODC EPC Lodgement Date" value={property.odcEpcLodgementDate} />
						<Field label="Last accepted ODC EPC suggestion" value={property.odcLastAcceptedSuggestion} />
					</Grid.Column>
				</Grid.Row>
			</Grid>

			<div className="epc-ratings">
				<div className="rating current">
					<span className="label">Current</span>
					<span className="value">{property.data.epc_rating}</span>
				</div>
				<div className="rating potential">
					<span className="label">Potential</span>
					<span className="value">{property.potentialEpcRating}</span>
				</div>

				{children}
			</div>
		</Card>
	);
}
