/* mears-mcmview-override-contents

import { useInjected } from "@yellow-sub/angular-react";
import { UseTeamContextService } from '../services/use-team-context.service';

export function useTeamContext()
{
	return useInjected(UseTeamContextService);
}

*/

import { createContext, useContext } from "react";
import Team from "../rest/Team";

const TeamContext = createContext<Team | undefined>(undefined);

export default TeamContext;

export function useTeamContext() {
	const context = useContext(TeamContext);
	if (!context) {
		throw new Error("No TeamContext.Provider found when calling useTeamContext.");
	}
	return context;
}
