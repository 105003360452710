import { useState } from "react";

import Checkbox from "../Checkbox/Checkbox";
import getColumns, { ColumnDefinition, ColumnGroupDefinition } from "./getColumns";

import "./ColumnGroupCheckboxes.scss";

const GROUP_CHECKBOX_LOCAL_STORAGE_KEY = "group-checkbox-save";

interface ColumGroupCheckboxesProps {
	onGroupVisibilityChanged: Function;
}

interface ColumnGroup {
	label: string;
	columns: ColumnDefinition[];
}

export default function ColumnGroupCheckboxes({ onGroupVisibilityChanged }: ColumGroupCheckboxesProps) {
	const columns = getColumns();
	const groups: Record<string, ColumnGroup> = {};

	const initCheckedState: Record<string, boolean> = {};
	const savedChecked = JSON.parse(localStorage.getItem(GROUP_CHECKBOX_LOCAL_STORAGE_KEY) ?? "{}");

	for (const column of columns) {
		if (!(column as ColumnGroupDefinition).hasVisibilityToggle) continue;

		const key = column.Header;

		groups[key] = {
			label: column.Header,
			columns: (column as ColumnGroupDefinition).columns,
		};

		// will check the saved state for validity and only use current columns
		if (savedChecked && savedChecked[key] !== null) {
			initCheckedState[key] = savedChecked[key];
		} else {
			initCheckedState[key] = true;
		}
	}

	const [checked, setChecked] = useState(initCheckedState);

	const onToggle = (key: string) => {
		const state = {
			...checked,
			[key]: !checked[key],
		};

		setChecked(state);

		onGroupVisibilityChanged({ columns: groups[key].columns });

		localStorage.setItem(GROUP_CHECKBOX_LOCAL_STORAGE_KEY, JSON.stringify(state));
	};

	const items = [];

	for (const key in groups) {
		const group = groups[key];

		items.push(
			<li key={key}>
				<Checkbox label={group.label} checked={checked[key]} onChange={() => onToggle(key)} />
			</li>
		);
	}

	return <ul className="column-group-checkboxes data-filters">{items}</ul>;
}
