import { useMemo } from "react";
import { useEffect, useState } from "react";
import * as XLSX from "xlsx";

export default function useProcessImageUploadSpreadsheetFiles({ files }) {
	const [header, setHeader] = useState([]);
	const [records, setRecords] = useState([]);
	const [keys, setKeys] = useState([]);

	const onSpreadsheetLoaded = useMemo(() => {
		return (event) => {
			const workbook = XLSX.read(event.target.result);
			const keyMap = {};
			let records = [];

			for (const key in workbook.Sheets) {
				const sheet = workbook.Sheets[key];
				const arr = XLSX.utils.sheet_to_row_object_array(sheet);

				for (const obj of arr)
					Object.keys(obj).forEach((key) => {
						keyMap[key] = true;
					});

				records = [...records, ...arr];

				const rowMajor = XLSX.utils.sheet_to_json(sheet, { header: 1 });
				const first = rowMajor[0];

				setHeader(first);
			}

			setKeys(Object.keys(keyMap).sort());
			setRecords(records);
		};
	}, []);

	useEffect(() => {
		files.forEach((file) => {
			const reader = new FileReader();
			reader.onload = onSpreadsheetLoaded;
			reader.readAsArrayBuffer(file);
		});
	}, [files, onSpreadsheetLoaded]);

	return { header, keys, records };
}
