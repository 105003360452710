import RoofTypeSelect from "../UI/RoofTypeSelect/RoofTypeSelect";
import BulkEditModal, { BulkEditModalField, BulkEditModalProps } from "./BulkEditModal";

interface BulkEditRootTypeModalProps extends BulkEditModalProps {}

export default function BulkEditRoofTypeModal(props: BulkEditRootTypeModalProps) {
	const fields: BulkEditModalField[] = [
		{
			label: "Roof Type",
			key: "roof_type",
			component: RoofTypeSelect,
		},
	];

	return <BulkEditModal {...props} modalProps={{ title: "Edit Roof Type" }} fields={fields} />;
}
