import { useState, useEffect } from "react";
import { Table, Icon } from "semantic-ui-react";
import { DateTime } from "luxon";

import { useRestClientContext } from "../../../contexts/RestClientContext";

import { Batch } from "../../../types/types";

import Suspense from "../Suspense/Suspense";
import Right from "../Right/Right";
import Button from "../Button/Button";
import JobBatchProgressBar from "../JobBatchProgressBar/JobBatchProgressBar";
import FailedJobsModal from "../../Modal/FailedJobsModal";
import CancelBatchConfirmationModal from "../../Modal/CancelBatchConfirmationModal";

import "./JobBatchesTable.scss";

interface JobBatchesTableProps
{
	teamId: number;
}

export default function JobBatchesTable({teamId}: JobBatchesTableProps)
{
	const client = useRestClientContext();
	const [promise, setPromise] = useState<Promise<any>>();
	const [batches, setBatches] = useState<Batch[]>([]);
	const [reloads, setReloads] = useState(0);
	const [failedJobsBatch, setFailedJobsBatch] = useState<Batch>();
	const [cancellingBatch, setCancellingBatch] = useState<Batch>();

	const formatDateTime = (dateTime: number) => {
		try{
			return DateTime.fromSeconds(dateTime).toLocaleString(DateTime.DATETIME_FULL);
		}
		catch(e) {}
	}

	useEffect(() => {

		setPromise(
			client
				.fetchCustomImprovementBatches(teamId)
				.then((response: Response) => response.json())
				.then((json: Batch[]) => setBatches(json))
		);

	}, [teamId, reloads, client]);

	const onReload = () => setReloads(reloads + 1);
	const onViewFailedJobs = (batch: Batch) => setFailedJobsBatch(batch);
	const onCancelBatch = (batch: Batch) => setCancellingBatch(batch);

	return <Suspense promise={promise}>
		<Right>
			<Button variant="text" onClick={onReload}>
				<Icon name="refresh"></Icon>
			</Button>
		</Right>
		<Table className="job-batches-table">
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell>
						ID
					</Table.HeaderCell>
					<Table.HeaderCell>
						Progress
					</Table.HeaderCell>
					<Table.HeaderCell>
						Failed jobs
					</Table.HeaderCell>
					<Table.HeaderCell>
						Created at
					</Table.HeaderCell>
					<Table.HeaderCell>
						Cancelled at
					</Table.HeaderCell>
					<Table.HeaderCell>
						<span hidden>
							Actions
						</span>
					</Table.HeaderCell>
				</Table.Row>
			</Table.Header>
			<Table.Body>
				{
					batches.map(batch => 
						<Table.Row key={batch.id}>
							<Table.Cell>
								{batch.id}
							</Table.Cell>
							<Table.Cell className="progress">
								<JobBatchProgressBar batch={batch} />
							</Table.Cell>
							<Table.Cell>
								{batch.failed_jobs}
								{
									batch.failed_jobs > 0 &&
									<Icon 
										name="eye" 
										onClick={() => onViewFailedJobs(batch)} 
									/>
								}
							</Table.Cell>
							<Table.Cell>
								{/* TODO: Need a DateTime component really */}
								{formatDateTime(batch.created_at)}
							</Table.Cell>
							<Table.Cell>
								{/* TODO: Need a DateTime component really */}
								{formatDateTime(batch.cancelled_at)}
							</Table.Cell>
							<Table.Cell>
								{
									batch.pending_jobs > 0 && batch.cancelled_at == null &&
									<Button 
										variant="contained"
										onClick={() => onCancelBatch(batch)}
									>
										Cancel
									</Button>
								}
							</Table.Cell>
						</Table.Row>
					)
				}
			</Table.Body>
		</Table>
		{
			failedJobsBatch && 
			<FailedJobsModal 
				teamId={teamId}
				batchId={failedJobsBatch.id}
				onClose={() => setFailedJobsBatch(undefined)}
			/>
		}
		{
			cancellingBatch &&
			<CancelBatchConfirmationModal
				teamId={teamId}
				batchId={cancellingBatch.id}
				onCancelled={onReload}
				onClose={() => setCancellingBatch(undefined)}
			/>
		}
	</Suspense>;
}
