import { useState, useRef, useEffect } from "react";

import { useRestClientContext } from "../contexts/RestClientContext";
import { PropertyResponse } from "../rest/Property";
import { PropertyFeature } from "../types/types";

export type PropertyQueryField = "id" | "uprn" | "lat" | "lng" | "addr_no" | "addr_street" | "addr_city" | "postcode" | "epc_rating" | "epc_rating_grade" | "data_integrity";

export interface BatchedPropertiesResponse
{
	entries: PropertyResponse[];
	page_number: number;
	total_pages: number;
}

// TODO: Should extend Feature<Geometry, GeoJsonProperties> ideally
export interface BatchedPropertiesCollection
{
	progress: number;
	isLoading: Boolean;
	type: String;
	features: PropertyFeature[];
	error?: string;
}

interface PropertyQueryParameters
{
	page_number?: number;
	page_size?: number;
	fields?: PropertyQueryField[];
}

const PAGE_SIZE = 5000;

export default function useBatchedProperties(teamId: number, fields?: PropertyQueryField[]) {
	const client = useRestClientContext();
	const abortControllerRef = useRef<AbortController>();

	const [page, setPage] = useState(1);
	const [properties, setProperties] = useState<BatchedPropertiesCollection>({
		isLoading: true,
		type: "FeatureCollection",
		features: [],
		progress: 0
	});

	useEffect(() => {
		if (abortControllerRef.current) 
			abortControllerRef.current.abort();

		abortControllerRef.current = new AbortController();

		const params: PropertyQueryParameters = {
			page_number: page,
			page_size: PAGE_SIZE
		};

		if(fields)
			params.fields = fields;
	
		client
			.fetchTeamProperties(teamId, params)
			.then((response: Response) => response.json())
			.then((json: BatchedPropertiesResponse) => {
				
				const append: PropertyFeature[] = [];

				json.entries.forEach((obj: PropertyResponse) => {

					const feature: PropertyFeature = {
						type: "Feature",
						properties: {
							data: obj,
							epc_rating: obj.epc_rating // NB: So that the fill style in layers.tsx can access this
						},
						geometry: {
							type: "Point",
							coordinates: [Number(obj.lng), Number(obj.lat)],
						},
					};

					if (isNaN(feature.geometry.coordinates[0]) || isNaN(feature.geometry.coordinates[1])) {
						console.warn(`Property ${obj.id} has invalid geometry and will be ignored`);
						return;
					}

					append.push(feature);

				});

				const data: BatchedPropertiesCollection = {
					isLoading: json.page_number < json.total_pages,
					progress: json.page_number / json.total_pages,
					type: "FeatureCollection",
					features: [...properties.features, ...append],
				};

				setProperties(data);

				if (json.page_number < json.total_pages)
					setPage(page + 1);
				
			})
			.catch((e: Error) => {
				
				if(e.name === "AbortError")
					return;
			
				setProperties({
					...properties,
					error: e.message
				});

			});

		// NB: We ignore exhaustive deps here because it wants properties.features, but this is appended to constantly. This causes the last page to be fetched repeatedly
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [client, teamId, page]);

	return properties;
}
