import React from "react";
import Input from "../../Input/Input";

interface DateRangeColumnFilterProps {
	column: {
		filterValue: string[];
		setFilter: Function;
		min: number;
		max: number;
	}
}

export default function DateRangeColumnFilter({
	column: { filterValue = [], setFilter, min, max },
}: DateRangeColumnFilterProps) {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Input
				type="date"
				min={min}
				max={max}
				value={filterValue[0] || undefined}
				onChange={(e) => {
					const val = e.target.value;
					setFilter((old = []) => [val, old[1]]);
				}}
			/>
			<Input
				type="date"
				min={min}
				max={max}
				value={filterValue[1] || undefined}
				onChange={(e) => {
					const val = e.target.value;
					setFilter((old = []) => [old[0], val]);
				}}
			/>
		</div>
	);
}
