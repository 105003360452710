import { useEffect, useRef, useState, useMemo } from "react";
import polyline from "google-polyline";
import { Feature, Polygon } from "geojson";

import { PropertyFilters } from "../types/types";
import { useRestClientContext } from "../contexts/RestClientContext";
import { useTeamContext } from "../contexts/TeamContext";

import { BatchedPropertiesResponse, PropertyQueryField } from "./useBatchedProperties";
import useBatchedProperties from "./useBatchedProperties";

export interface UseFilteredFeatureCollectionProps {
	address?: string;
	groups?: number[];
	grades?: { [key: string]: boolean };
	polygons?: Feature<Polygon>[];
	detachment?: string;
	propertyType?: string;
	fuelType?: string;
	roofType?: number;
	wallType?: string;
	triggerUpdate: number;
	fields?: PropertyQueryField[];
}

export default function useFilteredFeatureCollection({
	address,
	groups,
	grades,
	polygons,
	detachment,
	fuelType,
	propertyType,
	roofType,
	wallType,
	triggerUpdate,
	fields
}: UseFilteredFeatureCollectionProps) {
	
	const abortControllerRef = useRef<AbortController>();
	
	const client = useRestClientContext();
	const team = useTeamContext();
	const unfilteredProperties = useBatchedProperties(team.id, [
		"id",
		"uprn",
		"lat",
		"lng",
		"addr_no",
		"addr_street",
		"addr_city",
		"postcode",
		"epc_rating",
		"epc_rating_grade",
		"data_integrity"
	]);
	
	const [isFilterLoading, setIsFilterLoading] = useState(false);
	const [applyFiltersOnLoad, setApplyFiltersOnLoad] = useState(false);
	const [filteredProperties, setFilteredProperties] = useState<BatchedPropertiesResponse>();

	const properties = useMemo(() => {

		if(unfilteredProperties.isLoading || !filteredProperties)
			return unfilteredProperties;
		
		const allowedById: Record<number, boolean> = {};

		filteredProperties.entries.forEach(entry => allowedById[entry.id] = true);

		return {
			...unfilteredProperties,
			features: unfilteredProperties.features.filter(feature => allowedById[feature.properties.data.id])
		};

	}, [unfilteredProperties, filteredProperties]);

	const updateFilters = () => {
		
		if(unfilteredProperties.isLoading)
		{
			setApplyFiltersOnLoad(true);
			return;
		}

		setIsFilterLoading(true);

		if (abortControllerRef.current) 
			abortControllerRef.current.abort();

		abortControllerRef.current = new AbortController();

		const encodedPolygons = [];

		if(polygons?.length)
			for(const polygon of polygons)
			{
				const encoded = polyline.encode(polygon.geometry.coordinates[0] as [number, number][]);

				encodedPolygons.push(encoded);
			}

		const filters: PropertyFilters = {
			address,
			detachment,
			property_type: propertyType,
			wall_type: wallType,
			fuel_type: fuelType,
			roof_type: roofType,
			epc_rating_grade: grades ? Object.keys(grades).filter((grade) => !!grades[grade]) : undefined,
			group_ids: groups,
			polygons: encodedPolygons
		};

		// NB: convert the filter object into an array with id-value mapping
		let filtersArray: { id: string; value: string }[] = [];
		for (const [key, val] of Object.entries(filters)) {
			if (val != null) {
				filtersArray.push({ id: key, value: val });
			}
		}

		const params = {
			filters: JSON.stringify(filtersArray),
			fields: ["id"],
			page_size: 0xFFFFFFFF
		};

		// const qstr = queryString.stringify(params);

		client
			.fetchTeamProperties(team.id, params)
			.then((response: Response) => response.json())
			.then((json: BatchedPropertiesResponse) => setFilteredProperties(json))
			.catch((e: Error) => {

				if(e.name === "AbortError")
					return;
			
				throw e;

			})
			.finally(() => setIsFilterLoading(false));

	};

	useEffect(() => {

		if(unfilteredProperties.isLoading === false && applyFiltersOnLoad)
			updateFilters();

		// NB: Ignore the deps, it would be better to use useCallback above but this broke the isFilterLoading behaviour
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unfilteredProperties.isLoading]);

	return {
		properties, 
		updateFilters,
		isFilterLoading
	};

}
