import React from "react";

const ArrowVector = () => {
	return (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M20 1C9.50659 1 0.999998 9.50659 0.999998 20C0.999997 30.4934 9.50659 39 20 39C30.4934 39 39 30.4934 39 20C39 9.50659 30.4934 1 20 1Z"
				stroke="white"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M20 16L16 20L20 24" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M24 20L16 20" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};

export default ArrowVector;
