import { List, Input, Label, Icon, Segment, SemanticICONS } from "semantic-ui-react";

import Button from "../UI/Button/Button";
import { CustomImprovement } from "../../types/types";

import "./Item.scss";
import { ChangeEvent } from "react";

interface ImprovementProps
{
	context: "available" | "selected";
	improvement: CustomImprovement;
	reason?: string;
	param?: number;
	parameterInputDisabled?: boolean;
	onAdd?: Function;
	onRemove?: Function;
	onParameterChange: Function;
}

export default function Improvement({context, reason, improvement, param, parameterInputDisabled, onAdd, onRemove, onParameterChange}: ImprovementProps)
{
	let icon: SemanticICONS;
	let callback: Function | undefined;

	switch(context)
	{
		case "available":
			icon = "add";
			callback = onAdd;
			break;
		
		case "selected":
			icon = "minus";
			callback = onRemove;
			break;
		
		default:
			throw new Error(`Invalid context '${context}'`);
	}

	const showParameterComponents = improvement.accepts_parameters && context === "selected";

	return <List.Item className="custom-improvements-list-item">
		<Segment>
			<Icon name="bars" title="Re-order improvement" />
			<div className="label-and-description">
				<Label>
					{improvement.name}
				</Label>
				<p title="Improvement description">
					<Icon name="info circle" />
					<small>
						{improvement.description}
					</small>
				</p>
				{
					reason && <p title="Reason for suggestion">
						<Icon name="question circle" />
						<small>
							{reason}
						</small>
					</p>
				}
			</div>
			<div className="parameters-and-controls">
				{
					showParameterComponents && 
					<div className="parameter-input">
						<Input 
							type="number"
							placeholder={improvement.units}
							defaultValue={param}
							min={improvement.min}
							max={improvement.max}
							disabled={parameterInputDisabled}
							onInput={(event: ChangeEvent<HTMLInputElement>) => onParameterChange(improvement, event.target.value)}
						/>
						<small>
							{improvement.min} {improvement.units} - {improvement.max} {improvement.units}
						</small>
					</div>
				}
				<Button 
					variant="contained"
					size="mini"
					title="Remove Custom Measure"
					onClick={() => callback!(improvement)}
				>
					<Icon name={icon} />
				</Button>
			</div>
		</Segment>
	</List.Item>
}
