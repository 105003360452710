import React from "react";
import Select from "../../Select/Select";

interface SelectColumFilterProps {
	column: {
		filterValue: string | string[];
		setFilter: Function;
		aggregatedValues: string[];
		multiple: boolean;
	}
}

export default function SelectColumnFilter({
	column: { filterValue, setFilter, aggregatedValues, multiple },
}: SelectColumFilterProps) {
	const aggregates = aggregatedValues?.filter((value) => value != null) ?? [];
	const options = aggregates.map((value, index) => {
		return {
			key: index,
			text: value,
			value: value,
		};
	});

	if (multiple && filterValue === undefined) filterValue = [];

	return (
		<Select
			clearable
			value={filterValue}
			multiple={multiple}
			options={options}
			onChange={(e, { value }) => setFilter(value || undefined)}
		/>
	);
}
