import React from "react";

const ThermalVector = () => {
	return (
		<svg width="89" height="89" viewBox="0 0 89 89" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M16.1001 21.3999H14.1001V15.3999C14.1001 14.7999 14.5001 14.3999 15.1001 14.3999H21.1001V16.3999H16.1001V21.3999Z"
				fill="#373A36"
			/>
			<path
				d="M74.1001 21.3999H72.1001V16.3999H67.1001V14.3999H73.1001C73.7001 14.3999 74.1001 14.7999 74.1001 15.3999V21.3999Z"
				fill="#373A36"
			/>
			<path
				d="M21.1001 74.3999H15.1001C14.5001 74.3999 14.1001 73.9999 14.1001 73.3999V67.3999H16.1001V72.3999H21.1001V74.3999Z"
				fill="#373A36"
			/>
			<path
				d="M73.1001 74.3999H67.1001V72.3999H72.1001V67.3999H74.1001V73.3999C74.1001 73.9999 73.6001 74.3999 73.1001 74.3999Z"
				fill="#373A36"
			/>
			<path d="M37.1001 31.3999H19.1001V65.3999H37.1001V31.3999Z" fill="white" />
			<path d="M69.1001 65.3999V19.3999H37.1001V65.3999" fill="white" />
			<path
				d="M69.1001 70.3999H19.1001C18.5001 70.3999 18.1001 69.9999 18.1001 69.3999V65.3999C18.1001 64.7999 18.5001 64.3999 19.1001 64.3999H69.1001C69.7001 64.3999 70.1001 64.7999 70.1001 65.3999V69.3999C70.1001 69.9999 69.6001 70.3999 69.1001 70.3999ZM20.1001 68.3999H68.1001V66.3999H20.1001V68.3999V68.3999Z"
				fill="#373A36"
			/>
			<path
				d="M20.1001 62.3999H18.1001V31.3999C18.1001 30.7999 18.5001 30.3999 19.1001 30.3999H37.1001V32.3999H20.1001V62.3999Z"
				fill="#373A36"
			/>
			<path
				d="M70.1001 62.3999H68.1001V20.3999H38.1001V62.3999H36.1001V19.3999C36.1001 18.7999 36.5001 18.3999 37.1001 18.3999H69.1001C69.7001 18.3999 70.1001 18.7999 70.1001 19.3999V62.3999Z"
				fill="#373A36"
			/>
			<path d="M25.1001 36.3999H23.1001V38.3999H25.1001V36.3999Z" fill="#373A36" />
			<path d="M29.1001 36.3999H27.1001V38.3999H29.1001V36.3999Z" fill="#373A36" />
			<path d="M33.1001 36.3999H31.1001V38.3999H33.1001V36.3999Z" fill="#373A36" />
			<path d="M25.1001 40.3999H23.1001V42.3999H25.1001V40.3999Z" fill="#373A36" />
			<path d="M29.1001 40.3999H27.1001V42.3999H29.1001V40.3999Z" fill="#373A36" />
			<path d="M33.1001 40.3999H31.1001V42.3999H33.1001V40.3999Z" fill="#373A36" />
			<path d="M25.1001 44.3999H23.1001V46.3999H25.1001V44.3999Z" fill="#373A36" />
			<path d="M29.1001 44.3999H27.1001V46.3999H29.1001V44.3999Z" fill="#373A36" />
			<path d="M33.1001 44.3999H31.1001V46.3999H33.1001V44.3999Z" fill="#373A36" />
			<path d="M25.1001 48.3999H23.1001V50.3999H25.1001V48.3999Z" fill="#373A36" />
			<path d="M29.1001 48.3999H27.1001V50.3999H29.1001V48.3999Z" fill="#373A36" />
			<path d="M33.1001 48.3999H31.1001V50.3999H33.1001V48.3999Z" fill="#373A36" />
			<path d="M25.1001 52.3999H23.1001V54.3999H25.1001V52.3999Z" fill="#373A36" />
			<path d="M29.1001 52.3999H27.1001V54.3999H29.1001V52.3999Z" fill="#373A36" />
			<path d="M33.1001 52.3999H31.1001V54.3999H33.1001V52.3999Z" fill="#373A36" />
			<path d="M25.1001 56.3999H23.1001V58.3999H25.1001V56.3999Z" fill="#373A36" />
			<path d="M29.1001 56.3999H27.1001V58.3999H29.1001V56.3999Z" fill="#373A36" />
			<path d="M33.1001 56.3999H31.1001V58.3999H33.1001V56.3999Z" fill="#373A36" />
			<path
				d="M57.1001 66.3999H49.1001C48.5001 66.3999 48.1001 65.9999 48.1001 65.3999V57.3999C48.1001 56.7999 48.5001 56.3999 49.1001 56.3999H57.1001C57.7001 56.3999 58.1001 56.7999 58.1001 57.3999V65.3999C58.1001 65.9999 57.6001 66.3999 57.1001 66.3999ZM50.1001 64.3999H56.1001V58.3999H50.1001V64.3999Z"
				fill="#373A36"
			/>
			<path
				d="M47.1001 30.3999H43.1001C42.5001 30.3999 42.1001 29.9999 42.1001 29.3999V25.3999C42.1001 24.7999 42.5001 24.3999 43.1001 24.3999H47.1001C47.7001 24.3999 48.1001 24.7999 48.1001 25.3999V29.3999C48.1001 29.9999 47.6001 30.3999 47.1001 30.3999ZM44.1001 28.3999H46.1001V26.3999H44.1001V28.3999Z"
				fill="#373A36"
			/>
			<path
				d="M47.1001 38.3999H43.1001C42.5001 38.3999 42.1001 37.9999 42.1001 37.3999V33.3999C42.1001 32.7999 42.5001 32.3999 43.1001 32.3999H47.1001C47.7001 32.3999 48.1001 32.7999 48.1001 33.3999V37.3999C48.1001 37.9999 47.6001 38.3999 47.1001 38.3999ZM44.1001 36.3999H46.1001V34.3999H44.1001V36.3999Z"
				fill="#373A36"
			/>
			<path
				d="M47.1001 46.3999H43.1001C42.5001 46.3999 42.1001 45.9999 42.1001 45.3999V41.3999C42.1001 40.7999 42.5001 40.3999 43.1001 40.3999H47.1001C47.7001 40.3999 48.1001 40.7999 48.1001 41.3999V45.3999C48.1001 45.9999 47.6001 46.3999 47.1001 46.3999ZM44.1001 44.3999H46.1001V42.3999H44.1001V44.3999Z"
				fill="#373A36"
			/>
			<path
				d="M55.1001 46.3999H51.1001C50.5001 46.3999 50.1001 45.9999 50.1001 45.3999V41.3999C50.1001 40.7999 50.5001 40.3999 51.1001 40.3999H55.1001C55.7001 40.3999 56.1001 40.7999 56.1001 41.3999V45.3999C56.1001 45.9999 55.6001 46.3999 55.1001 46.3999ZM52.1001 44.3999H54.1001V42.3999H52.1001V44.3999Z"
				fill="#373A36"
			/>
			<path
				d="M63.1001 46.3999H59.1001C58.5001 46.3999 58.1001 45.9999 58.1001 45.3999V41.3999C58.1001 40.7999 58.5001 40.3999 59.1001 40.3999H63.1001C63.7001 40.3999 64.1001 40.7999 64.1001 41.3999V45.3999C64.1001 45.9999 63.6001 46.3999 63.1001 46.3999ZM60.1001 44.3999H62.1001V42.3999H60.1001V44.3999Z"
				fill="#373A36"
			/>
			<path
				d="M47.1001 54.3999H43.1001C42.5001 54.3999 42.1001 53.9999 42.1001 53.3999V49.3999C42.1001 48.7999 42.5001 48.3999 43.1001 48.3999H47.1001C47.7001 48.3999 48.1001 48.7999 48.1001 49.3999V53.3999C48.1001 53.9999 47.6001 54.3999 47.1001 54.3999ZM44.1001 52.3999H46.1001V50.3999H44.1001V52.3999Z"
				fill="#373A36"
			/>
			<path
				d="M55.1001 54.3999H51.1001C50.5001 54.3999 50.1001 53.9999 50.1001 53.3999V49.3999C50.1001 48.7999 50.5001 48.3999 51.1001 48.3999H55.1001C55.7001 48.3999 56.1001 48.7999 56.1001 49.3999V53.3999C56.1001 53.9999 55.6001 54.3999 55.1001 54.3999ZM52.1001 52.3999H54.1001V50.3999H52.1001V52.3999Z"
				fill="#373A36"
			/>
			<path
				d="M63.1001 54.3999H59.1001C58.5001 54.3999 58.1001 53.9999 58.1001 53.3999V49.3999C58.1001 48.7999 58.5001 48.3999 59.1001 48.3999H63.1001C63.7001 48.3999 64.1001 48.7999 64.1001 49.3999V53.3999C64.1001 53.9999 63.6001 54.3999 63.1001 54.3999ZM60.1001 52.3999H62.1001V50.3999H60.1001V52.3999Z"
				fill="#373A36"
			/>
			<path
				d="M49.5999 40.4002H70.9999V18.7002C70.9999 18.2002 70.5999 17.7002 69.9999 17.7002H48.3999V39.2002C48.3999 39.9002 48.8999 40.4002 49.5999 40.4002Z"
				fill="white"
			/>
			<path d="M46.1001 56.3999H42.1001V58.3999H46.1001V56.3999Z" fill="#373A36" />
			<path
				d="M51.0999 38.6999H70.0999V19.3999C70.0999 18.8999 69.6999 18.3999 69.0999 18.3999H49.8999V37.3999C49.8999 38.0999 50.3999 38.6999 51.0999 38.6999Z"
				fill="#33666F"
			/>
			<path
				d="M51.0999 38.7002H66.8999V22.7002C66.8999 22.2002 66.4999 21.7002 65.8999 21.7002H49.8999V37.5002C49.8999 38.1002 50.3999 38.7002 51.0999 38.7002Z"
				fill="#78D59F"
			/>
			<path
				d="M51.0999 38.6998H63.2999V26.2998C63.2999 25.7998 62.8999 25.2998 62.2999 25.2998H49.8999V37.4998C49.8999 38.0998 50.3999 38.6998 51.0999 38.6998Z"
				fill="#F9E075"
			/>
			<path d="M64.1001 56.3999H60.1001V58.3999H64.1001V56.3999Z" fill="#373A36" />
			<path
				d="M51.0999 38.7H59.5999V30C59.5999 29.5 59.1999 29 58.5999 29H49.8999V37.5C49.8999 38.1 50.3999 38.7 51.0999 38.7Z"
				fill="#FF5F43"
			/>
		</svg>
	);
};

export default ThermalVector;
