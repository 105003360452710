import GlazingTypeSelect from "../UI/GlazingTypeSelect/GlazingTypeSelect";
import BulkEditModal, { BulkEditModalField, BulkEditModalProps } from "./BulkEditModal";

interface BulkEditGlazingTypeModalProps extends BulkEditModalProps {}

export default function BulkEditGlazingTypeModal(props: BulkEditGlazingTypeModalProps) {
	const fields: BulkEditModalField[] = [
		{
			label: "Glazing Type",
			key: "glazing_type",
			component: GlazingTypeSelect,
		},
	];

	return <BulkEditModal {...props} modalProps={{ title: "Edit Glazing Type" }} fields={fields} />;
}
