// import DefaultColumnFilter from "./Filters/DefaultColumnFilter";
// import FuzzyTextColumnFilter from "./Filters/FuzzyTextColumnFilter";
import BulkEditAddressModal from "../../Modal/BulkEditAddressModal";
import BulkEditFuelTypeModal from "../../Modal/BulkEditFuelTypeModal";
import BulkEditGlazingTypeModal from "../../Modal/BulkEditGlazingTypeModal";
import BulkEditRoofTypeModal from "../../Modal/BulkEditRoofTypeModal";
import BulkEditWallTypeModal from "../../Modal/BulkEditWallTypeModal";
import NumberRangeColumnFilter from "./Filters/NumberRangeColumnFilter";
import SelectColumnFilter from "./Filters/SelectColumnFilter";
import DateRangeColumnFilter from "./Filters/DateRangeColumnFilter";
// import SliderColumnFilter from "./Filters/SliderColumnFilter";

import dateBetweenFilter from "./Filters/dateBetweenFilter";

interface Boundaries {
	epc_rating: number;
	data_integrity: number;
	wall_construction: number;
	glazing_u_value: number;
	roof_construction: number;
	main_fuel_price: number;
	main_fuel_co2: number;
	secondary_fuel_price: number;
	secondary_fuel_co2: number;
	pvpower: number;
	solarpvarea: number;
	current_heat_demand: number;
	potential_heat_demand: number;
	odc_lodgement_date: number;
}

// NB: Distinct values for inputs that aren't ranged
interface Values {
	epc_rating_grades: string[];
	ages: string[];
	detachments: string[];
	property_types: string[];
	wall_types: string[];
	glazing_types: string[];
	roof_types: string[];
	fuel_types: string[];
	heating_types: string[];
	floor_constructions: string[];
	controls: string[];
	tariffs: string[];
	cylinder_ins_types: string[];
	water_sources: string[];
}

export interface Aggregates {
	mins: Boundaries;
	maxs: Boundaries;
	values: Values;
}

interface Defaults {
	epcRatingGrade: string; // TODO: Would be nice to have a type for this A - G
}

export interface ColumnDefinition {
	Header: string;
	accessor: string;
	disableFilters?: boolean;
	Filter?: JSX.Element;
	filter?: string | Function;
	min?: number | string;
	max?: number | string;
	aggregatedValues?: string[];
}

export interface ColumnGroupDefinition {
	Header: string;
	hasVisibilityToggle?: boolean;
	canEdit?: boolean;
	bulkEditModal?: {
		component: JSX.Element;
	},
	columns: ColumnDefinition[];
}

const getColumns = (aggregates?: Aggregates | null, defaults?: Defaults): Array<ColumnGroupDefinition | ColumnDefinition> => [
	{
		Header: "UPRN",
		accessor: "uprn", // accessor is the "key" in the data
	},
	{
		Header: "Address",
		hasVisibilityToggle: true,
		canEdit: true,
		bulkEditModal: {
			// @ts-ignore
			component: BulkEditAddressModal,
		},
		columns: [
			{
				Header: "#",
				accessor: "addr_no",
			},
			{
				Header: "Street",
				accessor: "addr_street",
			},
			{
				Header: "City",
				accessor: "addr_city",
			},
			{
				Header: "Postcode",
				accessor: "postcode",
			},
		],
	},
	{
		Header: "EPC",
		columns: [
			{
				Header: "Rating",
				accessor: "epc_rating",
				// @ts-ignore
				Filter: NumberRangeColumnFilter,
				filter: "between",
				min: aggregates ? aggregates.mins.epc_rating : "",
				max: aggregates ? aggregates.maxs.epc_rating : "",
			},
			{
				Header: "Value",
				accessor: "epc_rating_grade",
				// @ts-ignore
				Filter: SelectColumnFilter,
				filter: "includes",
				aggregatedValues: ["A", "B", "C", "D", "E", "F", "G"], // NB: Hard-coded, now that this is multi-select, this should always show all options
				defaultValue: defaults?.epcRatingGrade,
				multiple: true,
			},
			{
				Header: "Potential Impact",
				accessor: "potential_epc_rating_impact",
				disableFilters: true,
			},
		],
	},
	{
		Header: "Data Quality",
		accessor: "data_integrity",
		// @ts-ignore
		Filter: NumberRangeColumnFilter,
		filter: "between",
		min: aggregates ? aggregates.mins.data_integrity : "",
		max: aggregates ? aggregates.maxs.data_integrity : "",
	},
	{
		Header: "Heat Demand",
		columns: [
			{
				Header: "Current",
				accessor: "current_heat_demand",
				// @ts-ignore
				Filter: NumberRangeColumnFilter,
				filter: "between",
				min: aggregates ? aggregates.mins.current_heat_demand : "",
				max: aggregates ? aggregates.maxs.current_heat_demand : "",
			},
			{
				Header: "Potential",
				accessor: "potential_heat_demand",
				// @ts-ignore
				Filter: NumberRangeColumnFilter,
				filter: "between",
				min: aggregates ? aggregates.mins.potential_heat_demand : "",
				max: aggregates ? aggregates.maxs.potential_heat_demand : "",
			},
		],
	},
	{
		Header: "Property",
		hasVisibilityToggle: true,
		// canEdit: true, // TODO: This has not worked for a long, long time. Re-instate at some point please.
		columns: [
			{
				Header: "Construction Age",
				accessor: "construction_age",
			},
			{
				Header: "Detachment",
				accessor: "detachment",
				// @ts-ignore
				Filter: SelectColumnFilter,
				filter: "includes",
				aggregatedValues: aggregates ? aggregates.values.detachments : [],
			},
			{
				Header: "Type",
				accessor: "property_type",
				// @ts-ignore
				Filter: SelectColumnFilter,
				filter: "includes",
				aggregatedValues: aggregates ? aggregates.values.property_types : [],
			},
			{
				Header: "EPC Lodgement Date",
				accessor: "odc_lodgement_date",
				// @ts-ignore
				Filter: DateRangeColumnFilter,
				filter: dateBetweenFilter,
				min: aggregates ? aggregates.mins.odc_lodgement_date : "",
				max: aggregates ? aggregates.maxs.odc_lodgement_date : "",
			},
			{
				Header: "Boiler Type",
				accessor: "heating_type",
				// @ts-ignore
				Filter: SelectColumnFilter,
				filter: "includes",
				aggregatedValues: aggregates ? aggregates.values.heating_types : [],
			},
			{
				Header: "Heating controls",
				accessor: "controls",
				// @ts-ignore
				Filter: SelectColumnFilter,
				filter: "includes",
				aggregatedValues: aggregates ? aggregates.values.controls : [],
			},
			{
				Header: "Water Source",
				accessor: "water_source",
				// @ts-ignore
				Filter: SelectColumnFilter,
				filter: "includes",
				aggregatedValues: aggregates ? aggregates.values.water_sources : [],
			},
			{
				Header: "Floor construction",
				accessor: "floor_construction",
				// @ts-ignore
				Filter: SelectColumnFilter,
				filter: "includes",
				aggregatedValues: aggregates ? aggregates.values.floor_constructions : [],
			},
		],
	},
	{
		Header: "Wall",
		hasVisibilityToggle: true,
		canEdit: true,
		bulkEditModal: {
			// @ts-ignore
			component: BulkEditWallTypeModal,
		},
		columns: [
			{
				Header: "Type",
				accessor: "wall_type",
				// @ts-ignore
				Filter: SelectColumnFilter,
				filter: "includes",
				aggregatedValues: aggregates ? aggregates.values.wall_types : [],
				multiple: true,
			},
			{
				Header: "Construction",
				accessor: "wall_construction",
				// @ts-ignore
				Filter: NumberRangeColumnFilter,
				filter: "between",
				min: aggregates ? aggregates.mins.wall_construction : "",
				max: aggregates ? aggregates.maxs.wall_construction : "",
			},
		],
	},
	{
		Header: "Glazing",
		hasVisibilityToggle: true,
		canEdit: true,
		bulkEditModal: {
			// @ts-ignore
			component: BulkEditGlazingTypeModal,
		},
		columns: [
			{
				Header: "Type",
				accessor: "glazing_type",
				// @ts-ignore
				Filter: SelectColumnFilter,
				filter: "includes",
				aggregatedValues: aggregates ? aggregates.values.glazing_types : [],
			},
			{
				Header: "U-Value",
				accessor: "glazing_u_value",
				// @ts-ignore
				Filter: NumberRangeColumnFilter,
				filter: "between",
				min: aggregates ? aggregates.mins.glazing_u_value : "",
				max: aggregates ? aggregates.maxs.glazing_u_value : "",
			},
		],
	},
	{
		Header: "Roof",
		hasVisibilityToggle: true,
		canEdit: true,
		bulkEditModal: {
			// @ts-ignore
			component: BulkEditRoofTypeModal,
		},
		columns: [
			{
				Header: "Type",
				accessor: "roof_type",
				// @ts-ignore
				Filter: SelectColumnFilter,
				filter: "includes",
				aggregatedValues: aggregates ? aggregates.values.roof_types : [],
				multiple: true,
			},
			{
				Header: "Construction",
				accessor: "roof_construction",
				// @ts-ignore
				Filter: NumberRangeColumnFilter,
				filter: "between",
				min: aggregates ? aggregates.mins.roof_construction : "",
				max: aggregates ? aggregates.maxs.roof_construction : "",
			},
		],
	},
	{
		Header: "Fuel",
		hasVisibilityToggle: true,
		canEdit: true,
		bulkEditModal: {
			// @ts-ignore
			component: BulkEditFuelTypeModal,
		},
		columns: [
			{
				Header: "Type",
				accessor: "fuel_type",
				// @ts-ignore
				Filter: SelectColumnFilter,
				filter: "includes",
				aggregatedValues: aggregates ? aggregates.values.fuel_types : [],
			},
		],
	},
	/*{
		Header: "Misc",
		hasVisibilityToggle: true,
		canEdit: false,
		columns: [
			{
				Header: "Heating",
				accessor: "heating_type",
				Filter: SelectColumnFilter,
				filter: "includes",
				aggregatedValues: aggregates ? aggregates.values.heating_types : [],
			},
			{
				Header: "Floor construction",
				accessor: "floor_construction",
				Filter: SelectColumnFilter,
				filter: "includes",
				aggregatedValues: aggregates ? aggregates.values.floor_constructions : [],
			},
			{
				Header: "Heating controls",
				accessor: "controls",
				Filter: SelectColumnFilter,
				filter: "includes",
				aggregatedValues: aggregates ? aggregates.values.controls : [],
			},
			{
				Header: "Property description",
				accessor: "property_description",
			},
			{
				Header: "PV Power",
				accessor: "pvpower",
				Filter: NumberRangeColumnFilter,
				filter: "between",
				min: aggregates ? aggregates.mins.pvpower : "",
				max: aggregates ? aggregates.maxs.pvpower : "",
			},
			{
				Header: "Solar Water Heating",
				accessor: "solarpvarea",
				Filter: NumberRangeColumnFilter,
				filter: "between",
				min: aggregates ? aggregates.mins.solarpvarea : "",
				max: aggregates ? aggregates.maxs.solarpvarea : "",
			},
			{
				Header: "Tariff",
				accessor: "electricity_tariff",
				Filter: SelectColumnFilter,
				filter: "includes",
				aggregatedValues: aggregates ? aggregates.values.tariffs : [],
			},
			{
				Header: "Cylinder Ins. Type",
				accessor: "cylinder_ins_type",
				Filter: SelectColumnFilter,
				filter: "includes",
				aggregatedValues: aggregates ? aggregates.values.cylinder_ins_types : [],
			},
			{
				Header: "Block Ref.",
				accessor: "block_ref",
			},
			{
				Header: "Scheme Ref.",
				accessor: "scheme_ref",
			},
			{
				Header: "Notes 1",
				accessor: "notes_1",
			},
			{
				Header: "Notes 2",
				accessor: "notes_2",
			},
		],
	},*/
];

export default getColumns;
