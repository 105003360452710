import React from "react";
import { Image, Dropdown } from "semantic-ui-react";
import { useAuth0 } from "@auth0/auth0-react";

import { useUserContext } from "../../../contexts/UserContext";

import "./ProfileWidget.scss";

const ProfileWidget = () => {
	const { logout } = useAuth0();
	const user = useUserContext();

	const onSignOut = () =>
		logout({
			returnTo: window.location.origin,
		});

	return (
		<div className="profile-widget">
			<div>
				<Image src={user.picture} circular />
			</div>
			<div></div>
			<div>
				<Dropdown text={user.nickname}>
					<Dropdown.Menu>
						<Dropdown.Item text="Sign Out" onClick={() => onSignOut()} />
					</Dropdown.Menu>
				</Dropdown>
			</div>
		</div>
	);
};

export default ProfileWidget;
