import { useState, useEffect } from 'react';
import { Container, List, Message } from "semantic-ui-react";
import { ReactSortable } from 'react-sortablejs';

import { useRestClientContext } from '../../contexts/RestClientContext';
import useCustomImprovementSetsHistory from '../../hooks/useCustomImprovementSetsHistory';

import Button from '../UI/Button/Button';
import Right from '../UI/Right/Right';
import Item from './Item';
import Suspense from '../UI/Suspense/Suspense';
import Select from '../UI/Select/Select';
import { CustomImprovement, CustomImprovementsSet } from '../../types/types';
import { CustomImprovementsModalPanelProps } from '../../types/types';

type CustomParameter = [number];

interface MappedRelationship
{
	id: number;
	reason?: string;
	params?: CustomParameter;
}

export default function CustomPanel({teamId, property, data, onComplete, onClose}: CustomImprovementsModalPanelProps)
{
	const client = useRestClientContext();
	const { save } = useCustomImprovementSetsHistory();

	const [promise, setPromise] = useState<Promise<Response>>();

    const [improvements, setImprovements] = useState<CustomImprovement[]>();
    const [selectedImprovements, setSelectedImprovements] = useState<CustomImprovement[]>([]);
    const [parameterValuesByImprovementName, setParameterValuesByImprovementName] = useState<Record<string, number>>({});

	const [error, setError] = useState<string>();

	// TODO: This won't change very often at all, perhaps we could cache this somewhere
    useEffect(() => {

		const promise = client.fetchCustomImprovements();

		setPromise(promise);

		promise
			.then((response: Response) => response.json())
			.then((data: CustomImprovement[]) => setImprovements(data));
		
	}, [client]);

	const sortByName = (a: CustomImprovement, b: CustomImprovement) => a.name < b.name ? -1 : 1;

    const onAddImprovement = (improvement: CustomImprovement) => {
        if(!(selectedImprovements.includes(improvement))) 
            setSelectedImprovements(improvements => [...improvements, improvement]);
    }

    const onRemoveImprovement = (improvement: CustomImprovement) => {
        setSelectedImprovements(improvements => improvements.filter(item => improvement !== item));
    }

	const onParameterChange = (improvement: CustomImprovement, value: number) => {

		if(value < improvement.min! || value > improvement.max!) {
			setError(`Set value between ${improvement.min!} and ${improvement.max!} for ${improvement.name!}`);
			return; 
		} 

		setError(undefined);

		setParameterValuesByImprovementName({
			...parameterValuesByImprovementName,
			[improvement.name]: value
		});

	};

	const onSubmit = () => {

		if(error)
			return;

		const mapped = selectedImprovements.map<MappedRelationship>((improvement: CustomImprovement) => {

			const result: MappedRelationship = {
				id: improvement.id,
				reason: "User selected custom"
			};

			if(improvement.accepts_parameters)
				result.params = [
					Number(parameterValuesByImprovementName[improvement.name])
				]; // NB: Cast to int and wrap in array, this is how the server expects this - An array of arguments
			
			return result;

		});

		// NB: Store in history
		save(
			selectedImprovements
				.map(improvement => improvement.name)
				.sort()
				.join(", "),
			mapped
		);

		let promise;

		if(property)
			promise = client.createPropertyCustomImprovements(property, {
				...data,
				"custom-improvements": mapped
			});
		else
			promise = client.createMultiPropertyCustomImprovements(teamId, {
				...data,
				"custom-improvements": mapped
			});

		setPromise(promise);

		promise
			.then((response: Response) => response.json())
			.then((json: CustomImprovementsSet) => {
				
				if(onComplete)
					onComplete(json);
				
				onClose();

			});

	};

	const options = improvements
		?.filter(improvement => !selectedImprovements.includes(improvement))
		.sort(sortByName)
		.map(improvement => {
			return {
				key: improvement.id,
				value: improvement.id,
				text: improvement.name
			}
		});
	
	return <Suspense promise={promise}>
		<Select 
			placeholder="Select Improvement"
			fluid
			search
			selection
			size="full"
			closeOnChange
			options={options} 
			value={false} // NB: Don't allow selection to persist
			onChange={(e, { value }) => onAddImprovement( improvements!.find(improvement => improvement.id === value)! )}
		/>
		<List>
			<ReactSortable 
				list={selectedImprovements} 
				setList={setSelectedImprovements}
				className="sortable-list"
			>
				{
					selectedImprovements
						.map((improvement) =>
							<Item 
								key={improvement.id}
								improvement={improvement} 
								context="selected"
								param={parameterValuesByImprovementName[improvement.name]}
								onRemove={onRemoveImprovement}
								onParameterChange={onParameterChange}
							/>
						)
				}

			</ReactSortable>
		</List>
		<Container>
			{error && <Message negative>{error}</Message>}
			<Right>
				<Button variant="contained" onClick={onSubmit}>Submit</Button>
			</Right>
		</Container>
	</Suspense>;
}