import { ChangeEvent, FunctionComponent, useState } from "react";

import Modal, { ModalProps } from "./Modal";
import { Form } from "semantic-ui-react";
import Button from "../UI/Button/Button";
import Input from "../UI/Input/Input";

import "./BulkEditModal.scss";
import { useTeamContext } from "../../contexts/TeamContext";
import { useRestClientContext } from "../../contexts/RestClientContext";
import { PropertyResponse } from "../../rest/Property";

interface ComponentProps {
	disabled: boolean;
	value: string;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}
export interface BulkEditModalField {
	key: string;
	label: string;
	component?: FunctionComponent<ComponentProps>;
}
export interface BulkEditModalProps {
	fields: BulkEditModalField[];
	selectedPropertyIds: number[];
	onClose: Function;
	modalProps: ModalProps;
}

export default function BulkEditModal({ fields, selectedPropertyIds, onClose, modalProps }: BulkEditModalProps) {
	const client = useRestClientContext();
	const team = useTeamContext();

	// const initial = Object.fromEntries(fields.map((obj) => [obj.key, ""]));

	let objFromEntries: Record<string, string> = {};

	fields.forEach(obj => {
		objFromEntries[obj.key] = "";
	});

	const initial = objFromEntries;

	const [state, setState] = useState(initial);
	const [loading, setLoading] = useState(false);

	const onChange = (key: string, value: string) => {
		setState({
			...state,
			[key]: value,
		});
	};

	const onSubmit = () => {
		const body = selectedPropertyIds.map((id) => {
			return {
				id: id,
				...state,
			};
		});

		setLoading(true);

		client
			.updateTeamProperties(team.id, body)
			.then((response: Response) => response.json())
			.then((json: PropertyResponse[]) => {
				setLoading(false);
				onClose();
			});

		// TODO: Handle errors
	};

	return (
		<Modal {...modalProps} className="bulk-edit-modal">
			<Form>
				{fields.map((definition) => {
					const Component = definition.component ? definition.component : Input;
					const props = {
						disabled: loading,
						value: state[definition.key],
						onChange: (event: ChangeEvent<HTMLInputElement>) =>
							onChange(definition.key, event.target.value),
					};

					return (
						<Form.Field key={definition.key}>
							<label>{definition.label}</label>
							<Component {...props} />
						</Form.Field>
					);
				})}
				<div className="button-container">
					<Button disabled={loading} onClick={() => onClose()}>
						Cancel
					</Button>
					<Button variant="outlined" disabled={loading} onClick={() => onSubmit()}>
						Submit
					</Button>
				</div>
			</Form>
		</Modal>
	);
}
