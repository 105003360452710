import { useEffect, useState } from "react";
import { Grid, Header, Icon } from "semantic-ui-react";
import { useRestClientContext } from "../contexts/RestClientContext";

import Button from "../components/UI/Button/Button";
import Preloader from "../components/UI/Preloader/Preloader";

import Page from "./Page";
import TeamCard from "../components/UI/TeamCard/TeamCard";
import CreateTeamModal from "../components/Modal/CreateTeamModal";

export default function Teams() {
	const [loading, setLoading] = useState(true);
	const [teams, setTeams] = useState([]);
	const [createTeamModalOpen, setCreateTeamModalOpen] = useState(false);
	const client = useRestClientContext();

	useEffect(() => {
		client
			.fetchTeams()
			.then((response) => response.json())
			.then((data) => {
				setTeams(data);
				setLoading(false);
			});
	}, [client]);

	const onCreateTeam = () => {
		setCreateTeamModalOpen(true);
	};

	const onCloseCreateTeamModal = () => {
		setCreateTeamModalOpen(false);
	};

	return (
		<Page>
			<Header as="h1">Your Teams</Header>
			<div className="team-page-container">
				<p>
					<Button variant="contained" onClick={onCreateTeam}>
						<Icon name="plus" />
						Create
					</Button>
				</p>
				{/* TODO: Need to make this into a GridPage ideally or componentize the grid itself in the very least */}
				<Grid stackable columns={2}>
					{teams.map((team) => (
						<Grid.Column key={team.id} width={6}>
							<TeamCard team={team} />
						</Grid.Column>
					))}
				</Grid>
			</div>
			{loading && <Preloader status="Loading teams..." />}
			{createTeamModalOpen && <CreateTeamModal onClose={onCloseCreateTeamModal} />}
		</Page>
	);
}
