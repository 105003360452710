import Modal from "../../Modal/Modal";

import { ModalProps } from "../../Modal/Modal";

interface ExplainerModalProps extends ModalProps {};

export default function ExplainerModal(props: ExplainerModalProps) {
	return (
		<Modal {...props} title="Coming soon">
			We are currently working on these reports and they will be available soon
		</Modal>
	);
}
