import { BarDatum, ResponsiveBar } from "@nivo/bar";
import PerformanceStatChartLabels from "./PerformanceStatChartLabels";

interface PerformanceStatsChartProps {
	data: BarDatum;
}

const PerformanceStatChart = ({ data }: PerformanceStatsChartProps) => {
	return (
		<div style={{ height: 330, minWidth: 70 }}>
			<ResponsiveBar
				data={[data]}
				keys={["Current", "Potential"]}
				indexBy="label"
				innerPadding={12}
				// maxValue={200}
				groupMode="grouped"
				valueScale={{ type: "linear" }}
				indexScale={{ type: "band", round: false }}
				valueFormat=" >-,.2f"
				colors={["#CA3A31", "#78D59F"]}
				borderRadius={2}
				axisTop={null}
				axisRight={null}
				axisBottom={null}
				axisLeft={null}
				enableGridY={false}
				enableLabel={false}
				// TODO: Please come back to this, how does this even work? Types say that layers shouldn't accept a JSX element. Perhaps it needs to be cast to a function / callback?
				// @ts-ignore
				layers={["grid", "axes", "bars", "markers", PerformanceStatChartLabels, "legends"]}
				legends={[]}
				animate={false}
			/>
		</div>
	);
};
export default PerformanceStatChart;
