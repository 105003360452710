import React, { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Case from "case";

import { useTeamContext } from "../../../contexts/TeamContext";
import { useRestClientContext } from "../../../contexts/RestClientContext";

import Button from "../../UI/Button/Button";
import Card from "../../UI/Card/Card";
import EpcRatingTile from "../../UI/EpcRatingTile/EpcRatingTile";
import Preloader from "../../UI/Preloader/Preloader";

import "./YourProperties.scss";

// TODO: Looks like this is referred to as Leaderboards on the server, would be nice to standardise this please

interface LeaderboardProperty
{
	id: number;
	epc_rating: number;
	epc_rating_grade: string;
	addr_no: string;
	addr_street: string;
	postcode: string;
}

interface BiggestImpactLeaderboardProperty extends LeaderboardProperty
{
	epc_rating_grade: string;
	potential_epc_rating: number;
	potential_epc_rating_grade: string;
}

interface YourPropertiesResponse
{
	worst_performers: Array<LeaderboardProperty>;
	best_performers: Array<LeaderboardProperty>;
	biggest_impact: Array<BiggestImpactLeaderboardProperty>;
}

const YourProperties = () => {
	const [data, setData] = useState<YourPropertiesResponse>();

	const team = useTeamContext();
	const client = useRestClientContext();

	useEffect(() => {
		client
			.fetchLeaderboards(team.id)
			.then((response: Response) => response.json())
			.then((json: YourPropertiesResponse) => {
				setData(json);
			});
	}, [client, team.id]);

	return (
		<Card title="Your Properties" className="your-properties">
			{!data && <Preloader />}
			{data && (
				<Grid padded="vertically">
					{["worst_performers", "best_performers"].map((key) => {
						const sortBy = [
							{
								id: "epc_rating",
								desc: key === "best_performers",
							},
						];

						return (
							<Grid.Column key={key}>
								<Grid divided="vertically">
									<Grid.Row>
										<Grid.Column>
											<h3>{Case.title(key)}</h3>
										</Grid.Column>
									</Grid.Row>
									{data?.[key as keyof YourPropertiesResponse].map((obj) => (
										<Grid.Row key={obj.id} style={{ paddingTop: 0, paddingBottom: 0 }}>
											<Grid.Column width={2}>
												<EpcRatingTile grade={obj.epc_rating_grade} compact />
											</Grid.Column>
											<Grid.Column width={4}>({obj.epc_rating})</Grid.Column>
											<Grid.Column width={6}>
												<Link to={`/epc-data/${obj.id}`} className="property">
													<h4>{`${obj.addr_no} ${obj.addr_street} ${obj.postcode}`}</h4>
												</Link>
											</Grid.Column>
										</Grid.Row>
									))}
									{
										<Link to="/epc-data" state={{ sortBy }}>
											<Button variant="contained">View {Case.title(key)}</Button>
										</Link>
									}
								</Grid>
							</Grid.Column>
						);
					})}
					<Grid.Column>
						<Grid divided="vertically">
							<Grid.Row>
								<Grid.Column>
									<h3>Biggest Impact</h3>
								</Grid.Column>
							</Grid.Row>
							{data?.biggest_impact.map((obj) => (
								<Grid.Row key={obj.id} style={{ paddingTop: 0, paddingBottom: 0 }}>
									<Grid.Column width={1}>
										<EpcRatingTile grade={obj.epc_rating_grade} compact />
									</Grid.Column>
									<Grid.Column width={2}>
										<EpcRatingTile grade={obj.potential_epc_rating_grade} compact />
									</Grid.Column>

									<Grid.Column width={3}>{`${obj.epc_rating_grade} (${obj.epc_rating})`}</Grid.Column>
									<Grid.Column
										width={3}
									>{`${obj.potential_epc_rating_grade} (${obj.potential_epc_rating})`}</Grid.Column>
									<Grid.Column width={4}>
										<Link to={`/epc-data/${obj.id}`}>
											<h4>
												{obj.addr_no} {obj.addr_street} {obj.postcode}
											</h4>
										</Link>
									</Grid.Column>
								</Grid.Row>
							))}
							{
								<Link
									to="/epc-data"
									state={{
										sortBy: [
											{
												id: "potential_epc_rating_impact",
												desc: true,
											},
										],
									}}
								>
									<Button variant="contained">View Biggest Impact</Button>
								</Link>
							}
						</Grid>
					</Grid.Column>
				</Grid>
			)}
		</Card>
	);
};

export default YourProperties;
