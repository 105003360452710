import React from "react";
import Input from "../../Input/Input";

interface NumberRangeColumnFilterProps {
	column: {
		filterValue: number[];
		setFilter: Function;
		min: number;
		max: number;
	}
};

export default function NumberRangeColumnFilter({
	column: { filterValue = [], setFilter, min, max },
}: NumberRangeColumnFilterProps) {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Input
				type="number"
				placeholder={`Min (${min})`}
				min={min}
				max={max}
				value={filterValue[0] || ""}
				onChange={(e) => {
					const val = e.target.value;
					setFilter((old = []) => [val ? parseFloat(val) : undefined, old[1]]);
				}}
			/>
			<Input
				type="number"
				placeholder={`Max (${max})`}
				min={min}
				max={max}
				value={filterValue[1] || ""}
				onChange={(e) => {
					const val = e.target.value;
					setFilter((old = []) => [old[0], val ? parseFloat(val) : undefined]);
				}}
			/>
		</div>
	);
}
