import { useState } from 'react';

function useUpdateTrigger() {
    const [trigger, setTrigger] = useState(0);

    const triggerUpdate = () => {
        setTrigger((prevTrigger) => prevTrigger + 1);
    };

    return { trigger, triggerUpdate };
}

export default useUpdateTrigger;
