import { XOR } from "ts-xor";
import "./EpcRatingTile.scss";

type EpcRatingTilePropsWithGrade = {
	grade: string;
	compact?: boolean;
};

/**
 * @deprecated Use grade parameter instead
 */
type EpcRatingTilePropsWithRating = {
	rating: number;
	compact? :boolean;
}

type EpcRatingTileProps = XOR<EpcRatingTilePropsWithGrade, EpcRatingTilePropsWithRating>;

export default function EpcRatingTile(props: EpcRatingTileProps) {

	const { grade, rating, compact } = props;
	let letter;

	const classNames = ["epc-rating-tile"];

	if (grade !== undefined && rating !== undefined)
		throw new Error("Invalid arguments, supply either grade OR rating"); // TODO: Should be redundant now we have types

	if (rating) {
		const ratingRound = Math.round(rating);
		if (isNaN(rating)) throw new Error("Invalid rating");

		if (ratingRound < 0 || ratingRound > 100) throw new Error("Out of bounds");

		// We should move away from having these values hard coded in the front end
		if (ratingRound > 91) letter = "A";
		else if (ratingRound >= 81) letter = "B";
		else if (ratingRound >= 69) letter = "C";
		else if (ratingRound >= 55) letter = "D";
		else if (ratingRound >= 39) letter = "E";
		else if (ratingRound >= 21) letter = "F";
		else letter = "G";
	}else
		letter = grade;

	if (compact) classNames.push("compact");

	return (
		<div className={classNames.join(" ")} data-grade={letter}>
			{!compact && letter}
		</div>
	);
}
