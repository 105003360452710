import React, { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";

import Card from "../../UI/Card/Card";
import EpcDataSummary from "./EpcDataSummary";
import EpcDataBreakdown from "./EpcDataBreakdown";
import Preloader from "../../UI/Preloader/Preloader";

import { useTeamContext } from "../../../contexts/TeamContext";
import { useRestClientContext } from "../../../contexts/RestClientContext";

export const epcGrades = ["A", "B", "C", "D", "E", "F", "G", "Ungraded"] as const;
export type EpcGrade = typeof epcGrades[number];

const EpcData = () => {
	const team = useTeamContext();
	const client = useRestClientContext();
	const [epcOverview, setEpcOverview] = useState();

	useEffect(() => {
		client
			.fetchEpcDataOverview(team.id)
			.then((response: Response) => response.json())
			.then((json: any) => {
				setEpcOverview(json);
			});
	}, [team, client]);

	return (
		<Card title="EPC Data" minHeight={395}>
			{epcOverview ? (
				<Grid stackable columns={2} padded="vertically">
					<Grid.Column>
						<div className="epc-data-label">
							<EpcDataSummary data={epcOverview} />
						</div>
					</Grid.Column>
					<Grid.Column>
						<EpcDataBreakdown data={epcOverview} />
					</Grid.Column>
				</Grid>
			) : (
				<Preloader />
			)}
		</Card>
	);
};

export default EpcData;
