import React from "react";

const PaperClipVector = () => {
	return (
		<svg width="24" height="24" stroke="#9395A2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g>
				<path
					d="M21.4403 11.05L12.2503 20.24C11.1244 21.3659 9.59747 21.9984 8.00529 21.9984C6.41311 21.9984 4.88613 21.3659 3.76029 20.24C2.63445 19.1142 2.00195 17.5872 2.00195 15.995C2.00195 14.4029 2.63445 12.8759 3.76029 11.75L12.9503 2.56004C13.7009 1.80948 14.7188 1.38782 15.7803 1.38782C16.8417 1.38782 17.8597 1.80948 18.6103 2.56004C19.3609 3.3106 19.7825 4.32859 19.7825 5.39004C19.7825 6.4515 19.3609 7.46948 18.6103 8.22004L9.41029 17.41C9.03501 17.7853 8.52602 17.9962 7.99529 17.9962C7.46456 17.9962 6.95557 17.7853 6.58029 17.41C6.20501 17.0348 5.99418 16.5258 5.99418 15.995C5.99418 15.4643 6.20501 14.9553 6.58029 14.58L15.0703 6.10004"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	);
};

export default PaperClipVector;
