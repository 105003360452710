import { Icon, Message, Table } from "semantic-ui-react";
import { useState, useEffect, ReactNode, useCallback } from 'react';

import { BulkCustomImprovementSetsBatch, CustomImprovementsSet } from "../../types/types";
import Property from "../../rest/Property";

import { useRestClientContext } from "../../contexts/RestClientContext";

import Card from "../UI/Card/Card";
import Button from "../UI/Button/Button";

import CustomImprovementsSetTableRow from "./CustomImprovementsSetTableRow";
import BulkCustomImprovementSetsProgressModal from "../CustomImprovements/CustomImprovementSetsProgressModal";

interface CustomImprovementsCardProps {
	property: Property;
	children: ReactNode;
}

export default function CustomImprovementsCard({ property, children }: CustomImprovementsCardProps) {
	const [sets, setSets] = useState<CustomImprovementsSet[]>(property.data.custom_improvement_sets);
	const [bulkCustomImprovementSetsProgressModalOpen, setBulkCustomImprovementSetsProgressModalOpen] = useState(false);
	const [improvementBatch, setImprovementBatch] = useState<BulkCustomImprovementSetsBatch>();

	const client = useRestClientContext();

	const onSetDeleted = (deleted: CustomImprovementsSet) => {
		setSets(
			sets!.filter(set => set.id !== deleted.id)
		);
	};

	const checkForPendingImprovements = useCallback(() => {
		if (property.data.bulk_custom_improvements_update_batch_uuid) {
			client
				.getBulkCustomImprovementBatch(property.data.team_id, property.data.bulk_custom_improvements_update_batch_uuid)
				.then((res: Response) => res.json())
				.then((batch: BulkCustomImprovementSetsBatch) => setImprovementBatch(batch));
		}
	}, [client, property, setImprovementBatch]);

	const onImprovementProgressModalClose = () => {
		setBulkCustomImprovementSetsProgressModalOpen(false);
		// check that it is still going - it would be confusing for the user see that the progress bar is full but the
		// 	message still appears.
		checkForPendingImprovements();
	}

	useEffect(() => {
		checkForPendingImprovements();
	}, [client, property, checkForPendingImprovements]);

	return (
		<Card title="Custom Measures">
				<Table celled>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Improvement Name</Table.HeaderCell>
							<Table.HeaderCell>SAP Value</Table.HeaderCell>
							<Table.HeaderCell>EI Value</Table.HeaderCell>
							<Table.HeaderCell>CO2 Saving (kgs per year)</Table.HeaderCell>
							<Table.HeaderCell>Cost Saving (£s per year)</Table.HeaderCell>
							<Table.HeaderCell>Heat Demand (kWh/m2 per year)</Table.HeaderCell>
							<Table.HeaderCell>Actions</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>
						{
							sets
								?.map((set: CustomImprovementsSet) =>
									<CustomImprovementsSetTableRow
										key={set.id}
										property={property}
										set={set}
										onDeleted={onSetDeleted}
									/>
								)
						}
					</Table.Body>
				</Table>

				{improvementBatch && !improvementBatch.finished_at && !improvementBatch.cancelled_at && (
					<Message warning icon>
						<Icon name="warning sign"/>
						<Message.Content>
							<Message.Header>
								Update pending...
							</Message.Header>
							<p>
								An update is queued for processing. Starting a new update will override the
								improvements in the queue.

								<Button
									variant="text"
									onClick={() => setBulkCustomImprovementSetsProgressModalOpen(true)}
								>
									View progress
								</Button>
							</p>
						</Message.Content>
					</Message>
				)}

				{children}

				{
					bulkCustomImprovementSetsProgressModalOpen &&
					improvementBatch && (
						<BulkCustomImprovementSetsProgressModal
							batches={[improvementBatch]}
							onClose={onImprovementProgressModalClose}
						/>
					)
				}
		</Card>
	);
}
