import NextVector from "../../../assets/NextVector";
import Button, { ButtonProps } from "./Button";

export default function Next({ disabled, onClick }: ButtonProps) {
	const fill = disabled ? "#9395a2" : "#ff5f43";

	return (
		<Button variant="text" onClick={() => onClick!()}>
			<NextVector fill={fill} stroke={fill} />
		</Button>
	);
}
