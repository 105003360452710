import { useEffect, useState } from "react";

import { Aggregates } from "../components/UI/DataTable/getColumns";
import { useRestClientContext } from "../contexts/RestClientContext";
import { useTeamContext } from "../contexts/TeamContext";

export default function usePropertyAggregates(): Aggregates | undefined
{
	const team = useTeamContext();
	const client = useRestClientContext();

	const [cache, setCache] = useState<Aggregates>();

	useEffect(() => {

		if(cache)
			return;

		client
			.fetchTeamPropertyAggregates(team.id)
			.then((response: Response) => response.json())
			.then((json: any) => setCache(json as Aggregates));
	// Adding cache as a dep will cause a loop
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [client, team.id]);

	return cache;
}