import React from "react";
import "./Pagination.scss";
import Input from "../Input/Input";
import Select from "../Select/Select";
import Button from "../Button/Button";
import Previous from "../Button/Previous";
import Rewind from "../Button/Rewind";
import Next from "../Button/Next";
import FastForward from "../Button/FastForward";

const AVAILABLE_PAGE_SIZES = [10, 25, 50, 100, 250, 500, 1000, 2500];
export { AVAILABLE_PAGE_SIZES };

interface PaginationProps {
	numResults: number;
	numPages: number;
	currentPageIndex: number;
	pageSize: number;
	canPreviousPage: boolean;
	canNextPage: boolean;
	onFirstPage: Function;
	onPreviousPage: Function;
	onNextPage: Function;
	onLastPage: Function;
	onJumpToPage: Function;
	onChangePageSize: Function;
}

const Pagination = (props: PaginationProps) => {
	const {
		numResults,
		numPages,
		currentPageIndex,
		pageSize,
		canPreviousPage,
		canNextPage,
		onFirstPage,
		onPreviousPage,
		onNextPage,
		onLastPage,
		onJumpToPage,
		onChangePageSize,
	} = props;

	// NB: Human formatted page number, instead of 0 based index
	const currentPageNumber = currentPageIndex + 1;

	const onGotoPageKeypress = (event: KeyboardEvent) => {
		if (event.key !== "Enter") return;

		const value = Number( (event.target as HTMLInputElement).value );

		const pageIndex = value - 1;

		if (pageIndex < 0 || pageIndex >= numPages) return; // NB: Out of range

		onJumpToPage(value);
	};

	return (
		<div className="pagination-container">
			<div>
				Showing <b>{pageSize}</b> of <b>~{numResults} result </b>
			</div>
			<div className="pagination-button-container">
				<Rewind disabled={!canPreviousPage} onClick={() => onFirstPage()} />
				<Previous disabled={!canPreviousPage} onClick={() => onPreviousPage()} />
				<Button variant="contained">{currentPageNumber}</Button>
				<Next disabled={!canNextPage} onClick={() => onNextPage()} />
				<FastForward disabled={!canNextPage} onClick={() => onLastPage()} />
			</div>
			<div>
				<div className="page-counter">
					Page <b>{currentPageNumber}</b> of <b>{numPages}</b>
				</div>
				<div className="page-skip">
					<b>Go to page</b>
					<Input
						focus
						placeholder={currentPageNumber.toString()}
						onKeyPress={onGotoPageKeypress}
						onChange={(event) => onJumpToPage(event.target.value)}
					/>
				</div>
				<div>
					<Select
						text={`Show ${pageSize}`}
						options={AVAILABLE_PAGE_SIZES.map((size) => {
							return { key: size, text: size, value: size };
						})}
						value={pageSize}
						onChange={(event, data) => onChangePageSize(data.value)}
					></Select>
				</div>
			</div>
		</div>
	);
};

export default Pagination;
