import PreviousVector from "../../../assets/PreviousVector";
import Button, { ButtonProps } from "./Button";

export default function Previous({ disabled, onClick }: ButtonProps) {
	const fill = disabled ? "#9395a2" : "#ff5f43";

	return (
		<Button variant="text" onClick={() => onClick!()}>
			<PreviousVector fill={fill} stroke={fill} />
		</Button>
	);
}
