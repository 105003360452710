import { BrowserRouter as Router } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import env from "react-dotenv";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import Auth0Wrapper from "./components/Auth0Wrapper";

import "./App.css";

// TODO: Moving this above App.css causes styling issues. Ideally sort this please.
import MainNavigationMenu from "./components/MainNavigationMenu";
import Maintenance from "./pages/Maintenance";

Sentry.init({
	dsn: env.SENTRY_IO_DSN,
	integrations: [new BrowserTracing()],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: parseFloat(env.SENTRY_IO_TRACES_SAMPLE_RATE),
});

function App() {
	const isMaintenance = env.MAINTENANCE_MODE === "true";

	if (isMaintenance) {
		return <Maintenance />;
	}

	return (
		<Auth0Provider
			domain={env.AUTH0_ISSUER_BASE_URL}
			clientId={env.AUTH0_CLIENT_ID}
			redirectUri={window.location.origin}
			audience={env.API_V2_BASE_URL}
			scope="email openid profile"
			useRefreshTokens={true}
			cacheLocation="localstorage"
		>
			<Auth0Wrapper>
				<Router>
					<MainNavigationMenu />
				</Router>
			</Auth0Wrapper>
		</Auth0Provider>
	);
}

export default App;
