import { useState, SyntheticEvent, ChangeEvent } from "react";
import { Message, Table, DropdownProps } from "semantic-ui-react";

import { useRestClientContext } from "../../../contexts/RestClientContext";

import AddUserVector from "../../../assets/AddUserVector";
import RemoveUserVector from "../../../assets/RemoveUserVector";
import Button from "../Button/Button";
import Input from "../Input/Input";
import Preloader from "../Preloader/Preloader";

import RedactedEmail from "../RedactedEmail/RedactedEmail";
import RoleSelect from "../RoleSelect/RoleSelect";
import Role from "../../../rest/Role";
import Team from "../../../rest/Team";
import { User } from "../../../types/types";

import "./UserManagementTable.scss";

interface UserManagementTableProps {
	team: Team;
}

export default function UserManagementTable({ team }: UserManagementTableProps) {
	const client = useRestClientContext();

	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<Error>();

	const [users, setUsers] = useState<User[]>(team.users!);
	const [addUserEmail, setAddUserEmail] = useState<string>("");
	const [addUserRole, setAddUserRole] = useState<Role>(Role.USER);

	const reload = () => {
		setLoading(true);

		client
			.fetchTeam(team.id!)
			.then((response: Response) => response.json())
			.then((json: Team) => setUsers(json.users!))
			.catch((error: Error) => setError(error))
			.finally(() => setLoading(false));
	};

	const onAddUser = () => {
		if (!addUserEmail.length) {
			alert("Please specify an e-mail address");
			return;
		}

		setLoading(true);

		client
			.updateTeamUsers(team.id!, {
				email: addUserEmail,
				role: addUserRole,
			})
			.then(() => reload())
			.catch((error: Response) => alert(error.statusText))
			.finally(() => setLoading(false));
	};

	const onRemoveUser = (user: User) => {
		if (!window.confirm(`Are you sure you want to remove ${user.email} from this team?`)) return;

		setLoading(true);

		client
			.deleteTeamUser(team.id!, user.id)
			.then(() => reload())
			.catch((error: Response) => alert(error.statusText))
			.finally(() => setLoading(false));
	};

	const onUpdateUserRole = (user: User, role: Role) => {
		setLoading(true);

		client
			.updateTeamUser(team.id!, user.id, { role })
			.then(() => reload())
			.catch((error: Error) => {
				alert(error.message);
			})
			.finally(() => setLoading(false));
	};

	return (
		<div className="user-management-table-container">
			{error && <Message negative>{error.message}</Message>}
			<Table celled>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>E-Mail</Table.HeaderCell>
						<Table.HeaderCell>Role</Table.HeaderCell>
						<Table.HeaderCell>Actions</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{users.map((user) => {
						return (
							<Table.Row key={user.id}>
								<Table.Cell>
									<RedactedEmail address={user.email} />
								</Table.Cell>
								<Table.Cell>
									<RoleSelect
										value={user.role}
										onChange={(e: SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => onUpdateUserRole(user, value as Role)}
									/>
								</Table.Cell>
								<Table.Cell>
									<Button variant="contained" onClick={() => onRemoveUser(user)}>
										<RemoveUserVector />
									</Button>
								</Table.Cell>
							</Table.Row>
						);
					})}
					<Table.Row>
						<Table.Cell>
							<Input
								placeholder="E-Mail Address"
								value={addUserEmail}
								disabled={loading}
								onChange={(event: ChangeEvent<HTMLInputElement>) => setAddUserEmail(event.target.value)}
							/>
						</Table.Cell>
						<Table.Cell>
							<RoleSelect
								value={addUserRole}
								disabled={loading}
								onChange={(e: SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => setAddUserRole(value as Role)}
							/>
						</Table.Cell>
						<Table.Cell>
							<Button variant="outlined" onClick={onAddUser}>
								<AddUserVector />
							</Button>
						</Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table>
			{loading && <Preloader cover />}
		</div>
	);
}
