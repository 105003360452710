import React, { useEffect } from "react";

// TODO: Please solve the ts-ignores, I don't fully understand how this code should interact with our types. It comes from TanStack

const IndeterminateCheckbox = React.forwardRef(
	(
		{ 
			// @ts-ignore
			indeterminate, 
			...rest 
		}, ref
	) => {

		const defaultRef = React.useRef<HTMLInputElement>(null);
		const resolvedRef = ref || defaultRef;

		useEffect(() => {
			// @ts-ignore
			resolvedRef!.current.indeterminate = indeterminate;
		}, [resolvedRef, indeterminate]);

		return (
			<div>
				{ /* @ts-ignore */ }
				<input type="checkbox" className="form-checkbox text-red-600" ref={resolvedRef} {...rest} />
			</div>
		);
	});

export default IndeterminateCheckbox;
