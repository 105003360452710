import { PropsWithChildren, useEffect, useState } from "react";
import { Message } from "semantic-ui-react";
import Preloader from "../Preloader/Preloader";

interface SuspenseProps extends PropsWithChildren
{
	promise?: Promise<any>;
	fallback?: JSX.Element;
	isInitiallyLoading?: boolean;
}

export default function Suspense({children, promise, fallback, isInitiallyLoading}: SuspenseProps)
{
	const [loading, setLoading] = useState(isInitiallyLoading === false ? false : true); // NB: No initial promise means we're not starting out in a loading state
	const [error, setError] = useState<Error>();

	// TODO: Support multiple promises with array and Promise.all

	useEffect(() => {
		
		if(!promise)
			return;

		setLoading(true);

		promise.catch((error: Error) => setError(error));
		promise.finally(() => setLoading(false));

	}, [promise]);
	

	if(error)
		return <Message negative>{error.message}</Message>;
	
	if(loading)
	{
		if(fallback)
			return fallback;
		
		return <Preloader variant="inline" />;
	}

	return <>{children}</>;
}