import React from "react";
import { AlertVector } from "../../../assets/AlertVector";
import RectangleVector from "../../../assets/RectangleVector";
import "./Alert.scss";

interface AlertProps
{
	numUnreadNotifications: number;
}

const Alert = ({ numUnreadNotifications }: AlertProps) => {
	return (
		<div className="alert-compenent">
			{numUnreadNotifications > 0 ? <RectangleVector></RectangleVector> : <div></div>}
			<AlertVector></AlertVector>
		</div>
	);
};

export default Alert;
