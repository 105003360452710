import React, { PropsWithChildren } from "react";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Client from "../rest/Client";
import env from "react-dotenv";
import { Message } from "semantic-ui-react";

import RestClientContext from "../contexts/RestClientContext";
import UserContext from "../contexts/UserContext";
import TeamContext from "../contexts/TeamContext";

import Preloader from "./UI/Preloader/Preloader";
import Team from "../rest/Team";
import ConfirmEmailCard from "./UI/ConfirmEmailCard";
import Modal from "./Modal/Modal";

function Auth0Wrapper({ children }: PropsWithChildren) {
	const { isLoading, error, isAuthenticated, loginWithRedirect, getAccessTokenSilently, logout } = useAuth0();
	const [client, setClient] = useState<Client>();
	const [user, setUser] = useState(null);
	const [team, setTeam] = useState<Team>();
	const [isContextLoading, setIsContextLoading] = useState(true);
	const [ownError, setOwnError] = useState<string>();
	const [showTeamModal, setShowTeamModal] = useState<boolean>(false);

	useEffect(() => {
		(async () => {
			if (!isAuthenticated) return;

			try {
				// TODO: Speed this up with Promise.all, no need to call these sequentially
				let response, data;

				const token = await getAccessTokenSilently({
					audience: env.API_V2_BASE_URL,
					scope: "email openid profile",
				});

				const client = new Client(token);
				setClient(client);

				response = await fetch(`https://${env.AUTH0_ISSUER_BASE_URL}/userinfo`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				const auth0UserInfo = await response.json();

				response = await client.fetchMe();
				data = await response.json();

				if (data.error) {
					setOwnError(data.error.message);
					return;
				}

				// TODO: Make a class to represent the user
				setUser({
					...auth0UserInfo,
					...data,
				});

				if (!data["teams"]?.length && !data["is_admin"]) {
					setShowTeamModal(true);
					return;
				}

				response = await client.fetchTeam(data.team_id);
				data = await response.json();

				if (data.error) {
					setOwnError(data.error.message);
					return;
				}

				setTeam(new Team(data));
				setIsContextLoading(false);
			} catch (e) {
				setOwnError((e as Error).message);
			}
		})();
	}, [isAuthenticated, getAccessTokenSilently]);

	if (isLoading) return <Preloader status="Authenticating with Auth0..." fullscreen />;

	if (error && error.message === "Please verify your email before logging in.") {
		return <ConfirmEmailCard logout={logout} />;
	}

	if (error || ownError)
		return (
			<Message negative>
				<Message.Header>Error</Message.Header>
				<p>{ownError || error!.message}</p>
			</Message>
		);
	else if (showTeamModal)
		return (
			<Modal title="Welcome">
				<p>
					You are not a member of any teams yet. Please speak to a team manager or administrator to be invited
					to a team.
				</p>
			</Modal>
		);

	if (!isAuthenticated) {
		loginWithRedirect({
			audience: env.API_V2_BASE_URL,
			scope: "email openid profile",
		});
		return <Preloader status="Redirecting to login..." fullscreen />;
	}

	if (isContextLoading) return <Preloader status="Loading DREam..." fullscreen />;

	return (
		<RestClientContext.Provider value={client}>
			<UserContext.Provider value={user}>
				<TeamContext.Provider value={team}>{children}</TeamContext.Provider>
			</UserContext.Provider>
		</RestClientContext.Provider>
	);
}

export default Auth0Wrapper;
