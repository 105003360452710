import { createContext, useContext } from "react";

const UserContext = createContext();

export default UserContext;

export function useUserContext() {
	const context = useContext(UserContext);
	if (!context) {
		throw new Error("No UserContext.Provider found when calling useUserContext.");
	}
	return context;
}
