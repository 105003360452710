import { useState } from "react";
import { Message, List, Icon } from "semantic-ui-react";
import humanizeDuration from "humanize-duration";

import useCustomImprovementSetsHistory from "../../hooks/useCustomImprovementSetsHistory";
import { CustomImprovementSetsHistoryRecord } from "../../hooks/useCustomImprovementSetsHistory";

import Button from "../UI/Button/Button";
import Suspense from "../UI/Suspense/Suspense";

import { useRestClientContext } from "../../contexts/RestClientContext";
import { CustomImprovementsModalPanelProps, CustomImprovementsSet } from "../../types/types";

export default function HistoryPanel({ teamId, property, data, onComplete, onClose }: CustomImprovementsModalPanelProps) {

	const client = useRestClientContext();
	const { items } = useCustomImprovementSetsHistory();

	const [promise, setPromise] = useState<Promise<Response>>();

	const now = new Date().getTime();

	const onRun = (item: CustomImprovementSetsHistoryRecord) => {

		// TODO: This is repeated from CustomPanel, probably best to abstract this somehow
		const improvements = [...item.improvements];

		improvements.forEach(definition => definition.reason = "User selected from history");

		let promise;

		if(property)
			promise = client.createPropertyCustomImprovements(property, {
				...data,
				"custom-improvements": improvements
			});
		else
			promise = client.createMultiPropertyCustomImprovements(teamId, {
				...data,
				"custom-improvements": improvements
			});

		promise
			.then((response: Response) => response.json())
			.then((json: CustomImprovementsSet) => {
				
				if(onComplete)
					onComplete(json);
				
				onClose();

			});
		
		setPromise(promise);

	};

	if (!items.length) {
		return <Message info icon>
			<Icon name='info circle' />
			<Message.Content>
				<Message.Header>No items found</Message.Header>
				Run some improvements to populate this panel.
			</Message.Content>
		</Message>
	}

	return <Suspense promise={promise} isInitiallyLoading={false}>
		<List divided relaxed className="custom-improvement-presets-list">
			{
				items.map(item => 
					<List.Item key={item.name}>
						<List.Icon name='sliders horizontal' size='large' verticalAlign='middle' />
						<List.Content>
							<List.Header as='a'>{item.name}</List.Header>
							<List.Description as='a'>
								{
									humanizeDuration(now - item.createdAt, {
										round: true
									})
								} ago
							</List.Description>
						</List.Content>
						<Button 
							variant="contained"
							onClick={() => onRun(item)}
						>
							Run
						</Button>
					</List.Item>
				)
			}
		</List>
	</Suspense>;
}