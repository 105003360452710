import { useMemo } from "react";
import traverse from "traverse";

import getColumns, { ColumnDefinition } from "../components/UI/DataTable/getColumns";

export default function usePropertyColumns()
{
	return useMemo(() => {

		const result: ColumnDefinition[] = [];

		traverse.forEach(getColumns(), obj => {

			if(!(obj instanceof Object))
				return;

			if(!("accessor" in obj))
				return;
			
			result.push(obj as ColumnDefinition);

		});

		return result;

	}, []);
}